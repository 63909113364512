import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import qs from "qs";

import { logout } from "../features/auth/actions";
import { setConfig } from "../features/config/actions";

import { getAndDispatchClaims, getAuth0Client } from "./auth0Utils";
import {
  buildAuth0ClientOptions,
  fetchStaticConfig,
  isAbsoluteUrl,
} from "./common";

import type { RootState } from ".";

const rawBaseQuery = fetchBaseQuery({
  paramsSerializer: qs.stringify,
  // Being that we are using dynamic environment configs (./env/[env].json) and don't have a variable such as process.env.REACT_APP_API_URL
  // available to us at build time, we default this as an empty string here, then fetch the base API url in `dynamicBaseQuery`.
  baseUrl: "",
});

const buildArgWithAuthHeaders = (
  arg: string | FetchArgs,
  token: string,
  //   buildingId: string
) => {
  const headersWithAuth = {
    authorization: `Bearer ${token}`,
    // "x-building-id": buildingId,
  };

  return typeof arg === "string"
    ? { url: arg, headers: headersWithAuth }
    : {
        ...arg,
        headers: headersWithAuth,
      };
};

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (arg, api, extraOptions) => {
  const {
    auth: { token },
    config,
  } = api.getState() as RootState;

  let result = await rawBaseQuery(
    buildArgWithAuthHeaders(arg, token),
    api,
    extraOptions,
  );

  if (result.error && result.error.status === 401) {
    const auth0ClientOptions = buildAuth0ClientOptions(config);
    const claims = await getAndDispatchClaims(api.dispatch, auth0ClientOptions);

    if (claims) {
      result = await rawBaseQuery(
        buildArgWithAuthHeaders(arg, claims?.__raw),
        api,
        extraOptions,
      );
    } else {
      // Failed to refresh the token, logout
      const client = await getAuth0Client(auth0ClientOptions);
      api.dispatch(logout());
      await client.logout({
        returnTo: `${window.location.origin}`,
      });
    }
  }

  return result;
};

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (arg, api, extraOptions) => {
  let { config } = api.getState() as RootState;

  // This technically isn't necessary now, but it's better to add it upfront. If someone were to make a request from a manual `dispatch(api.endpoints['someEndpoint'].initiate())` in the future outside of ConfigGate, this code would be necessary.
  if (Object.keys(config).length === 0) {
    try {
      config = await fetchStaticConfig();
      api.dispatch(setConfig(config));
    } catch (err) {
      // If we fail to load config.json, retrying isn't a concern as it's a static resource and should just immediately load
      return {
        error: {
          status: 400,
          statusText: "Bad Request",
          data: "Unable to load the application configuration",
        },
      };
    }
  }

  const originalUrl = typeof arg === "string" ? arg : arg.url;

  const shouldSkipCombiningUrls =
    isAbsoluteUrl(originalUrl) ||
    ["/oms-api", "/api", "/parcel-api"].some((v) => originalUrl.startsWith(v));

  // If we've set an absolute URL, use that, otherwise use the API_URL from the config
  const finalUrl = shouldSkipCombiningUrls
    ? originalUrl
    : `${config.API_URL}${originalUrl}`;

  const adjustedArgs =
    typeof arg === "string"
      ? finalUrl
      : {
          ...arg,
          url: finalUrl,
        };

  return baseQueryWithReauth(adjustedArgs, api, extraOptions);
};

export default dynamicBaseQuery;
