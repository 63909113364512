import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { get } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { faBan, faCheck, faPencil } from "@fortawesome/pro-regular-svg-icons";
import { useForm } from "react-hook-form";

import { PermissionSelector } from "@stordco/cumulonimbus";
import { capitalize, Card, FaIcon } from "@stordco/fe-components";

import { useApi, useOrganization } from "../../../../hooks";
import type { ResourcePermissions } from "../../../../store/services/api.generated";

// Gets the top level permission paths from a permissions object
// Thanks BingGPT
// TODO: typing this suuuuuucks
function findArrayPaths(permissions: any, path = "", result = [] as string[]) {
  if (!permissions) return null;

  for (const key in permissions) {
    const newPath = path ? `${path}.${key}` : key;
    if (Array.isArray(permissions[key])) {
      result.push(path);
    } else if (typeof permissions[key] === "object") {
      findArrayPaths(permissions[key], newPath, result);
    }
  }
  return [...new Set(result)];
}

export function ApiKeyDetailsDrawer() {
  const navigate = useNavigate();
  const { apiKeyId } = useParams();
  const api = useApi();
  const { organizationId } = useOrganization();

  const { data } = api.useApiKeysShowQuery(
    {
      id: apiKeyId as string,
      organizationId,
    },
    {
      skip: !apiKeyId || !organizationId,
    },
  );

  const permissionPaths = useMemo(() => {
    return findArrayPaths(data?.data.permissions);
  }, [data?.data.permissions]);

  return (
    <Drawer isOpen placement="right" size="lg" onClose={() => navigate(-1)}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>{data?.data.name} details</DrawerHeader>
        <DrawerCloseButton />

        <DrawerBody>
          <Stack spacing={4}>
            {permissionPaths?.map((path) => {
              return (
                <ApiKeyDetailsCard
                  key={path}
                  apiKeyId={apiKeyId as string}
                  path={path}
                />
              );
            })}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

const getOnlyArrayValues = (obj: any) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      // @ts-ignore -- TODO: fix types because I have no idea what this is even suppose to be
      acc[key] = value;
    }
    return acc;
  }, {});
};

const getTopLevelResourceLabel = (path: string) => {
  if (path.endsWith("*")) {
    const result = path.split(".");
    return result[result.length - 2];
  } else {
    return path.split(".").pop();
  }
};

const ApiKeyDetailsCard = ({
  apiKeyId,
  path,
}: {
  apiKeyId: string;
  path: string;
}) => {
  const api = useApi();
  const { organizationId } = useOrganization();
  const [mode, setMode] = useState<"view" | "edit">("view");

  const [updateApiKey] = api.useApiKeysUpdateMutation();

  const { data: availablePermissions } =
    api.useOrganizationsAvailablePermissionsQuery(organizationId, {
      skip: !organizationId,
    });

  const {
    data: apiKeyData,
    refetch,
    fulfilledTimeStamp,
  } = api.useApiKeysShowQuery({
    id: apiKeyId,
    organizationId,
  });

  const {
    setValue,
    resetField,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<{
    permissions: ResourcePermissions;
  }>({
    defaultValues: {
      permissions: {},
    },
  });

  useEffect(() => {
    if (apiKeyData?.data.permissions) {
      setValue("permissions", apiKeyData.data.permissions);
    }
  }, [apiKeyData, fulfilledTimeStamp, setValue]);

  const permissions = get(apiKeyData?.data.permissions, path, {});
  const availablePerms = getOnlyArrayValues(
    get(availablePermissions?.data, path, {}),
  );

  const toast = useToast();

  return (
    <Card>
      <form
        onSubmit={handleSubmit((values) => {
          updateApiKey({
            organizationId,
            id: apiKeyId,
            body: {
              data: {
                permissions: values.permissions as any, // TODO: fix types
              },
            },
          })
            .unwrap()
            .then(() => {
              toast({
                status: "success",
                title: "Permissions updated",
                description:
                  "The permissions for this API key have been updated.",
              });
              refetch()
                .unwrap()
                .then(() => setMode("view"));
            })
            .catch(() => {
              toast({
                status: "error",
                title: "Error updating permissions",
                description:
                  "There was an error updating the permissions for this API key, try again.",
              });
            });
        })}
      >
        <Card.PaddedContent>
          <Card.Header
            title={capitalize(
              (permissions as any)?.__meta?.name ??
                getTopLevelResourceLabel(path),
            )}
            rightElement={
              <Card.Actions>
                {mode === "view" ? (
                  <IconButton
                    isDisabled={
                      !!apiKeyData?.data.expires_at &&
                      new Date() > new Date(apiKeyData?.data.expires_at)
                    }
                    aria-label="edit"
                    variant="outline"
                    colorScheme="gray"
                    icon={<FaIcon icon={faPencil} />}
                    onClick={() => setMode("edit")}
                  />
                ) : (
                  <>
                    <IconButton
                      aria-label="cancel"
                      variant="outline"
                      colorScheme="gray"
                      icon={<FaIcon icon={faBan} />}
                      isDisabled={isSubmitting}
                      onClick={() => {
                        setMode("view");
                        resetField("permissions");
                      }}
                    />
                    <IconButton
                      key={mode}
                      aria-label="save"
                      disabled={isSubmitting}
                      type="submit"
                      icon={<FaIcon icon={faCheck} />}
                    />
                  </>
                )}
              </Card.Actions>
            }
          />

          <PermissionSelector
            key={`${fulfilledTimeStamp}-${mode}`}
            availablePermissions={availablePerms}
            defaultValues={permissions as any} // TODO: permissions needs better types
            onChange={(value) => setValue(`permissions.${path}`, value)}
            isDisabled={mode === "view"}
            hideTitle
          />
        </Card.PaddedContent>
      </form>
    </Card>
  );
};
