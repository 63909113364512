import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../store";
import type { EnvConfig } from "../../store/api";
import { api } from "../../store/api";

import { setConfig } from "./actions";

const slice = createSlice({
  name: "config",
  initialState: {} as EnvConfig,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setConfig, (state, { payload }) => {
        Object.assign(state, payload);
      })
      .addMatcher(
        api.endpoints["getConfig"].matchFulfilled,
        (state, { payload }) => {
          Object.assign(state, payload);
        },
      );
  },
});

export default slice.reducer;

export const selectConfig = (state: RootState) => state.config;
