import type { EnvConfig } from "../api";
import { fetchStaticConfig } from "../common";

import { generatedApi } from "./api.generated";

export const TAG_TYPES = [
  "ApiKeys",
  "AppInstances",
  "MemberInvitations",
  "OrganizationMember",
  "OrganizationMembers",
  "Organizations",
];

export const userApi = generatedApi
  .enhanceEndpoints({
    addTagTypes: TAG_TYPES,
    endpoints: {
      organizationsMembersShow: {
        providesTags: ["OrganizationMember"],
      },
      organizationsMembersIndex: {
        providesTags: ["OrganizationMembers"],
      },
      organizationsMembersDelete: {
        invalidatesTags: ["OrganizationMembers"],
      },
      organizationsMembersCreate: {
        invalidatesTags: ["OrganizationMembers"],
      },
      organizationsMembersUpdate: {
        invalidatesTags: ["OrganizationMembers", "OrganizationMember"],
      },
      organizationInvitationsIndex: {
        providesTags: ["MemberInvitations"],
      },
      organizationInvitationsAcknowledge: {
        invalidatesTags: ["MemberInvitations", "Organizations"],
      },
      organizationsMembersActivate: {
        invalidatesTags: ["OrganizationMembers"],
      },
      organizationsMembersDeactivate: {
        invalidatesTags: ["OrganizationMembers"],
      },
      organizationsIndex: {
        providesTags: ["Organizations"],
      },
      me: {
        providesTags: ["Me"],
      },
      createMe: {
        invalidatesTags: ["Me"],
      },
      updateMe: {
        invalidatesTags: ["Me"],
      },
      apiKeysIndex: {
        providesTags: ["ApiKeys"],
      },
      apiKeysCreate: {
        invalidatesTags: ["ApiKeys"],
      },
      apiKeysRotateSecret: {
        invalidatesTags: ["ApiKeys"],
      },
      apiKeysUpdate: {
        invalidatesTags: ["ApiKeys"],
      },
      realmsListAll: {
        providesTags: ["AppInstances"],
      },
      realmsUpdate: {
        invalidatesTags: (_result, error) => (!error ? ["AppInstances"] : []),
      },
    },
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getConfig: build.query<EnvConfig, void>({
        queryFn: async () => {
          try {
            const data = await fetchStaticConfig();
            return { data };
          } catch (err) {
            return {
              error: { status: 500, data: "Error fetching config" },
            };
          }
        },
      }),
    }),
  });

export type UserApi = typeof userApi;
