import {
  Avatar,
  Button,
  Flex,
  LinkOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { LinkBoxCard } from "@stordco/fe-components";

import {
  closeOrganizationSwitcher,
  selectOrganizationSwitcherIsOpen,
} from "../../features/organizationSwitcher/organizationSwitcherSlice";
import { api } from "../../store/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export const OrganizationSwitcherModal = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectOrganizationSwitcherIsOpen);
  const { data } = api.useMeQuery({});

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => dispatch(closeOrganizationSwitcher())}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Organizations</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            {data?.data?.organizations?.map((org) => (
              <LinkBoxCard key={org.id} variant="outline">
                <Flex
                  px={3}
                  py={2}
                  gap={3}
                  alignItems="center"
                  _hover={{
                    cursor: "pointer",
                  }}
                >
                  <Avatar name={org.name} boxSize={8} rounded="base" />
                  <LinkOverlay
                    as={Link}
                    onClick={() => dispatch(closeOrganizationSwitcher())}
                    to={`/organizations/${org.alias}/dashboard`}
                  >
                    <Text fontWeight="medium">{org.name}</Text>
                  </LinkOverlay>
                </Flex>
              </LinkBoxCard>
            ))}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            colorScheme="gray"
            onClick={() => dispatch(closeOrganizationSwitcher())}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
