import {
  createContext,
  useContext,
  Fragment,
  type ReactNode,
  type ReactElement,
  useMemo,
  type Ref,
  type ElementRef,
} from "react";
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Skeleton,
  IconButton,
  HStack,
  Text,
} from "@chakra-ui/react";
import type {
  TableProps,
  TableColumnHeaderProps,
  TableCellProps,
  ButtonProps,
} from "@chakra-ui/react";
import type { RowData, Cell } from "@tanstack/react-table";
import {
  flexRender,
  type Row,
  type Table as ReactTable,
  type Updater,
  type SortingState,
  type PaginationState,
  type ColumnDef,
} from "@tanstack/react-table";
import {
  faArrowDownLong,
  faArrowUpLong,
} from "@fortawesome/pro-solid-svg-icons";
import type { DragControls } from "framer-motion";
import { Reorder, useDragControls } from "framer-motion";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";

import { FaIcon } from "../FaIcon";
import { NoResultsFound } from "../EmptyData/EmptyStates";
import { Select } from "../Select/Select";
import { Loader } from "../Loader";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    density?: TableDensity;
  }

  interface ColumnMeta<TData extends RowData, TValue> {
    headerProps?: TableColumnHeaderProps;
    getCellProps?: (cell: Cell<TData, TValue>) => TableCellProps;
    isRightAligned?: true;
    isCenterAligned?: boolean;
  }
}

type RenderBody = (children: ReactNode) => ReactElement | null;
const defaultRenderBody: RenderBody = (children) => <Tbody>{children}</Tbody>;

type RenderRow<TData> = (props: {
  children: ReactNode;
  row: Row<TData>;
}) => ReactElement | null;

export type TableDensity = "comfortable" | "compact";

const defaultRenderRow: RenderRow<any> = ({ children }) => <Tr>{children}</Tr>;

export function TanstackTable<TData>({
  table,
  isLoading,
  loadingRows = 10,
  renderEmptyData,
  renderBody = defaultRenderBody,
  renderRow = defaultRenderRow,
  renderSubRows,
  stickyHeaders,
  stickyFirstColumn,
  showLoadingRow,
  loadingRowRef,
  ...props
}: {
  table: ReactTable<TData>;
  isLoading?: boolean;
  /**
   *  Number of rows to render while loading
   **/
  loadingRows?: number;
  renderEmptyData?: () => ReactNode;

  /**
   * Customize the rendering of the table body. The default just renders a chakra `Tbody`.
   * Use this if you want reorderable rows, where you would render `ReorderableTbody`.
   */
  renderBody?: RenderBody;

  /**
   * Customize the rendering of the table rows. The default just renders a chakra `Tr`.
   * Make sure you provide the `key` prop, e.g. `key={row.id}`.
   * Use this if you want reorderable rows, where you would render `ReorderableRow`.
   */
  renderRow?: RenderRow<TData>;

  renderSubRows?: (props: { parentRow: Row<TData> }) => ReactElement | null;
  stickyHeaders?: boolean;
  stickyFirstColumn?: boolean;

  /**
   * Show a loading row when scroll reaches the bottom of the table.
   * Use this if the table supports infinite scrolling.
   */
  showLoadingRow?: boolean;

  /**
   * Ref to pass to loading row element which is displayed when `showLoadingRow` is `true`.
   */
  loadingRowRef?: Ref<ElementRef<"tr">>;
  density?: TableDensity;
} & TableProps) {
  return (
    <Table
      {...props}
      {...(table.options.meta?.density === "compact" && { size: "sm" })}
    >
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header, headerIndex) => (
              <Th
                key={header.id}
                {...(header.column.getCanSort() &&
                  (() => {
                    const toggleSortingHandler =
                      header.column.getToggleSortingHandler();

                    return {
                      role: "button",
                      "aria-label": "Sort column",
                      tabIndex: 0,
                      onClick: toggleSortingHandler,
                      onKeyDown: (event) => {
                        if (event.key === "Enter" || event.key === " ") {
                          toggleSortingHandler?.(event);
                        }
                      },
                      userSelect: "none",
                    };
                  })())}
                {...header.column.columnDef.meta?.headerProps}
                {...(header.column.columnDef.meta?.isRightAligned && {
                  textAlign: "right",
                })}
                {...(header.column.columnDef.meta?.isCenterAligned && {
                  textAlign: "center",
                })}
                {...(stickyHeaders && {
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  borderBottomWidth: 2,
                })}
                {...(stickyFirstColumn &&
                  headerIndex === 0 && {
                    left: 0,
                    zIndex: 3,
                    borderRightWidth: 1,
                  })}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}

                {(() => {
                  const sorted = header.column.getIsSorted();

                  if (sorted === false) {
                    return null;
                  }

                  return (
                    <FaIcon
                      icon={sorted === "asc" ? faArrowUpLong : faArrowDownLong}
                      size="xs"
                      ml={2}
                    />
                  );
                })()}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>

      {renderBody(
        isLoading ? (
          Array.from({ length: loadingRows }).map((_, index) => (
            <Tr key={index} data-testid="table-skeleton-loader-row">
              <Td colSpan={table.getVisibleLeafColumns().length}>
                <Skeleton>Loading...</Skeleton>
              </Td>
            </Tr>
          ))
        ) : !table.getRowModel().rows.length ? (
          <Tr>
            <Td colSpan={table.getVisibleLeafColumns().length} borderWidth={0}>
              {renderEmptyData?.() ?? <NoResultsFound />}
            </Td>
          </Tr>
        ) : (
          <>
            {table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                {renderRow({
                  row,
                  children: row.getVisibleCells().map((cell, cellIndex) => {
                    const isSticky = stickyFirstColumn && cellIndex === 0;
                    const Component = isSticky ? Th : Td;

                    return (
                      <Component
                        key={cell.id}
                        {...cell.column.columnDef.meta?.getCellProps?.(cell)}
                        {...(cell.column.columnDef.meta?.isRightAligned && {
                          textAlign: "right",
                        })}
                        {...(cell.column.columnDef.meta?.isCenterAligned && {
                          textAlign: "center",
                        })}
                        {...(isSticky && {
                          position: "sticky",
                          left: 0,
                          zIndex: 1,
                          borderRightWidth: 1,
                        })}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Component>
                    );
                  }),
                })}

                {row.getIsExpanded() && renderSubRows
                  ? renderSubRows({ parentRow: row })
                  : null}
              </Fragment>
            ))}

            {showLoadingRow ? (
              <Tr ref={loadingRowRef}>
                <Td colSpan={table.getVisibleLeafColumns().length}>
                  <HStack
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Loader size="sm" />
                    <Text>Loading</Text>
                  </HStack>
                </Td>
              </Tr>
            ) : null}
          </>
        ),
      )}
    </Table>
  );
}

export function ReorderableTbody<T>({
  children,
  onReorder,
  values,
}: {
  children: ReactNode;
  onReorder: (newValues: T[]) => void;
  values: T[];
}) {
  return (
    <Reorder.Group as={Tbody as any} onReorder={onReorder} values={values}>
      {children}
    </Reorder.Group>
  );
}

const DragControlsContext = createContext<DragControls | null>(null);

export function ReorderableRow({
  value,
  children,
}: {
  value: any;
  children: ReactNode;
}) {
  const dragControls = useDragControls();

  return (
    <Reorder.Item
      as={Tr as any}
      dragListener={false}
      dragControls={dragControls}
      value={value}
    >
      <DragControlsContext.Provider value={dragControls}>
        {children}
      </DragControlsContext.Provider>
    </Reorder.Item>
  );
}

export function ReorderableCell({
  children,
}: {
  children: (props: { dragControls: DragControls }) => ReactElement | null;
}) {
  const dragControls = useContext(DragControlsContext);

  if (!dragControls) {
    throw new Error(
      "ReorderableCell must be rendered inside of a ReorderableRow. See `TanstackTable.stories.tsx` for an example.",
    );
  }

  return children({ dragControls });
}

export function getExpanderColumnDef<TData>(): ColumnDef<TData> {
  return {
    id: "expander",
    cell: ({ row }) =>
      row.getCanExpand() ? <RowExpanderButton row={row} /> : null,
    meta: {
      headerProps: {
        width: "1%",
        pr: 0,
      },
      getCellProps: () => ({
        pr: 0,
      }),
    },
  };
}

export function RowExpanderButton<TData>({
  row,
  ...props
}: ButtonProps & { row: Row<TData> }) {
  const isExpanded = row.getIsExpanded();

  return (
    <IconButton
      variant="unstyled"
      size="xs"
      _focusVisible={{
        outline: "auto",
      }}
      icon={
        <FaIcon
          icon={faAngleRight}
          rotation={isExpanded ? 90 : undefined}
          transition="transform .1s ease-in-out"
        />
      }
      aria-label={isExpanded ? "Collapse row" : "Expand row"}
      onClick={row.getToggleExpandedHandler()}
      {...props}
    />
  );
}

export function PageSizeSelector({
  value,
  onChange,
  options,
}: {
  value: number;
  onChange: (pageSize: number) => void;
  options?: Array<{ label: string; value: number }>;
}) {
  return (
    <Select
      aria-label="Page size selector"
      options={
        options ?? [
          { label: "25 per page", value: 25 },
          { label: "50 per page", value: 50 },
          { label: "100 per page", value: 100 },
        ]
      }
      value={{ label: `${value} per page`, value }}
      onChange={(option) => {
        if (option) {
          onChange(option.value);
        }
      }}
      menuStyles={{ width: "auto", whiteSpace: "nowrap" }}
    />
  );
}

export const getOnPaginationChange =
  ({
    pagination,
    onPrevious,
    onNext,
  }: {
    pagination: PaginationState;
    onPrevious: () => void;
    onNext: () => void;
  }) =>
  (updaterOrValue: Updater<PaginationState>) => {
    const newPagination =
      typeof updaterOrValue === "function"
        ? updaterOrValue(pagination)
        : updaterOrValue;

    // If we're going to the previous
    if (newPagination.pageIndex < pagination.pageIndex) {
      onPrevious();
    } else if (newPagination.pageIndex > pagination.pageIndex) {
      onNext();
    }
  };

export const getOnSortingChange =
  ({
    sorting,
    onChange,
  }: {
    sorting: SortingState | undefined;
    onChange: (sort: string | undefined) => void;
  }) =>
  (updaterOrValue: Updater<SortingState>) => {
    const newSorting =
      typeof updaterOrValue === "function"
        ? updaterOrValue(sorting ?? [])
        : updaterOrValue;

    onChange(
      newSorting[0]
        ? `${newSorting[0].desc ? "-" : ""}${newSorting[0].id}`
        : undefined,
    );
  };

/**
 *  Converts tanstack's format to one such as property:direction
 *
 *  Use this for cloud, parcel or other signal-template originated apps
 */
export const getOnSortingChangeColonFormat =
  ({
    sorting,
    onChange,
  }: {
    sorting: SortingState;
    onChange: (sort: string | undefined) => void;
  }) =>
  (updaterOrValue: Updater<SortingState>) => {
    const newSorting =
      typeof updaterOrValue === "function"
        ? updaterOrValue(sorting)
        : updaterOrValue;

    onChange(
      newSorting[0]
        ? `${newSorting[0].id}:${newSorting[0].desc ? "desc" : "asc"}`
        : undefined,
    );
  };

export function usePagination({
  pageSize,
  metadata,
}: {
  pageSize: number;
  metadata:
    | undefined
    | {
        total_count: number;
        before: string | null;
        after: string | null;
      };
}): {
  pageCount: number;
  pagination: PaginationState;
} {
  return useMemo(() => {
    if (!metadata) {
      return {
        pageCount: 0,
        pagination: { pageSize, pageIndex: 0 },
      };
    }

    const pageCount = Math.ceil(metadata.total_count / pageSize);

    return {
      pageCount,
      pagination: {
        pageSize,
        pageIndex: (() => {
          // If there are no records before
          if (!metadata.before) {
            // We're on the first page
            return 0;
          }

          // If there are no records after
          if (!metadata.after) {
            // We're on the last page
            return pageCount - 1;
          }

          return 1;
        })(),
      },
    };
  }, [metadata, pageSize]);
}

/**
 * Convert a sort string of the format `-property` or `property` to Tanstack Table's sorting type `[{ id: string; desc: boolean}]`
 * @param sort
 */
export function useSorting({
  sort,
}: {
  sort: string | undefined;
}): SortingState | undefined {
  return useMemo(() => {
    if (!sort) {
      return undefined;
    }

    if (sort.startsWith("-")) {
      return [{ id: sort.slice(1), desc: true }];
    }

    return [{ id: sort, desc: false }];
  }, [sort]);
}

/**
 * Convert a sort string of the format `property:direction` or `property` to Tanstack Table's sorting type `[{ id: string; desc: boolean}]`
 *
 * Use this for cloud, parcel or other signal-template originated apps
 * @param sort
 */
export function useSortingColonFormat({
  sort,
}: {
  sort: string | undefined;
}): SortingState | undefined {
  return useMemo(() => {
    if (!sort) {
      return undefined;
    }

    const [property, direction] = sort.split(":");

    return [{ id: property, desc: direction === "desc" ? true : false }];
  }, [sort]);
}
