import type { Dispatch, SetStateAction } from "react";

import type { FaIconProps } from "@stordco/fe-components";

import type { NavigationState } from "./provider";

export const isNavigationParent = (
  act: NavigationItem,
): act is NavigationParent => "children" in act;

export type NavigationItem = NavigationLink | NavigationParent;

export type NavigationLink = {
  icon: FaIconProps["icon"];
  label: string;
  children?: undefined;
  isExternal?: boolean;
  onClick?: () => void;
  to?: string;
};

export type NavigationChild = {
  icon: FaIconProps["icon"];
  label: string;
  isExternal?: boolean;
  onClick?: () => void;
  to?: string;
};

export type NavigationParent = {
  icon: FaIconProps["icon"];
  label: string;
  children: NavigationChild[];
};

export type NavigationLinks = {
  primary: NavigationItem[];
};

export interface NavigationContextProps {
  links: NavigationLinks;
  navigationState: NavigationState;
  setNavigationState: Dispatch<SetStateAction<NavigationState>>;
}
