import { type EnvConfig } from "./api";

import type { RootState } from ".";
import type { JsonErrorResponse } from "./services/api.generated";
import { isBaseError } from "@stordco/fe-components";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query";

/**
 * If either :// or // is present consider it to be an absolute url
 *
 * @param url string
 */

export function isAbsoluteUrl(url: string) {
  return new RegExp("(^|:)//").test(url);
}

export const fetchStaticConfig = async () =>
  fetch("/config.json").then((r) => r.json() as Promise<EnvConfig>);

export const buildAuth0ClientOptions = (config: RootState["config"]) => ({
  domain: config.AUTH0_DOMAIN,
  client_id: config.AUTH0_CLIENT_ID,
});

export const hasCloudValidationErrors = (
  error: unknown,
): error is FetchBaseQueryError & { data: JsonErrorResponse } => {
  return (
    isBaseError(error) &&
    error.status === 422 &&
    "errors" in (error.data as any)
  );
};
