import { parcelApi as api } from "./base.api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getV1CarrierAccounts: build.query<
      GetV1CarrierAccountsApiResponse,
      GetV1CarrierAccountsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accounts`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    postV1CarrierAccounts: build.mutation<
      PostV1CarrierAccountsApiResponse,
      PostV1CarrierAccountsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accounts`,
        method: "POST",
        body: queryArg,
      }),
    }),
    deleteV1CarrierAccountsById: build.mutation<
      DeleteV1CarrierAccountsByIdApiResponse,
      DeleteV1CarrierAccountsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accounts/${queryArg}`,
        method: "DELETE",
      }),
    }),
    getV1CarrierAccountsById: build.query<
      GetV1CarrierAccountsByIdApiResponse,
      GetV1CarrierAccountsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/carrier_accounts/${queryArg}` }),
    }),
    patchV1CarrierAccountsById: build.mutation<
      PatchV1CarrierAccountsByIdApiResponse,
      PatchV1CarrierAccountsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accounts/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1CarrierAccountsById: build.mutation<
      PutV1CarrierAccountsByIdApiResponse,
      PutV1CarrierAccountsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/carrier_accounts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getV1FulfillmentCenters: build.query<
      GetV1FulfillmentCentersApiResponse,
      GetV1FulfillmentCentersApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fulfillment_centers`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    postV1FulfillmentCenters: build.mutation<
      PostV1FulfillmentCentersApiResponse,
      PostV1FulfillmentCentersApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fulfillment_centers`,
        method: "POST",
        body: queryArg,
      }),
    }),
    deleteV1FulfillmentCentersById: build.mutation<
      DeleteV1FulfillmentCentersByIdApiResponse,
      DeleteV1FulfillmentCentersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fulfillment_centers/${queryArg}`,
        method: "DELETE",
      }),
    }),
    getV1FulfillmentCentersById: build.query<
      GetV1FulfillmentCentersByIdApiResponse,
      GetV1FulfillmentCentersByIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/fulfillment_centers/${queryArg}` }),
    }),
    patchV1FulfillmentCentersById: build.mutation<
      PatchV1FulfillmentCentersByIdApiResponse,
      PatchV1FulfillmentCentersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fulfillment_centers/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1FulfillmentCentersById: build.mutation<
      PutV1FulfillmentCentersByIdApiResponse,
      PutV1FulfillmentCentersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/fulfillment_centers/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getV1Registrations: build.query<
      GetV1RegistrationsApiResponse,
      GetV1RegistrationsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registrations`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    postV1Registrations: build.mutation<
      PostV1RegistrationsApiResponse,
      PostV1RegistrationsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registrations`,
        method: "POST",
        body: queryArg,
      }),
    }),
    deleteV1RegistrationsById: build.mutation<
      DeleteV1RegistrationsByIdApiResponse,
      DeleteV1RegistrationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registrations/${queryArg}`,
        method: "DELETE",
      }),
    }),
    getV1RegistrationsById: build.query<
      GetV1RegistrationsByIdApiResponse,
      GetV1RegistrationsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/registrations/${queryArg}` }),
    }),
    patchV1RegistrationsById: build.mutation<
      PatchV1RegistrationsByIdApiResponse,
      PatchV1RegistrationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registrations/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateRegistration,
      }),
    }),
    putV1RegistrationsById: build.mutation<
      PutV1RegistrationsByIdApiResponse,
      PutV1RegistrationsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/registrations/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateRegistration,
      }),
    }),
    getV1Shippers: build.query<GetV1ShippersApiResponse, GetV1ShippersApiArg>({
      query: (queryArg) => ({
        url: `/v1/shippers`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    postV1Shippers: build.mutation<
      PostV1ShippersApiResponse,
      PostV1ShippersApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers`,
        method: "POST",
        body: queryArg,
      }),
    }),
    getV1ShippersById: build.query<
      GetV1ShippersByIdApiResponse,
      GetV1ShippersByIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/shippers/${queryArg}` }),
    }),
    patchV1ShippersById: build.mutation<
      PatchV1ShippersByIdApiResponse,
      PatchV1ShippersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ShippersById: build.mutation<
      PutV1ShippersByIdApiResponse,
      PutV1ShippersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getV1ShippersByShipperIdBillingAccounts: build.query<
      GetV1ShippersByShipperIdBillingAccountsApiResponse,
      GetV1ShippersByShipperIdBillingAccountsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    postV1ShippersByShipperIdBillingAccounts: build.mutation<
      PostV1ShippersByShipperIdBillingAccountsApiResponse,
      PostV1ShippersByShipperIdBillingAccountsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    deleteV1ShippersByShipperIdBillingAccountsAndId: build.mutation<
      DeleteV1ShippersByShipperIdBillingAccountsAndIdApiResponse,
      DeleteV1ShippersByShipperIdBillingAccountsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getV1ShippersByShipperIdBillingAccountsAndId: build.query<
      GetV1ShippersByShipperIdBillingAccountsAndIdApiResponse,
      GetV1ShippersByShipperIdBillingAccountsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts/${queryArg.id}`,
      }),
    }),
    patchV1ShippersByShipperIdBillingAccountsAndId: build.mutation<
      PatchV1ShippersByShipperIdBillingAccountsAndIdApiResponse,
      PatchV1ShippersByShipperIdBillingAccountsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1ShippersByShipperIdBillingAccountsAndId: build.mutation<
      PutV1ShippersByShipperIdBillingAccountsAndIdApiResponse,
      PutV1ShippersByShipperIdBillingAccountsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/billing_accounts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteV1ShippersByShipperIdCarrierAccountsAndCarrierAccountIdFulfillmentCentersFulfillmentCenterId:
      build.mutation<
        DeleteV1ShippersByShipperIdCarrierAccountsAndCarrierAccountIdFulfillmentCentersFulfillmentCenterIdApiResponse,
        DeleteV1ShippersByShipperIdCarrierAccountsAndCarrierAccountIdFulfillmentCentersFulfillmentCenterIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/shippers/${queryArg.shipperId}/carrier_accounts/${queryArg.carrierAccountId}/fulfillment_centers/${queryArg.fulfillmentCenterId}`,
          method: "DELETE",
        }),
      }),
    postV1ShippersByShipperIdCarrierAccountsAndCarrierAccountIdFulfillmentCentersFulfillmentCenterId:
      build.mutation<
        PostV1ShippersByShipperIdCarrierAccountsAndCarrierAccountIdFulfillmentCentersFulfillmentCenterIdApiResponse,
        PostV1ShippersByShipperIdCarrierAccountsAndCarrierAccountIdFulfillmentCentersFulfillmentCenterIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/shippers/${queryArg.shipperId}/carrier_accounts/${queryArg.carrierAccountId}/fulfillment_centers/${queryArg.fulfillmentCenterId}`,
          method: "POST",
        }),
      }),
    postV1ShippersByShipperIdDeliveryEstimate: build.mutation<
      PostV1ShippersByShipperIdDeliveryEstimateApiResponse,
      PostV1ShippersByShipperIdDeliveryEstimateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/delivery_estimate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getV1ShippersByShipperIdParcels: build.query<
      GetV1ShippersByShipperIdParcelsApiResponse,
      GetV1ShippersByShipperIdParcelsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    postV1ShippersByShipperIdParcels: build.mutation<
      PostV1ShippersByShipperIdParcelsApiResponse,
      PostV1ShippersByShipperIdParcelsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getV1ShippersByShipperIdParcelsBatchesAndBatchId: build.query<
      GetV1ShippersByShipperIdParcelsBatchesAndBatchIdApiResponse,
      GetV1ShippersByShipperIdParcelsBatchesAndBatchIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels/batches/${queryArg.batchId}`,
      }),
    }),
    postV1ShippersByShipperIdParcelsRateEstimate: build.mutation<
      PostV1ShippersByShipperIdParcelsRateEstimateApiResponse,
      PostV1ShippersByShipperIdParcelsRateEstimateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels/rate_estimate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    deleteV1ShippersByShipperIdParcelsAndId: build.mutation<
      DeleteV1ShippersByShipperIdParcelsAndIdApiResponse,
      DeleteV1ShippersByShipperIdParcelsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getV1ShippersByShipperIdParcelsAndId: build.query<
      GetV1ShippersByShipperIdParcelsAndIdApiResponse,
      GetV1ShippersByShipperIdParcelsAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/shippers/${queryArg.shipperId}/parcels/${queryArg.id}`,
      }),
    }),
    getV1TrackingEvents: build.query<
      GetV1TrackingEventsApiResponse,
      GetV1TrackingEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/tracking_events`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    postV1TrackingEvents: build.mutation<
      PostV1TrackingEventsApiResponse,
      PostV1TrackingEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/tracking_events`,
        method: "POST",
        body: queryArg,
      }),
    }),
    deleteV1TrackingEventsByTrackingEventId: build.mutation<
      DeleteV1TrackingEventsByTrackingEventIdApiResponse,
      DeleteV1TrackingEventsByTrackingEventIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/tracking_events/${queryArg}`,
        method: "DELETE",
      }),
    }),
    getV1TrackingEventsByTrackingEventId: build.query<
      GetV1TrackingEventsByTrackingEventIdApiResponse,
      GetV1TrackingEventsByTrackingEventIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/tracking_events/${queryArg}` }),
    }),
    patchV1TrackingEventsByTrackingEventId: build.mutation<
      PatchV1TrackingEventsByTrackingEventIdApiResponse,
      PatchV1TrackingEventsByTrackingEventIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/tracking_events/${queryArg.trackingEventId}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1TrackingEventsByTrackingEventId: build.mutation<
      PutV1TrackingEventsByTrackingEventIdApiResponse,
      PutV1TrackingEventsByTrackingEventIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/tracking_events/${queryArg.trackingEventId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    zipZoneMappingsIndex: build.query<
      ZipZoneMappingsIndexApiResponse,
      ZipZoneMappingsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zip_zone_mappings`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    zipZoneMappingsUpsert: build.mutation<
      ZipZoneMappingsUpsertApiResponse,
      ZipZoneMappingsUpsertApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zip_zone_mappings`,
        method: "PUT",
        body: queryArg,
      }),
    }),
    zipZoneMappingsExport: build.query<
      ZipZoneMappingsExportApiResponse,
      ZipZoneMappingsExportApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/zip_zone_mappings/export`,
        params: { sort: queryArg.sort, filter: queryArg.filter },
      }),
    }),
    postWebhooksEasyPostTrackingEvents: build.mutation<
      PostWebhooksEasyPostTrackingEventsApiResponse,
      PostWebhooksEasyPostTrackingEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/webhooks/easy_post/tracking_events`,
        method: "POST",
        body: queryArg,
      }),
    }),
    postWebhooksShipiumTrackingEvents: build.mutation<
      PostWebhooksShipiumTrackingEventsApiResponse,
      PostWebhooksShipiumTrackingEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/webhooks/shipium/tracking_events`,
        method: "POST",
        body: queryArg,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type GetV1CarrierAccountsApiResponse =
  /** status 200 A collection of carrier accounts */ {
    data: ShowCarrierAccount[];
    meta: PaginationMetadata;
  };
export type GetV1CarrierAccountsApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `carrier_name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier_name?: string;
    postal_code?: string;
  };
};
export type PostV1CarrierAccountsApiResponse =
  /** status 201 The created carrier_account */ {
    data: ShowCarrierAccount;
  };
export type PostV1CarrierAccountsApiArg =
  /** Carrier Account creation payload */ {
    data: CreateCarrierAccount;
  };
export type DeleteV1CarrierAccountsByIdApiResponse =
  /** status 204 Successful deletion of a carrier account */ string;
export type DeleteV1CarrierAccountsByIdApiArg =
  /** The carrier account id */ string;
export type GetV1CarrierAccountsByIdApiResponse =
  /** status 200 The Carrier Account */ {
    data: ShowCarrierAccount;
  };
export type GetV1CarrierAccountsByIdApiArg =
  /** The carrier account id */ string;
export type PatchV1CarrierAccountsByIdApiResponse =
  /** status 200 An updated carrier account */ {
    data: ShowCarrierAccount;
  };
export type PatchV1CarrierAccountsByIdApiArg = {
  /** The carrier account id */
  id: string;
  /** Carrier Account update payload */
  body: {
    data: UpdateCarrierAccount;
  };
};
export type PutV1CarrierAccountsByIdApiResponse =
  /** status 200 An updated carrier account */ {
    data: ShowCarrierAccount;
  };
export type PutV1CarrierAccountsByIdApiArg = {
  /** The carrier account id */
  id: string;
  /** Carrier Account update payload */
  body: {
    data: UpdateCarrierAccount;
  };
};
export type GetV1FulfillmentCentersApiResponse =
  /** status 200 A collection of fulfillment centers */ {
    data: ShowFulfillmentCenter[];
    meta: PaginationMetadata;
  };
export type GetV1FulfillmentCentersApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type PostV1FulfillmentCentersApiResponse =
  /** status 201 The created fulfillment_center */ {
    data: ShowFulfillmentCenter;
  };
export type PostV1FulfillmentCentersApiArg =
  /** Fulfillment center creation payload */ {
    data: CreateFulfillmentCenter;
  };
export type DeleteV1FulfillmentCentersByIdApiResponse =
  /** status 204 Successful deletion of a fulfillment center */ string;
export type DeleteV1FulfillmentCentersByIdApiArg =
  /** The fulfillment center id */ string;
export type GetV1FulfillmentCentersByIdApiResponse =
  /** status 200 The Fulfillment Center */ {
    data: ShowFulfillmentCenter;
  };
export type GetV1FulfillmentCentersByIdApiArg =
  /** The fulfillment center id */ string;
export type PatchV1FulfillmentCentersByIdApiResponse =
  /** status 200 An updated fulfillment center */ {
    data: ShowFulfillmentCenter;
  };
export type PatchV1FulfillmentCentersByIdApiArg = {
  /** The fulfillment id */
  id: string;
  /** Fulfillment center update payload */
  body: {
    data: UpdateFulfillmentCenter;
  };
};
export type PutV1FulfillmentCentersByIdApiResponse =
  /** status 200 An updated fulfillment center */ {
    data: ShowFulfillmentCenter;
  };
export type PutV1FulfillmentCentersByIdApiArg = {
  /** The fulfillment id */
  id: string;
  /** Fulfillment center update payload */
  body: {
    data: UpdateFulfillmentCenter;
  };
};
export type GetV1RegistrationsApiResponse =
  /** status 200 A collection of registrations */ {
    data: Registration[];
    meta: PaginationMetadata;
  };
export type GetV1RegistrationsApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `delivered_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type PostV1RegistrationsApiResponse =
  /** status 201 The created registrations */ {
    data: Registration;
  };
export type PostV1RegistrationsApiArg =
  /** Fulfillment center creation payload */ {
    data: Registration;
  };
export type DeleteV1RegistrationsByIdApiResponse =
  /** status 204 Successful deletion of a registration */ string;
export type DeleteV1RegistrationsByIdApiArg = /** The registration id */ string;
export type GetV1RegistrationsByIdApiResponse =
  /** status 200 The Registration */ {
    data: ShowRegistration;
  };
export type GetV1RegistrationsByIdApiArg = /** The registration id */ string;
export type PatchV1RegistrationsByIdApiResponse =
  /** status 200 An updated registration */ ShowRegistration;
export type PatchV1RegistrationsByIdApiArg = {
  /** The registration id */
  id: string;
  /** Registration update payload */
  updateRegistration: UpdateRegistration;
};
export type PutV1RegistrationsByIdApiResponse =
  /** status 200 An updated registration */ ShowRegistration;
export type PutV1RegistrationsByIdApiArg = {
  /** The registration id */
  id: string;
  /** Registration update payload */
  updateRegistration: UpdateRegistration;
};
export type GetV1ShippersApiResponse =
  /** status 200 A collection of shippers */ {
    data: Shipper[];
    meta: PaginationMetadata;
  };
export type GetV1ShippersApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `default_label_provider`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    default_label_provider?: string;
    external_reference?: string;
    name?: string;
  };
};
export type PostV1ShippersApiResponse = /** status 201 The shipper */ {
  data: Shipper;
};
export type PostV1ShippersApiArg = /** Shipper creation payload */ {
  data: CreateShipper;
};
export type GetV1ShippersByIdApiResponse = /** status 200 The shipper */ {
  data: Shipper;
};
export type GetV1ShippersByIdApiArg =
  /** The shipper id or external reference */ string;
export type PatchV1ShippersByIdApiResponse =
  /** status 200 An updated shipper */ {
    data: Shipper;
  };
export type PatchV1ShippersByIdApiArg = {
  /** The shipper id or external reference */
  id: string;
  /** Shipper update payload */
  body: {
    data: UpdateShipper;
  };
};
export type PutV1ShippersByIdApiResponse =
  /** status 200 An updated shipper */ {
    data: Shipper;
  };
export type PutV1ShippersByIdApiArg = {
  /** The shipper id or external reference */
  id: string;
  /** Shipper update payload */
  body: {
    data: UpdateShipper;
  };
};
export type GetV1ShippersByShipperIdBillingAccountsApiResponse =
  /** status 200 A collection of billing accounts */ {
    data: BillingAccount[];
    meta: PaginationMetadata;
  };
export type GetV1ShippersByShipperIdBillingAccountsApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `name`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    id?: string;
    name?: string;
  };
};
export type PostV1ShippersByShipperIdBillingAccountsApiResponse =
  /** status 201 The created billing account */ {
    data: BillingAccount;
  };
export type PostV1ShippersByShipperIdBillingAccountsApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** Billing Account creation payload */
  body: {
    data: CreateBillingAccount;
  };
};
export type DeleteV1ShippersByShipperIdBillingAccountsAndIdApiResponse =
  /** status 204 Successful deletion of a billing account */ string;
export type DeleteV1ShippersByShipperIdBillingAccountsAndIdApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The billing account id */
  id: string;
};
export type GetV1ShippersByShipperIdBillingAccountsAndIdApiResponse =
  /** status 200 The Billing Account */ {
    data: BillingAccount;
  };
export type GetV1ShippersByShipperIdBillingAccountsAndIdApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The billing account id */
  id: string;
};
export type PatchV1ShippersByShipperIdBillingAccountsAndIdApiResponse =
  /** status 200 An updated billing account */ {
    data: BillingAccount;
  };
export type PatchV1ShippersByShipperIdBillingAccountsAndIdApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The billing account id */
  id: string;
  /** Billing Account update payload */
  body: {
    data: UpdateBillingAccount;
  };
};
export type PutV1ShippersByShipperIdBillingAccountsAndIdApiResponse =
  /** status 200 An updated billing account */ {
    data: BillingAccount;
  };
export type PutV1ShippersByShipperIdBillingAccountsAndIdApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The billing account id */
  id: string;
  /** Billing Account update payload */
  body: {
    data: UpdateBillingAccount;
  };
};
export type DeleteV1ShippersByShipperIdCarrierAccountsAndCarrierAccountIdFulfillmentCentersFulfillmentCenterIdApiResponse =
  /** status 204 Successful deletion of a shipper carrier fulfillment context */ string;
export type DeleteV1ShippersByShipperIdCarrierAccountsAndCarrierAccountIdFulfillmentCentersFulfillmentCenterIdApiArg =
  {
    /** The shipper id */
    shipperId: string;
    /** The carrier account id */
    carrierAccountId: string;
    /** The fulfillment center id */
    fulfillmentCenterId: string;
  };
export type PostV1ShippersByShipperIdCarrierAccountsAndCarrierAccountIdFulfillmentCentersFulfillmentCenterIdApiResponse =
  /** status 201 A shipper carrier fulfillment context */ {
    data: ShipperCarrierFulfillment;
  };
export type PostV1ShippersByShipperIdCarrierAccountsAndCarrierAccountIdFulfillmentCentersFulfillmentCenterIdApiArg =
  {
    /** The shipper id */
    shipperId: string;
    /** The carrier account id */
    carrierAccountId: string;
    /** The fulfillment center id */
    fulfillmentCenterId: string;
  };
export type PostV1ShippersByShipperIdDeliveryEstimateApiResponse =
  /** status 200 The delivery estimate */ {
    data: DeliveryEstimate;
  };
export type PostV1ShippersByShipperIdDeliveryEstimateApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** Delivery estimate request payload */
  body: {
    data: RequestDeliveryEstimate;
  };
};
export type GetV1ShippersByShipperIdParcelsApiResponse =
  /** status 200 A collection of parcels */ {
    data: ShowParcel[];
    meta: PaginationMetadata;
  };
export type GetV1ShippersByShipperIdParcelsApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `carrier_name`, `label_provider`, `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier_name?: string;
    external_source_id?: string;
    label_provider?: string;
    tracking_number?: string;
  };
};
export type PostV1ShippersByShipperIdParcelsApiResponse =
  /** status 201 The created parcel */
  | {
      data: ShowParcel;
    }
  | /** status 202 Parcels Batch: Accepted */ {
      data?: {
        attributes: {
          status: string;
        };
        id: string;
        links?: {
          self?: string;
        };
        type: string;
      };
    };
export type PostV1ShippersByShipperIdParcelsApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** Parcel creation payload */
  body:
    | {
        data: CreateParcel;
      }
    | {
        data: CreateParcelBatch;
      };
};
export type GetV1ShippersByShipperIdParcelsBatchesAndBatchIdApiResponse =
  /** status 200 A collection of batch jobs */ {
    data: BatchJob[];
  };
export type GetV1ShippersByShipperIdParcelsBatchesAndBatchIdApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The batch id */
  batchId: string;
};
export type PostV1ShippersByShipperIdParcelsRateEstimateApiResponse =
  /** status 200 A collection parcel rate estimates */ {
    data: ShowRates[];
  };
export type PostV1ShippersByShipperIdParcelsRateEstimateApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** parcel rate payload */
  body: {
    data: RateEstimate;
  };
};
export type DeleteV1ShippersByShipperIdParcelsAndIdApiResponse =
  /** status 204 Successful deletion of a parcel */ string;
export type DeleteV1ShippersByShipperIdParcelsAndIdApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The parcel id */
  id: string;
};
export type GetV1ShippersByShipperIdParcelsAndIdApiResponse =
  /** status 200 The Parcel */ {
    data: ShowParcel;
  };
export type GetV1ShippersByShipperIdParcelsAndIdApiArg = {
  /** The shipper external reference */
  shipperId: string;
  /** The parcel id */
  id: string;
};
export type GetV1TrackingEventsApiResponse =
  /** status 200 A collection of tracking events */ {
    data: TrackingEvent[];
    meta: PaginationMetadata;
  };
export type GetV1TrackingEventsApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `occurred_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
};
export type PostV1TrackingEventsApiResponse =
  /** status 201 The created tracking_events */ {
    data: TrackingEvent;
  };
export type PostV1TrackingEventsApiArg =
  /** Fulfillment center creation payload */ {
    data: TrackingEvent;
  };
export type DeleteV1TrackingEventsByTrackingEventIdApiResponse =
  /** status 204 Successful deletion of a tracking event */ string;
export type DeleteV1TrackingEventsByTrackingEventIdApiArg =
  /** The tracking event id */ string;
export type GetV1TrackingEventsByTrackingEventIdApiResponse =
  /** status 200 The Tracking Event */ {
    data: ShowTrackingEvent;
  };
export type GetV1TrackingEventsByTrackingEventIdApiArg =
  /** The tracking event id */ string;
export type PatchV1TrackingEventsByTrackingEventIdApiResponse =
  /** status 200 An updated tracking event */ {
    data: ShowTrackingEvent;
  };
export type PatchV1TrackingEventsByTrackingEventIdApiArg = {
  /** The tracking event id */
  trackingEventId: string;
  /** Tracking Event update payload */
  body: {
    data: UpdateTrackingEvent;
  };
};
export type PutV1TrackingEventsByTrackingEventIdApiResponse =
  /** status 200 An updated tracking event */ {
    data: ShowTrackingEvent;
  };
export type PutV1TrackingEventsByTrackingEventIdApiArg = {
  /** The tracking event id */
  trackingEventId: string;
  /** Tracking Event update payload */
  body: {
    data: UpdateTrackingEvent;
  };
};
export type ZipZoneMappingsIndexApiResponse =
  /** status 200 A collection of zip zone mappings */ {
    data: ZipZoneMapping[];
    meta: PaginationMetadata;
  };
export type ZipZoneMappingsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier?: string | string[] | object;
    carrier_service_method?: string;
    destination_country?: string;
    destination_zip?: string;
    id?: string;
    origin_country?: string;
    origin_zip?: string;
  };
};
export type ZipZoneMappingsUpsertApiResponse =
  /** status 201 A collection of the zip zone mappings */ {
    data: ZipZoneMapping[];
  };
export type ZipZoneMappingsUpsertApiArg =
  /** Payload to upsert zip zone mappings */ {
    data: CreateZipZoneMapping[];
  };
export type ZipZoneMappingsExportApiResponse = unknown;
export type ZipZoneMappingsExportApiArg = {
  /** Sort by `inserted_at`, `updated_at`.
    You can specify the direction by appending `:asc` or `:desc`.
    Multiples should be passed in the format of `&sort=property:asc,property2:desc`
     */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    carrier?: string | string[] | object;
    carrier_service_method?: string;
    destination_country?: string;
    destination_zip?: string;
    id?: string;
    origin_country?: string;
    origin_zip?: string;
  };
};
export type PostWebhooksEasyPostTrackingEventsApiResponse =
  /** status 202 Acknowledge the webhook */ string;
export type PostWebhooksEasyPostTrackingEventsApiArg =
  /** Webhook tracking event payload */ WebhookEvent;
export type PostWebhooksShipiumTrackingEventsApiResponse =
  /** status 202 Acknowledge the webhook */ string;
export type PostWebhooksShipiumTrackingEventsApiArg =
  /** Webhook tracking event payload */ ShipiumWebhookEvent;
export type ShowCarrierAccount = {
  account_id?: string;
  address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  carrier_name?: string;
  description?: string | null;
  id?: string;
  inserted_at?: string;
  label_provider?: ("shipium" | "easy_post") | null;
  updated_at?: string;
};
export type PaginationMetadata = {
  page_number: number;
  page_size: number;
  total_entries: number;
  total_pages: number;
};
export type JsonErrorResponse = {
  errors: {
    detail: string;
    source: {
      pointer: string;
    };
    title: string;
  }[];
};
export type CreateCarrierAccount = {
  account_id: string;
  address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  carrier_name: string;
  description?: string | null;
  label_provider: ("shipium" | "easy_post") | null;
};
export type NotFound = {
  errors: {
    detail?: string;
  };
};
export type UpdateCarrierAccount = {
  account_id?: string;
  carrier_name?: string;
  description?: string | null;
  label_provider?: ("shipium" | "easy_post") | null;
};
export type ShowFulfillmentCenter = {
  address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  alias?: string;
  id?: string;
  inserted_at?: string;
  name?: string;
  shipium_origin_id?: string;
  updated_at?: string;
};
export type CreateFulfillmentCenter = {
  address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  alias: string;
  name: string;
  shipium_origin_id: string;
};
export type UpdateFulfillmentCenter = {
  address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  alias?: string;
  name?: string;
  shipium_origin_id?: string;
};
export type Registration = {
  carrier_estimated_delivery_date?: string | null;
  carrier_name: string;
  delivered_at?: string | null;
  initial_carrier_estimated_delivery_date?: string | null;
  label_provider: string;
  registered_at?: string | null;
  shipper_external_reference: string;
  shipper_id: string;
  source?: string | null;
  tracking_number: string;
};
export type ShowRegistration = {
  carrier_estimated_delivery_date?: string | null;
  carrier_name?: string;
  delivered_at?: string | null;
  initial_carrier_estimated_delivery_date?: string | null;
  label_provider?: string;
  registered_at?: string | null;
  shipper_external_reference?: string;
  shipper_id?: string;
  source?: string | null;
  tracking_number?: string;
};
export type UpdateRegistration = {
  carrier_estimated_delivery_date?: string | null;
  carrier_name?: string;
  delivered_at?: string | null;
  initial_carrier_estimated_delivery_date?: string | null;
  label_provider?: string;
  registered_at?: string | null;
  shipper_external_reference?: string;
  shipper_id?: string;
  source?: string | null;
  tracking_number?: string;
};
export type Shipper = {
  default_service_method?: string | null;
  enabled_label_providers?: ("shipium" | "easy_post")[];
  external_reference: string | null;
  fallback_service_method?: string | null;
  id: string;
  inserted_at?: string;
  name: string;
  updated_at?: string;
};
export type CreateShipper = {
  default_service_method?: string | null;
  enabled_label_providers?: ("shipium" | "easy_post")[];
  external_reference: string | null;
  fallback_service_method?: string | null;
  name: string;
};
export type UpdateShipper = {
  default_service_method?: string | null;
  enabled_label_providers?: ("shipium" | "easy_post")[];
  fallback_service_method?: string | null;
  name?: string;
};
export type BillingAccount = {
  account_number: string;
  billing_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  carrier_name: "fedex" | "usps" | "ups" | "dhl";
  id: string;
  inserted_at?: string;
  name: string;
  service_method?: string | null;
  shipper_id: string;
  updated_at?: string;
};
export type CreateBillingAccount = {
  account_number: string;
  billing_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  carrier_name: "fedex" | "usps" | "ups" | "dhl";
  name: string;
  service_method?: string | null;
};
export type UpdateBillingAccount = {
  account_number?: string;
  billing_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  carrier_name?: "fedex" | "usps" | "ups" | "dhl";
  name?: string;
  service_method?: string | null;
};
export type ShipperCarrierFulfillment = {
  carrier_account_id: string;
  fulfillment_center_id: string;
  id?: string;
  inserted_at?: string;
  shipper_id: string;
  updated_at?: string;
};
export type DeliveryEstimate = {
  alias: string;
  destination_country_code: string;
  destination_postal_code: string;
  estimated_days_in_transit: number;
  estimated_delivery_date: string;
  expected_ship_date: string;
  inserted_at?: string;
  request_date_override?: string;
  ship_option?: "standard";
  updated_at?: string;
};
export type RequestDeliveryEstimate = {
  alias: string;
  destination_country_code: string;
  destination_postal_code: string;
  expected_ship_date: string;
  request_date_override?: string;
  ship_option?: "standard";
};
export type Item = {
  description: string;
  hazmat?: boolean | null;
  hazmat_info?: {
    battery_count?: number | null;
    battery_installation?: ("installed" | "uninstalled") | null;
    contains_lithium?: boolean;
    hazard_class?: string;
    limited_quantity?: boolean;
    lithium_ion_content?: number | null;
    packing_group?: ("i" | "ii" | "iii") | null;
    proper_shipping_name?: string;
    transport_mode?:
      | "any"
      | "cargo_aircraft_only"
      | "ground"
      | "passenger_and_cargo_aircraft";
    un_id?: string;
  } | null;
  origin_country: string;
  price: number;
  product_sku: string;
  quantity: number;
  tariff_code?: string | null;
  unit_price?: number;
  weight: number;
};
export type Document = {
  document_encoding?: string;
  document_format: string;
  document_type?:
    | "commercial_invoice"
    | "electronic_export_information"
    | "cn22"
    | "air_waybill"
    | "multiple"
    | "nafta_certificate_of_origin"
    | "high_value_report"
    | "cod_return_label"
    | "order_summary";
  encoded_document: string;
  expires_at?: string;
  id: string;
  inserted_at?: string;
  is_electronic?: boolean;
  parcel_id: string;
  updated_at?: string;
};
export type ShowParcel = {
  id?: string;
  external_source_id?: string | null;
  return_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  items?: Item[];
  carrier_selection_method?: string | null;
  saturday_delivery?: boolean | null;
  weight?: number;
  incoterm?: ("DAP" | "DDP") | null;
  business_days_in_transit?: number | null;
  billing_account_id?: string | null;
  voided_at?: string | null;
  desired_delivery_date?: string | null;
  inserted_at?: string;
  updated_at?: string;
  tracking_url?: string;
  label_provider?: ("shipium" | "easy_post") | null;
  service_method?: string | null;
  external_label_id?: string | null;
  tracking_number?: string;
  to_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  label_rate?: number;
  carrier_ship_option?: string | null;
  documents?: Document[];
  from_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  length?: number;
  height?: number;
  width?: number;
  carrier_name?: string | null;
  third_party_billing_enabled?: boolean | null;
  shipping_label?: string;
  packaging_material?:
    | (
        | "box"
        | "fedex_one_rate_envelope"
        | "fedex_one_rate_extra_large_box"
        | "fedex_one_rate_large_box"
        | "fedex_one_rate_medium_box"
        | "fedex_one_rate_pak"
        | "fedex_one_rate_small_box"
        | "fedex_one_rate_tube"
      )
    | null;
  currency_code?: string;
  expected_shipped_at_date?: string | null;
  external_id?: string | null;
  signature_required?:
    | (
        | "signature"
        | "resident_signature"
        | "adult_signature"
        | "adult_resident_signature"
      )
    | null;
};
export type CreateParcel = {
  business_days_in_transit?: number | null;
  carrier_ship_option?: string | null;
  currency_code: string;
  desired_delivery_date?: string | null;
  expected_shipped_at_date?: string | null;
  external_id?: string | null;
  external_source_id: string | null;
  fallback_service_method?: string | null;
  from_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  height: number;
  incoterm?: ("DAP" | "DDP") | null;
  items: Item[];
  length: number;
  order_metadata?: {
    carrier_processing_id?: string | null;
  } | null;
  packaging_material?:
    | (
        | "box"
        | "fedex_one_rate_envelope"
        | "fedex_one_rate_extra_large_box"
        | "fedex_one_rate_large_box"
        | "fedex_one_rate_medium_box"
        | "fedex_one_rate_pak"
        | "fedex_one_rate_small_box"
        | "fedex_one_rate_tube"
      )
    | null;
  print_option_1?: string | null;
  print_option_2?: string | null;
  print_option_3?: string | null;
  return_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  } | null;
  saturday_delivery?: boolean | null;
  service_method?: string | null;
  signature_required?:
    | (
        | "signature"
        | "resident_signature"
        | "adult_signature"
        | "adult_resident_signature"
      )
    | null;
  tags?: string[] | null;
  to_address: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  weight: number;
  width: number;
  zone?: string | null;
};
export type CreateParcelBatch = {
  parcels: CreateParcel[];
};
export type BatchJob = {
  error?: string | null;
  id: string;
  parcel?: {
    print_option_1?: string | null;
    id?: string;
    external_source_id?: string | null;
    external_order_number?: string | null;
    return_address?: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    } | null;
    items?: Item[];
    carrier_selection_method?: string | null;
    saturday_delivery?: boolean | null;
    weight?: number;
    print_option_2?: string | null;
    incoterm?: ("DAP" | "DDP") | null;
    print_option_3?: string | null;
    business_days_in_transit?: number | null;
    billing_account_id?: string | null;
    voided_at?: string | null;
    desired_delivery_date?: string | null;
    inserted_at?: string;
    updated_at?: string;
    order_metadata?: {
      carrier_processing_id?: string | null;
    } | null;
    expected_delivery_at_date?: string | null;
    zone?: string | null;
    tracking_url?: string;
    label_provider?: ("shipium" | "easy_post") | null;
    service_method?: string | null;
    tags?: string[] | null;
    external_label_id?: string | null;
    tracking_number?: string;
    to_address?: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    };
    label_rate?: number;
    carrier_ship_option?: string | null;
    documents?: Document[];
    fallback_service_method?: string | null;
    from_address?: {
      city: string;
      company?: string | null;
      country: string;
      email?: string | null;
      external_location_id?: string | null;
      line1: string;
      line2?: string | null;
      line3?: string | null;
      name: string;
      phone?: string | null;
      postal_code?: string | null;
      state?: string | null;
      type: "residential" | "commercial";
    };
    length?: number;
    height?: number;
    shipper_id?: string;
    width?: number;
    carrier_name?: string | null;
    third_party_billing_enabled?: boolean | null;
    shipping_label?: string;
    label_format?: string | null;
    packaging_material?:
      | (
          | "box"
          | "fedex_one_rate_envelope"
          | "fedex_one_rate_extra_large_box"
          | "fedex_one_rate_large_box"
          | "fedex_one_rate_medium_box"
          | "fedex_one_rate_pak"
          | "fedex_one_rate_small_box"
          | "fedex_one_rate_tube"
        )
      | null;
    currency_code?: string;
    expected_shipped_at_date?: string | null;
    external_id?: string | null;
    signature_required?:
      | (
          | "signature"
          | "resident_signature"
          | "adult_signature"
          | "adult_resident_signature"
        )
      | null;
  } | null;
  status: "completed" | "pending" | "failed";
};
export type EvaluatedServiceMethod = {
  carrier_name?: string;
  label_rate?: number;
  service_method_name?: string;
};
export type Rate = {
  carrier_name?: string | null;
  currency_code?: string;
  evaluated_service_methods?: EvaluatedServiceMethod[];
  expected_delivery_at_date?: string | null;
  label_rate?: number;
  metadata?: {
    shipium_shipment_id?: string;
  };
  service_method?: string | null;
};
export type ShowRates = {
  external_source_id?: string | null;
  from_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  height?: number;
  items?: Item[];
  length?: number;
  rates?: Rate[];
  to_address?: {
    city: string;
    company?: string | null;
    country: string;
    email?: string | null;
    external_location_id?: string | null;
    line1: string;
    line2?: string | null;
    line3?: string | null;
    name: string;
    phone?: string | null;
    postal_code?: string | null;
    state?: string | null;
    type: "residential" | "commercial";
  };
  weight?: number;
  width?: number;
};
export type RateEstimate =
  | {
      business_days_in_transit: number | null;
      currency_code?: string;
      external_source_id?: string | null;
      height?: number;
      incoterm?: ("DAP" | "DDP") | null;
      items?: Item[];
      length?: number;
      locations?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      }[];
      rate_type?: string;
      saturday_delivery?: boolean | null;
      signature_required?:
        | (
            | "signature"
            | "resident_signature"
            | "adult_signature"
            | "adult_resident_signature"
          )
        | null;
      to_address?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      };
      weight?: number;
      width?: number;
    }
  | {
      carrier_ship_option: string | null;
      currency_code?: string;
      external_source_id?: string | null;
      height?: number;
      incoterm?: ("DAP" | "DDP") | null;
      items?: Item[];
      length?: number;
      locations?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      }[];
      rate_type?: string;
      saturday_delivery?: boolean | null;
      signature_required?:
        | (
            | "signature"
            | "resident_signature"
            | "adult_signature"
            | "adult_resident_signature"
          )
        | null;
      to_address?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      };
      weight?: number;
      width?: number;
    }
  | {
      currency_code?: string;
      external_source_id?: string | null;
      height?: number;
      incoterm?: ("DAP" | "DDP") | null;
      items?: Item[];
      length?: number;
      locations?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      }[];
      rate_type?: string;
      saturday_delivery?: boolean | null;
      service_method: string | null;
      signature_required?:
        | (
            | "signature"
            | "resident_signature"
            | "adult_signature"
            | "adult_resident_signature"
          )
        | null;
      to_address?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      };
      weight?: number;
      width?: number;
    }
  | {
      currency_code?: string;
      desired_delivery_date: string | null;
      external_source_id?: string | null;
      height?: number;
      incoterm?: ("DAP" | "DDP") | null;
      items?: Item[];
      length?: number;
      locations?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      }[];
      rate_type?: string;
      saturday_delivery?: boolean | null;
      signature_required?:
        | (
            | "signature"
            | "resident_signature"
            | "adult_signature"
            | "adult_resident_signature"
          )
        | null;
      to_address?: {
        city: string;
        company?: string | null;
        country: string;
        email?: string | null;
        external_location_id?: string | null;
        line1: string;
        line2?: string | null;
        line3?: string | null;
        name: string;
        phone?: string | null;
        postal_code?: string | null;
        state?: string | null;
        type: "residential" | "commercial";
      };
      weight?: number;
      width?: number;
    };
export type TrackingEvent = {
  carrier_name?: string;
  country_code?: string | null;
  country_subdivision_code?: string | null;
  description?: string | null;
  inserted_at?: string;
  locality?: string | null;
  occurred_at: string;
  postal_code?: string | null;
  source: string;
  status:
    | "registered"
    | "label_printed"
    | "delivered"
    | "exception"
    | "in_transit"
    | "out_for_delivery"
    | "unknown";
  tracking_event_id?: string;
  tracking_number: string;
  updated_at?: string;
};
export type ShowTrackingEvent = {
  carrier_name?: string;
  country_code?: string | null;
  country_subdivision_code?: string | null;
  description?: string | null;
  inserted_at?: string;
  locality?: string | null;
  occurred_at?: string;
  postal_code?: string | null;
  source?: string;
  status?:
    | "registered"
    | "label_printed"
    | "delivered"
    | "exception"
    | "in_transit"
    | "out_for_delivery"
    | "unknown";
  tracking_event_id?: string;
  tracking_number?: string;
  updated_at?: string;
};
export type UpdateTrackingEvent = {
  carrier_name?: string;
  country_code?: string | null;
  country_subdivision_code?: string | null;
  description?: string | null;
  locality?: string | null;
  occurred_at?: string;
  postal_code?: string | null;
  source?: string;
  status?:
    | "registered"
    | "label_printed"
    | "delivered"
    | "exception"
    | "in_transit"
    | "out_for_delivery"
    | "unknown";
  tracking_number?: string;
};
export type ZipZoneMapping = {
  carrier?: string | null;
  carrier_service_method?: string | null;
  destination_country: string;
  destination_state: string;
  destination_zip_end: string;
  destination_zip_start: string;
  effective_at: string;
  expires_at?: string | null;
  id?: string;
  inserted_at?: string;
  max_weight?: number | null;
  origin_country: string;
  origin_state: string;
  origin_zip_end: string;
  origin_zip_start: string;
  updated_at?: string;
  zone: string;
};
export type CreateZipZoneMapping = {
  carrier?: string | null;
  carrier_service_method?: string | null;
  destination_country: string;
  destination_state: string;
  destination_zip_end: string;
  destination_zip_start: string;
  effective_at: string;
  expires_at?: string | null;
  max_weight?: number | null;
  origin_country: string;
  origin_state: string;
  origin_zip_end: string;
  origin_zip_start: string;
  zone: string;
};
export type TrackingLocation = {
  city?: string | null;
  country?: string | null;
  object: "TrackingLocation";
  state?: string | null;
  zip?: string | null;
} | null;
export type CarrierDetail = {
  alternate_identifier?: string | null;
  container_type?: string | null;
  destination_location?: string | null;
  destination_tracking_location?: TrackingLocation;
  est_delivery_date_local?: string | null;
  est_delivery_time_local?: string | null;
  guaranteed_delivery_date?: string | null;
  initial_delivery_attempt?: string | null;
  object?: "CarrierDetail";
  origin_location?: string | null;
  origin_tracking_location?: TrackingLocation;
  service?: string | null;
} | null;
export type Fee = {
  amount: number;
  charged: boolean;
  object: "Fee";
  refunded: boolean;
  type:
    | "LabelFee"
    | "CarbonOffsetFee"
    | "PostageFee"
    | "InsuranceFee"
    | "TrackerFee";
};
export type TrackingDetail = {
  carrier_code?: string | null;
  datetime: string;
  description?: string | null;
  message: string;
  object: "TrackingDetail";
  source: string;
  status: string;
  status_detail: string | null;
  tracking_location?: TrackingLocation;
};
export type Tracker = {
  carrier: string;
  carrier_detail?: CarrierDetail;
  created_at: string;
  est_delivery_date?: string | null;
  fees?: Fee[];
  finalized: boolean;
  id: string;
  is_return: boolean;
  mode: "test" | "production";
  object: "Tracker";
  public_url: string;
  shipment_id?: string | null;
  signed_by?: string | null;
  status: string;
  status_detail: string;
  tracking_code: string;
  tracking_details?: TrackingDetail[];
  updated_at: string;
  weight?: number | null;
};
export type WebhookEvent = {
  created_at: string;
  description: string | null;
  id: string;
  mode: string;
  object: string;
  result: Tracker;
  updated_at: string;
};
export type DetailedTrackingEvent = {
  carrierDescription?: string;
  city?: string | null;
  country?: string | null;
  eventDate: string;
  postalCode?: (string | null) | number;
  region?: string | null;
  shipmentStatus:
    | "Delivered"
    | "Exception"
    | "In Transit"
    | "Label Printed"
    | "Out For Delivery"
    | "Registered"
    | "Unknown";
};
export type Tracking = {
  carrierEstimatedDeliveryDate?: string | null;
  carrierId?: string;
  carrierServiceMethodId?: string | null;
  carrierTrackingId?: string;
  deliveredAtDateTime?: string | null;
  originalCarrierEstimatedDeliveryDate?: string | null;
  shipiumTrackingId: string;
  shipmentStatus?:
    | "Delivered"
    | "Exception"
    | "In Transit"
    | "Label Printed"
    | "Out For Delivery"
    | "Registered"
    | "Unknown";
  shippedDateTime?: string | null;
  trackingEvents: DetailedTrackingEvent[];
};
export type ShipiumEvent = {
  metadata?: {
    eventId?: string;
    eventTimestamp?: string;
    eventType?: "tracking_updated";
    payloadSchemaVersion?: "v1";
    testEvent?: boolean;
  };
  payload: {
    trackings: Tracking[];
  };
};
export type ShipiumWebhookEvent = {
  events: ShipiumEvent[];
};
