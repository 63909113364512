import { createApi } from "@reduxjs/toolkit/query/react";

import { default as baseQuery } from "./baseQuery";

export const reducerPath = "parcel-api";

export const parcelApi = createApi({
  reducerPath,
  baseQuery,
  endpoints: () => ({}),
});
