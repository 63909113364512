import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import type { ReactNode } from "react";
import { Global } from "@emotion/react";

import { useIsDesktop } from "@stordco/fe-components";

import { OrganizationSwitcherModal } from "../../features/organizationSwitcher/OrganizationSwitcherModal";

import { MobileSidebar } from "./Navigation/MobileSidebar";
import { Sidebar, useNavigation } from "./Navigation";

import { Navbar } from ".";

import "./toast-adjustments.css";

export const LayoutWrapper = ({
  includeSearch = false,
  children,
}: {
  includeSearch?: boolean;
  children: ReactNode;
}) => {
  const isDesktop = useIsDesktop();
  const mobileNavControl = useDisclosure();
  const { navigationState } = useNavigation();

  return (
    <>
      <Global
        styles={{
          ":root": {
            "--sidebar-width-collapsed": "4rem",
            "--sidebar-width-expanded": "16rem",
          },
        }}
      />

      <Navbar
        includeSearch={includeSearch}
        onMobileToggle={mobileNavControl.onToggle}
        mobileIsOpen={mobileNavControl.isOpen}
        onMobileClose={mobileNavControl.onClose}
      />
      <Flex
        direction={{ base: "column", lg: "row" }}
        height="calc(100vh - 56px)"
        backgroundColor="bg-canvas"
        overflowY="hidden"
      >
        {isDesktop ? <Sidebar /> : <MobileSidebar {...mobileNavControl} />}

        <Box
          flex="1"
          overflowY="auto"
          transition="margin-left 0.2s ease-out"
          px={{ base: 4, lg: 12 }}
          py={6}
          {...(isDesktop && {
            marginLeft:
              navigationState === "expanded"
                ? "var(--sidebar-width-expanded)"
                : "var(--sidebar-width-collapsed)",
          })}
        >
          {children}
        </Box>
      </Flex>

      <OrganizationSwitcherModal />
    </>
  );
};
