import type {
  CheckboxProps as ChakraCheckboxProps,
  FormLabelProps,
  StackProps,
} from "@chakra-ui/react";
import {
  Checkbox as ChakraCheckbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  VisuallyHidden,
} from "@chakra-ui/react";
import type {
  FieldPath,
  FieldValues,
  UseControllerProps,
} from "react-hook-form";
import { useController } from "react-hook-form";

import { ConditionalWrapper } from "../ConditionalWrapper";

import { getFieldErrorMessage } from "./utils";

/**
 * Creates a `Checkbox` component that is controlled by the react-hook-form library.
 * @remarks You can use the `direction` prop to change the layout of the checkbox and label
 * @example
 * ```
 * <RHFCheckbox name="some_field_name" label="some label" control={control} direction="row" />
 * ```
 */
export function RHFCheckbox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  label,
  hideLabel = false,
  labelProps,
  isRequired,
  isDisabled,
  direction = "column-reverse",
  // Controller props
  name,
  rules,
  shouldUnregister,
  control,
  stackProps,
  ...checkboxProps
}: UseControllerProps<TFieldValues, TName> &
  ChakraCheckboxProps & {
    label: string;
    hideLabel?: boolean;
    labelProps?: FormLabelProps;
    direction?: StackProps["direction"];
    stackProps?: StackProps;
  }) {
  const { field, formState } = useController({
    defaultValue: false as any,
    name,
    rules,
    shouldUnregister,
    control,
  });

  return (
    <FormControl
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={!!(formState.errors as any)[field.name]}
      id={field.name}
    >
      <Stack direction={direction} {...stackProps}>
        <ChakraCheckbox
          isDisabled={isDisabled}
          isChecked={field.value}
          _dark={{ borderColor: field.value ? undefined : "gray.700" }}
          {...field}
          {...checkboxProps}
        />
        <ConditionalWrapper
          condition={hideLabel}
          wrapper={(children) => <VisuallyHidden>{children}</VisuallyHidden>}
        >
          <FormLabel htmlFor={field.name} marginInlineEnd={2} {...labelProps}>
            {label}
          </FormLabel>
        </ConditionalWrapper>
      </Stack>
      <FormErrorMessage>
        {getFieldErrorMessage(formState, field.name)}
      </FormErrorMessage>
    </FormControl>
  );
}
