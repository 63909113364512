import {
  Badge,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import {
  faCheck,
  faEllipsisVertical,
  faXmark,
  faCopy,
} from "@fortawesome/pro-regular-svg-icons";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Suspense, useCallback, useMemo, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { debounce } from "lodash-es";
import {
  getLocalTimeZone,
  parseDate,
  toZoned,
  today,
} from "@internationalized/date";

import {
  FaIcon,
  FixedHeightTableLayout,
  getDetailedLocationIndicatorText,
  StackedCell,
  TanstackTable,
  TanstackTablePagination,
  useSearchParams,
  RHFInput,
  useBaseFilter,
  Filter,
  ChakraSearch,
  NoFilteredResultsFound,
  ErrorFetchingData,
  NoResultsFound,
  DatePicker,
  RHFCheckbox,
  PageLoader,
} from "@stordco/fe-components";

import {
  useTanstackPagination,
  useTanstackPaginationChange,
} from "../../../../helpers/table";
import { useApi, useIsAdminContext, useOrganization } from "../../../../hooks";
import { useCloudPermissions } from "../../../../hooks/useCloudPermissions";
import type { ApiKey } from "../../../../store/services/api.generated";
import { DateTimeCell } from "../../../../components/DateTimeCell";

type ModalControl = {
  action: "rotate" | "edit" | "expire" | "view";
  apiKey: ApiKey;
};

export function ApiKeyDataTable({
  organizationId: providedOrganizationId,
}: {
  organizationId?: string;
}) {
  const isAdminContext = useIsAdminContext();
  const [hasWritePermission] = useCloudPermissions("api_keys", "write");
  const { organizationId } = useParams();
  const organizationIdToUse = providedOrganizationId ?? organizationId;
  const api = useApi();
  const [params, { append, clear }] = useSearchParams();
  const [modalControl, setModalControl] = useState<ModalControl | null>(null);
  const [searchTerm, setSearchTerm] = useState((params.search as string) ?? "");

  const statusFilter = useBaseFilter({
    scope: "Status",
    options: [
      { value: "active", label: "Active" },
      { value: "expired", label: "Expired" },
    ],
    queryParam: "status",
  });

  const onSearch = useCallback(
    (search: string) => {
      append({
        page: undefined,
        search: search || undefined,
      });
    },
    [append],
  );

  const debouncedOnSearch = useMemo(() => debounce(onSearch, 200), [onSearch]);

  const filterParams = ["status"];

  const { data, isLoading, isError, refetch } = api.useApiKeysIndexQuery({
    organizationId: organizationIdToUse!,
    page: Number(params.page) || 1,
    filter: {
      expired:
        params.status === undefined
          ? undefined
          : params.status === "expired"
          ? "true"
          : "false",
    },
    search: params.search ? `%${params.search}%` : undefined,
  });

  const { pageCount, pagination } = useTanstackPagination(data?.meta);
  const { onPaginationChange } = useTanstackPaginationChange(data?.meta);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Omit<ApiKey, "permissions">>();

    return [
      columnHelper.accessor("name", {
        header: "Name",
        cell: ({ getValue, row: { original } }) => (
          <StackedCell
            lines={[
              getValue(),
              `Last 4: ${original.secret_key_last_4}`,
              original.description,
            ]}
            to={original.id}
          />
        ),
      }),
      columnHelper.display({
        header: "Status",
        cell: ({ row: { original } }) => {
          const isInactive =
            original.expires_at && new Date() > new Date(original.expires_at);
          return (
            <Badge colorScheme={isInactive ? "gray" : "green"}>
              {isInactive ? "Expired" : "Active"}
            </Badge>
          );
        },
      }),
      ...(isAdminContext
        ? [
            columnHelper.display({
              header: "System",
              cell: ({ row: { original } }) => {
                const isSystem = original.system;
                return isSystem ? (
                  <FaIcon icon={faCheck} color="green.500" />
                ) : (
                  <FaIcon icon={faXmark} color="gray.500" />
                );
              },
            }),
          ]
        : []),
      columnHelper.accessor("last_used_at", {
        header: "Last used",
        cell: ({ getValue }) => {
          return <DateTimeCell value={getValue()} />;
        },
      }),
      columnHelper.accessor("expires_at", {
        header: "Expires",
        cell: ({ getValue }) => {
          return <DateTimeCell value={getValue()} />;
        },
      }),
      columnHelper.accessor("inserted_at", {
        header: "Inserted",
        cell: ({ getValue }) => {
          return <DateTimeCell value={getValue()} />;
        },
      }),
      columnHelper.display({
        id: "actions",
        cell: ({ row: { original } }) => {
          const isInactive =
            original.expires_at && new Date() > new Date(original.expires_at);

          if (!hasWritePermission || isInactive) return null;

          return (
            <Menu>
              <MenuButton
                as={IconButton}
                variant="outline"
                colorScheme="gray"
                size="sm"
                icon={<FaIcon icon={faEllipsisVertical} />}
              />
              <MenuList>
                <MenuItem
                  onClick={() =>
                    setModalControl({ action: "edit", apiKey: original })
                  }
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    setModalControl({ action: "rotate", apiKey: original })
                  }
                >
                  Rotate
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    setModalControl({
                      action: "expire",
                      apiKey: original,
                    })
                  }
                >
                  Expire
                </MenuItem>
              </MenuList>
            </Menu>
          );
        },
        meta: {
          isRightAligned: true,
        },
      }),
    ];
  }, [hasWritePermission, isAdminContext]);

  const tableData = useMemo(() => {
    return data?.data ?? [];
  }, [data]);

  const table = useReactTable({
    data: tableData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: () => true,
    manualPagination: true,
    onPaginationChange,
    pageCount,
    state: {
      pagination,
    },
  });

  return (
    <>
      <FixedHeightTableLayout.TableWrapper>
        <FixedHeightTableLayout.TableWrapperHeader
          search={
            <ChakraSearch
              onChange={(search) => {
                debouncedOnSearch(search);
                setSearchTerm(search);
              }}
              placeholder="Search by name"
              value={searchTerm}
            />
          }
          filters={<Filter {...statusFilter} />}
        />
        <FixedHeightTableLayout.TableContainer table={table}>
          <TanstackTable
            table={table}
            stickyHeaders
            isLoading={isLoading}
            renderEmptyData={() => {
              if (isError) {
                return <ErrorFetchingData onRetryClick={() => refetch()} />;
              }

              if (
                params.search ||
                filterParams.some((param) => params[param])
              ) {
                return (
                  <NoFilteredResultsFound
                    onClearFiltersClick={() => {
                      setSearchTerm("");
                      clear();
                    }}
                  />
                );
              }

              return <NoResultsFound />;
            }}
          />
        </FixedHeightTableLayout.TableContainer>
        <FixedHeightTableLayout.TableWrapperFooter>
          <TanstackTablePagination
            table={table}
            isLoading={isLoading}
            getLocationIndicatorText={getDetailedLocationIndicatorText({
              totalCount: data?.meta.total_entries,
              singularName: "key",
              pluralName: "keys",
            })}
            ml="auto"
          />
        </FixedHeightTableLayout.TableWrapperFooter>
      </FixedHeightTableLayout.TableWrapper>

      {modalControl?.action === "rotate" && (
        <RotateApiKeyModal
          isOpen
          onClose={() => setModalControl(null)}
          apiKey={modalControl.apiKey}
          organizationId={organizationId!}
        />
      )}

      {modalControl?.action === "edit" && (
        <EditApiKeyModal
          apiKey={modalControl.apiKey}
          onClose={() => setModalControl(null)}
          isAdmin={isAdminContext}
        />
      )}

      {modalControl?.action === "expire" && (
        <EditApiKeyExpirationModal
          apiKey={modalControl.apiKey}
          onClose={() => setModalControl(null)}
        />
      )}

      <Suspense fallback={<PageLoader />}>
        <Outlet />
      </Suspense>
    </>
  );
}

function RotateApiKeyModal({
  isOpen,
  onClose,
  apiKey,
  organizationId,
}: {
  isOpen: boolean;
  onClose: () => void;
  apiKey: ApiKey;
  organizationId: string;
}) {
  const api = useApi();
  const [rotateSecretKey, { isLoading }] = api.useApiKeysRotateSecretMutation();

  const {
    onCopy: onCopySecret,
    value: valueSecret,
    setValue: setValueSecret,
    hasCopied: hasCopiedSecret,
  } = useClipboard("");

  const toast = useToast();

  const [step, setStep] = useState<"confirm" | "complete">("confirm");

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Roll API key</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {step === "confirm" && (
            <Stack spacing={4}>
              <Text>
                Block this API key and generate a new one. Any webhook endpoints
                created with this key will stay active, even after the key is
                rolled.
              </Text>
              <Box>
                <FormLabel>Name</FormLabel>
                <Text>{apiKey.name}</Text>
              </Box>
              <Box>
                <FormLabel>Last used</FormLabel>
                <Text>
                  {apiKey.last_used_at
                    ? new Date(apiKey.last_used_at).toLocaleString()
                    : "-"}
                </Text>
              </Box>
            </Stack>
          )}
          {step === "complete" && (
            <Stack spacing={4}>
              <Text>
                Make sure to copy your secret key as you won&apos;t see it
                again!
              </Text>

              <Box>
                <Stack spacing={2}>
                  <HStack justifyContent="space-between">
                    <Text fontWeight="bold">Secret Key</Text>
                    <IconButton
                      aria-label="copy"
                      icon={
                        hasCopiedSecret ? (
                          <FaIcon icon={faCheck} color="green.500" />
                        ) : (
                          <FaIcon icon={faCopy} />
                        )
                      }
                      onClick={onCopySecret}
                    />
                  </HStack>
                  <Text
                    overflowWrap="anywhere"
                    as="kbd"
                    fontSize="sm"
                    color="red"
                  >
                    {valueSecret}
                  </Text>
                </Stack>
              </Box>
            </Stack>
          )}
        </ModalBody>
        <ModalFooter gap={2}>
          {step === "confirm" && (
            <>
              <Button variant="ghost" colorScheme="gray" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                isLoading={isLoading}
                onClick={async () =>
                  await rotateSecretKey({
                    organizationId,
                    apiKeyId: apiKey.id,
                    body: { data: {} },
                  })
                    .unwrap()
                    .then((token) => {
                      setValueSecret(token.data.secret_key);
                      setStep("complete");
                    })
                    .catch((err) => {
                      console.error(err);
                      toast({
                        status: "error",
                        description: "Error rotating API key",
                      });
                    })
                }
              >
                Rotate
              </Button>
            </>
          )}
          {step === "complete" && (
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function EditApiKeyModal({
  apiKey,
  onClose,
  isAdmin = false,
}: {
  onClose: () => void;
  apiKey: ApiKey;
  isAdmin?: boolean;
}) {
  const api = useApi();
  const { data: orgData } = useOrganization();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      name: apiKey.name,
      description: apiKey.description ?? "",
      ...(isAdmin && { system: apiKey.system }),
    },
  });

  const [editApiKey] = api.useApiKeysUpdateMutation();

  const toast = useToast();

  return (
    <Modal
      isOpen
      onClose={onClose}
      closeOnEsc={!isSubmitting}
      closeOnOverlayClick={!isSubmitting}
    >
      <ModalOverlay />
      <ModalContent>
        <form
          onSubmit={handleSubmit((values) =>
            editApiKey({
              organizationId: orgData!.data.id,
              id: apiKey.id,
              body: {
                data: {
                  name: values.name,
                  description: values.description,
                  ...(isAdmin && {
                    system: values.system,
                  }),
                },
              },
            })
              .unwrap()
              .then(() => {
                toast({
                  status: "success",
                  title: "API key updated",
                });
                onClose();
              })
              .catch(() => {
                toast({
                  status: "error",
                  title: "Error updating API key",
                });
              }),
          )}
        >
          <ModalHeader>Edit API key</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <RHFInput name="name" label="Name" control={control} />
              <RHFInput
                name="description"
                label="Description"
                control={control}
              />
              {isAdmin && (
                <RHFCheckbox
                  label="Is system"
                  name="system"
                  control={control}
                  direction="row"
                />
              )}
            </Stack>
          </ModalBody>
          <ModalFooter gap={4}>
            <Button
              variant="ghost"
              colorScheme="gray"
              onClick={onClose}
              isDisabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button type="submit" isLoading={isSubmitting}>
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

function EditApiKeyExpirationModal({
  apiKey,
  onClose,
}: {
  onClose: () => void;
  apiKey: ApiKey;
}) {
  const api = useApi();
  const { data: orgData } = useOrganization();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: apiKey,
  });

  const [editApiKey] = api.useApiKeysUpdateMutation();

  const toast = useToast();

  return (
    <Modal
      isOpen
      onClose={onClose}
      closeOnEsc={!isSubmitting}
      closeOnOverlayClick={!isSubmitting}
    >
      <ModalOverlay />
      <ModalContent>
        <form
          onSubmit={handleSubmit((values) =>
            editApiKey({
              organizationId: orgData!.data.id,
              id: apiKey.id,
              body: {
                data: {
                  expires_at: values.expires_at,
                },
              },
            })
              .unwrap()
              .then(() => {
                toast({
                  status: "success",
                  title: "API key updated",
                });
                onClose();
              })
              .catch(() => {
                toast({
                  status: "error",
                  title: "Error updating API key",
                });
              }),
          )}
        >
          <ModalHeader>Edit API key</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Controller
                control={control}
                name="expires_at"
                render={({ field, fieldState }) => {
                  return (
                    <FormControl isInvalid={fieldState.invalid}>
                      <DatePicker
                        label="Expires at"
                        minValue={today(getLocalTimeZone())}
                        value={
                          field.value
                            ? parseDate(field.value.split("T")[0])
                            : null
                        }
                        onChange={(value) => {
                          if (!value) {
                            field.onChange(null);
                          } else {
                            field.onChange(
                              toZoned(
                                value,
                                getLocalTimeZone(),
                              ).toAbsoluteString(),
                            );
                          }
                        }}
                      />
                      <FormErrorMessage>
                        {fieldState.error?.message}
                      </FormErrorMessage>
                    </FormControl>
                  );
                }}
              />
            </Stack>
          </ModalBody>
          <ModalFooter gap={4}>
            <Button
              variant="ghost"
              colorScheme="gray"
              onClick={onClose}
              isDisabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button type="submit" isLoading={isSubmitting}>
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
