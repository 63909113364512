import { baseApi as api } from "./base.api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    authCheck: build.mutation<AuthCheckApiResponse, AuthCheckApiArg>({
      query: () => ({ url: `/v1/auth/check`, method: "POST" }),
    }),
    authPermissions: build.query<
      AuthPermissionsApiResponse,
      AuthPermissionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/auth/organizations/${queryArg.organization}/apps/${queryArg.app}/permissions`,
      }),
    }),
    authToken: build.mutation<AuthTokenApiResponse, AuthTokenApiArg>({
      query: (queryArg) => ({
        url: `/v1/auth/token`,
        method: "POST",
        body: queryArg,
      }),
    }),
    authValidate: build.query<AuthValidateApiResponse, AuthValidateApiArg>({
      query: () => ({ url: `/v1/auth/validate` }),
    }),
    me: build.query<MeApiResponse, MeApiArg>({
      query: (queryArg) => ({
        url: `/v1/me`,
        params: { sort: queryArg.sort, filter: queryArg.filter },
      }),
    }),
    createMe: build.mutation<CreateMeApiResponse, CreateMeApiArg>({
      query: (queryArg) => ({ url: `/v1/me`, method: "POST", body: queryArg }),
    }),
    updateMe: build.mutation<UpdateMeApiResponse, UpdateMeApiArg>({
      query: (queryArg) => ({ url: `/v1/me`, method: "PUT", body: queryArg }),
    }),
    myOrganization: build.query<
      MyOrganizationApiResponse,
      MyOrganizationApiArg
    >({
      query: (queryArg) => ({ url: `/v1/me/organizations/${queryArg}` }),
    }),
    organizationInvitationsIndex: build.query<
      OrganizationInvitationsIndexApiResponse,
      OrganizationInvitationsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organization_invitations`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    organizationInvitationsAcknowledge: build.mutation<
      OrganizationInvitationsAcknowledgeApiResponse,
      OrganizationInvitationsAcknowledgeApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organization_invitations/${queryArg.organizationId}/${queryArg.action}`,
        method: "POST",
      }),
    }),
    organizationsIndex: build.query<
      OrganizationsIndexApiResponse,
      OrganizationsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
          search: queryArg.search,
        },
      }),
    }),
    organizationsShow: build.query<
      OrganizationsShowApiResponse,
      OrganizationsShowApiArg
    >({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg}` }),
    }),
    activitiesIndex: build.query<
      ActivitiesIndexApiResponse,
      ActivitiesIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/activities`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
          search: queryArg.search,
        },
      }),
    }),
    apiKeysIndex: build.query<ApiKeysIndexApiResponse, ApiKeysIndexApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/api_keys`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
          search: queryArg.search,
        },
      }),
    }),
    apiKeysCreate: build.mutation<
      ApiKeysCreateApiResponse,
      ApiKeysCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/api_keys`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    apiKeysRotateSecret: build.mutation<
      ApiKeysRotateSecretApiResponse,
      ApiKeysRotateSecretApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/api_keys/${queryArg.apiKeyId}/secret_key/rotate`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    apiKeysShow: build.query<ApiKeysShowApiResponse, ApiKeysShowApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/api_keys/${queryArg.id}`,
      }),
    }),
    apiKeysUpdate: build.mutation<
      ApiKeysUpdateApiResponse,
      ApiKeysUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/api_keys/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1OrganizationsByOrganizationIdApiKeysAndId: build.mutation<
      PutV1OrganizationsByOrganizationIdApiKeysAndIdApiResponse,
      PutV1OrganizationsByOrganizationIdApiKeysAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/api_keys/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    organizationAppsIndex: build.query<
      OrganizationAppsIndexApiResponse,
      OrganizationAppsIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/apps`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
          search: queryArg.search,
        },
      }),
    }),
    realmsIndex: build.query<RealmsIndexApiResponse, RealmsIndexApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/apps/${queryArg.appId}/realms`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
          search: queryArg.search,
        },
      }),
    }),
    realmsDelete: build.mutation<RealmsDeleteApiResponse, RealmsDeleteApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/apps/${queryArg.appId}/realms/${queryArg.resourcePath}`,
        method: "DELETE",
      }),
    }),
    realmsShow: build.query<RealmsShowApiResponse, RealmsShowApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/apps/${queryArg.appId}/realms/${queryArg.resourcePath}`,
      }),
    }),
    realmsUpdate: build.mutation<RealmsUpdateApiResponse, RealmsUpdateApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/apps/${queryArg.appId}/realms/${queryArg.resourcePath}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    organizationAppsShow: build.query<
      OrganizationAppsShowApiResponse,
      OrganizationAppsShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/apps/${queryArg.id}`,
      }),
    }),
    organizationsAvailablePermissions: build.query<
      OrganizationsAvailablePermissionsApiResponse,
      OrganizationsAvailablePermissionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg}/available_permissions`,
      }),
    }),
    insightsCreate: build.mutation<
      InsightsCreateApiResponse,
      InsightsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/insights`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    organizationsMembersIndex: build.query<
      OrganizationsMembersIndexApiResponse,
      OrganizationsMembersIndexApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/members`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
          search: queryArg.search,
        },
      }),
    }),
    organizationsMembersCreate: build.mutation<
      OrganizationsMembersCreateApiResponse,
      OrganizationsMembersCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/members`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    organizationsMembersDelete: build.mutation<
      OrganizationsMembersDeleteApiResponse,
      OrganizationsMembersDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/members/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    organizationsMembersShow: build.query<
      OrganizationsMembersShowApiResponse,
      OrganizationsMembersShowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/members/${queryArg.id}`,
      }),
    }),
    organizationsMembersUpdate: build.mutation<
      OrganizationsMembersUpdateApiResponse,
      OrganizationsMembersUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/members/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    putV1OrganizationsByOrganizationIdMembersAndId: build.mutation<
      PutV1OrganizationsByOrganizationIdMembersAndIdApiResponse,
      PutV1OrganizationsByOrganizationIdMembersAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/members/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    organizationsMembersActivate: build.mutation<
      OrganizationsMembersActivateApiResponse,
      OrganizationsMembersActivateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/members/${queryArg.userId}/activate`,
        method: "POST",
      }),
    }),
    organizationsMembersDeactivate: build.mutation<
      OrganizationsMembersDeactivateApiResponse,
      OrganizationsMembersDeactivateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/members/${queryArg.userId}/deactivate`,
        method: "POST",
      }),
    }),
    organizationsMembersResendInvitation: build.mutation<
      OrganizationsMembersResendInvitationApiResponse,
      OrganizationsMembersResendInvitationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/members/${queryArg.userId}/resend_invitation`,
        method: "POST",
      }),
    }),
    realmsListAll: build.query<RealmsListAllApiResponse, RealmsListAllApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.organizationId}/realms`,
        params: {
          page: queryArg.page,
          page_size: queryArg.pageSize,
          sort: queryArg.sort,
          filter: queryArg.filter,
          search: queryArg.search,
        },
      }),
    }),
    sessionCreate: build.mutation<
      SessionCreateApiResponse,
      SessionCreateApiArg
    >({
      query: () => ({ url: `/v1/socket/session`, method: "POST" }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type AuthCheckApiResponse = /** status 200 ok */ {
  data: CheckResult;
};
export type AuthCheckApiArg = void;
export type AuthPermissionsApiResponse = /** status 200 ok */ {
  data: AvailablePermissions;
};
export type AuthPermissionsApiArg = {
  /** An App ID or alias that the token's permissions will be scoped to */
  app: string;
  /** An Organization ID or alias that the token's permissions will be scoped to */
  organization: string;
};
export type AuthTokenApiResponse = /** status 200 ok */ {
  data: TokenResult;
};
export type AuthTokenApiArg = /** payload */ {
  data: TokenRequest;
};
export type AuthValidateApiResponse = unknown;
export type AuthValidateApiArg = void;
export type MeApiResponse = /** status 200 ok */ {
  data: Me;
};
export type MeApiArg = {
  /** Sort by a given property. Accepts fields: . Sorts should be passed in the format of `&sort=property:asc,property2:desc` */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    app_aliases?: string[];
  };
};
export type CreateMeApiResponse = /** status 200 ok */ {
  data: Me;
};
export type CreateMeApiArg = /** payload */ {
  data: CreateMe;
};
export type UpdateMeApiResponse = /** status 200 ok */ {
  data: Me;
};
export type UpdateMeApiArg = /** payload */ {
  data: UpdateMe;
};
export type MyOrganizationApiResponse = /** status 200 ok */ {
  data: MyOrganization;
};
export type MyOrganizationApiArg = /** The organization id or alias */ string;
export type OrganizationInvitationsIndexApiResponse =
  /** status 200 A collection of organziations */ {
    data: Organization[];
    meta: PaginationMetadata;
  };
export type OrganizationInvitationsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by a given property. Accepts fields: alias, name, inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc` */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    alias?: string;
    name?: string;
  };
};
export type OrganizationInvitationsAcknowledgeApiResponse =
  /** status 204 No content */ undefined;
export type OrganizationInvitationsAcknowledgeApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The action to perform on the invitation, either accept or reject. */
  action: "accept" | "reject";
};
export type OrganizationsIndexApiResponse =
  /** status 200 A collection of organizations */ {
    data: Organization[];
    meta: PaginationMetadata;
  };
export type OrganizationsIndexApiArg = {
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by a given property. Accepts fields: alias, name, inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc` */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    alias?: string;
    name?: string;
  };
  /** Search by the following fields: alias, name */
  search?: string;
};
export type OrganizationsShowApiResponse = /** status 200 The organization */ {
  data: Organization;
};
export type OrganizationsShowApiArg =
  /** The organization id or alias */ string;
export type ActivitiesIndexApiResponse =
  /** status 200 A collection of activities */ {
    data: Activity[];
    meta: PaginationMetadata;
  };
export type ActivitiesIndexApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by a given property. Accepts fields: occurred_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc` */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    activity_data?: string;
    activity_name?: string;
    actor_data?: string;
    actor_id?: string;
    actor_type?: string;
    occurred_after?: string;
    occurred_before?: string;
    segmentation_key?: string;
    source?: string;
    source_data?: string;
  };
  /** Search by the following fields:  */
  search?: string;
};
export type ApiKeysIndexApiResponse =
  /** status 200 A collection of API keys */ {
    data: ApiKey[];
    meta: PaginationMetadata;
  };
export type ApiKeysIndexApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by a given property. Accepts fields: name, inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc` */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    expired?: string;
    name?: string;
  };
  /** Search by the following fields: name, secret_key_last_4 */
  search?: string;
};
export type ApiKeysCreateApiResponse = /** status 201 An API Key */ {
  data: ApiKeyWithSecret;
};
export type ApiKeysCreateApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** payload */
  body: {
    data: Create;
  };
};
export type ApiKeysRotateSecretApiResponse =
  /** status 200 The updated API Key */ {
    data: ApiKeyWithSecret;
  };
export type ApiKeysRotateSecretApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The API key id */
  apiKeyId: string;
  /** payload */
  body: {
    data: RotateSecret;
  };
};
export type ApiKeysShowApiResponse = /** status 200 ok */ {
  data: ApiKey;
};
export type ApiKeysShowApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The API key id */
  id: string;
};
export type ApiKeysUpdateApiResponse = /** status 200 ok */ {
  data: ApiKey;
};
export type ApiKeysUpdateApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The API key id */
  id: string;
  /** payload */
  body: {
    data: Update;
  };
};
export type PutV1OrganizationsByOrganizationIdApiKeysAndIdApiResponse =
  /** status 200 ok */ {
    data: ApiKey;
  };
export type PutV1OrganizationsByOrganizationIdApiKeysAndIdApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The API key id */
  id: string;
  /** payload */
  body: {
    data: Update;
  };
};
export type OrganizationAppsIndexApiResponse =
  /** status 200 A collection of apps */ {
    data: App[];
    meta: PaginationMetadata;
  };
export type OrganizationAppsIndexApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by a given property. Accepts fields: alias, name, inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc` */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    alias?: string;
    name?: string;
  };
  /** Search by the following fields: alias, description, name */
  search?: string;
};
export type RealmsIndexApiResponse = /** status 200 A collection of Realms */ {
  data: Realm[];
  meta: PaginationMetadata;
};
export type RealmsIndexApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The app id or alias */
  appId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by a given property. Accepts fields: name, inserted_at, updated_at, app_alias, resource_path. Sorts should be passed in the format of `&sort=property:asc,property2:desc` */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
  /** Search by the following fields: name, description, resource_path */
  search?: string;
};
export type RealmsDeleteApiResponse = unknown;
export type RealmsDeleteApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The app id or alias */
  appId: string;
  /** The resource_path to the realm, including its resource_id */
  resourcePath: string;
};
export type RealmsShowApiResponse = /** status 200 ok */ {
  data: Realm;
};
export type RealmsShowApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The app id or alias */
  appId: string;
  /** The resource_path to the realm, including its resource_id */
  resourcePath: string;
};
export type RealmsUpdateApiResponse = /** status 200 ok */ {
  data: Realm;
};
export type RealmsUpdateApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The app id or alias */
  appId: string;
  /** The resource_path to the realm, including its resource_id */
  resourcePath: string;
  /** payload */
  body: {
    data: CreateRealm;
  };
};
export type OrganizationAppsShowApiResponse = /** status 200 The app */ {
  data: App;
};
export type OrganizationAppsShowApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The app id or alias */
  id: string;
};
export type OrganizationsAvailablePermissionsApiResponse =
  /** status 200 ok */ {
    data: AvailablePermissions;
  };
export type OrganizationsAvailablePermissionsApiArg =
  /** The organization id or alias */ string;
export type InsightsCreateApiResponse = /** status 200 ok */ {
  data: Domo;
};
export type InsightsCreateApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** payload */
  body: {
    data: CreateInsightsRequest;
  };
};
export type OrganizationsMembersIndexApiResponse =
  /** status 200 A collection of members */ {
    data: Member[];
    meta: PaginationMetadata;
  };
export type OrganizationsMembersIndexApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by a given property. Accepts fields: inserted_at, updated_at. Sorts should be passed in the format of `&sort=property:asc,property2:desc` */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    email?: string;
    external_id?: string;
    membership_status?: string;
    name?: string;
    resource_path?: string;
  };
  /** Search by the following fields: email, external_id, membership_status, name */
  search?: string;
};
export type OrganizationsMembersCreateApiResponse =
  /** status 201 A single member */ {
    data: Member;
  };
export type OrganizationsMembersCreateApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** payload */
  body: {
    data: CreateMember;
  };
};
export type OrganizationsMembersDeleteApiResponse = unknown;
export type OrganizationsMembersDeleteApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The user id */
  id: string;
};
export type OrganizationsMembersShowApiResponse =
  /** status 200 A single member */ {
    data: Member;
  };
export type OrganizationsMembersShowApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The id of the user */
  id: string;
};
export type OrganizationsMembersUpdateApiResponse =
  /** status 200 A single member */ {
    data: Member;
  };
export type OrganizationsMembersUpdateApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The id of the user to update */
  id: string;
  /** payload */
  body: {
    data: UpdateMember;
  };
};
export type PutV1OrganizationsByOrganizationIdMembersAndIdApiResponse =
  /** status 200 A single member */ {
    data: Member;
  };
export type PutV1OrganizationsByOrganizationIdMembersAndIdApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The id of the user to update */
  id: string;
  /** payload */
  body: {
    data: UpdateMember;
  };
};
export type OrganizationsMembersActivateApiResponse =
  /** status 200 A single member */ {
    data: Member;
  };
export type OrganizationsMembersActivateApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The id of the user to activate */
  userId: string;
};
export type OrganizationsMembersDeactivateApiResponse =
  /** status 200 A single member */ {
    data: Member;
  };
export type OrganizationsMembersDeactivateApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The id of the user to deactivate */
  userId: string;
};
export type OrganizationsMembersResendInvitationApiResponse =
  /** status 204 No content */ undefined;
export type OrganizationsMembersResendInvitationApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** The id of the user to resend their invitation */
  userId: string;
};
export type RealmsListAllApiResponse =
  /** status 200 A collection of Realms */ {
    data: Realm[];
    meta: PaginationMetadata;
  };
export type RealmsListAllApiArg = {
  /** The organization id or alias */
  organizationId: string;
  /** Page Number */
  page?: number;
  /** The number of items to return */
  pageSize?: number;
  /** Sort by a given property. Accepts fields: name, inserted_at, updated_at, app_alias, resource_path. Sorts should be passed in the format of `&sort=property:asc,property2:desc` */
  sort?: string;
  /** Filter by a given property. Filters should be passed in the format of `&filter[property]=value&filter[property2]=value2` */
  filter?: {
    name?: string;
  };
  /** Search by the following fields: name, description, resource_path */
  search?: string;
};
export type SessionCreateApiResponse = unknown;
export type SessionCreateApiArg = void;
export type CheckResult = {
  result: boolean;
};
export type ResourceMeta =
  | {
      description?: string | null;
      name: string;
    }
  | {
      unknown: true;
    };
export type PermissionsList = string[];
export type ResourcePermissions = {
  [key: string]: {
    __meta?: ResourceMeta;
  } & (
    | PermissionsList
    | {
        [key: string]: PermissionsList | ResourcePermissions;
      }
  );
};
export type Permissions = {
  [key: string]: ResourcePermissions;
};
export type AvailablePermissions = {
  permissions?: Permissions;
};
export type TokenType = "orion" | "cloud";
export type TokenResult = {
  app: string;
  organization: string;
  organization_alias: string;
  resource_path: string | null;
  token: string;
  type: TokenType;
};
export type ErrorResponses = {
  errors: {
    message: string;
    name: string;
  }[];
};
export type TokenRequest = {
  app: string;
  include?: ("roles" | "permissions")[];
  organization?: string | null;
  resource_path?: string | null;
  type: TokenType;
};
export type User = {
  email: string;
  external_id: string;
  first_name?: string;
  id: string;
  inserted_at?: string;
  last_name?: string;
  metadata?: {
    [key: string]: any;
  } | null;
  phone?: string | null;
  status?: "setup" | "active" | "inactive";
  updated_at?: string;
};
export type Me =
  | {
      organizations: {
        alias: string;
        apps?: {
          alias: string;
          base_url: string | null;
          description: string | null;
          id: string;
          name: string;
          realms: {
            description?: string | null;
            full_resource_name: string;
            name: string;
            resource_id: string;
            resource_path: string;
          }[];
        }[];
        description: string | null;
        id: string;
        name: string;
      }[];
      type: "user";
      user: User;
    }
  | {
      auth0: {
        email?: string;
        external_id?: string;
        first_name?: string;
        last_name?: string;
        phone?: string | null;
        raw?: object;
      };
      organizations: {
        alias: string;
        apps?: {
          alias: string;
          base_url: string | null;
          description: string | null;
          id: string;
          name: string;
          realms: {
            description?: string | null;
            full_resource_name: string;
            name: string;
            resource_id: string;
            resource_path: string;
          }[];
        }[];
        description: string | null;
        id: string;
        name: string;
      }[];
      type: "auth0";
    };
export type JsonErrorResponse = {
  errors: {
    detail: string;
    source: {
      pointer: string;
    };
    title: string;
  }[];
};
export type CreateMe = {
  first_name: string;
  last_name: string;
  phone: string | null;
};
export type UpdateMe = {
  first_name?: string;
  last_name?: string;
  phone?: string | null;
};
export type MyOrganization = {
  alias: string;
  apps: {
    alias: string;
    base_url: string | null;
    description: string | null;
    id: string;
    name: string;
    realms: {
      description?: string | null;
      full_resource_name: string;
      name: string;
      resource_id: string;
      resource_path: string;
    }[];
  }[];
  description: string | null;
  id: string;
  name: string;
};
export type Organization = {
  alias: string;
  description?: string | null;
  id: string;
  inserted_at?: string;
  name: string;
  updated_at?: string;
};
export type PaginationMetadata = {
  page_number: number;
  page_size: number;
  total_entries: number;
  total_pages: number;
};
export type ActivityData = {
  changes?: {
    from?: any | null;
    key?: string;
    to?: any | null;
  }[];
  metadata: {
    [key: string]: any;
  } | null;
  organization: string | null;
  resource: string[];
  version: number;
};
export type ApiKeyActor = {
  name: string;
  secret_key_header: string;
};
export type UserActor = {
  email: string;
  first_name?: string;
  last_name?: string;
};
export type Activity = {
  activity_data: ActivityData;
  activity_name: string;
  actor_data: {
    permissions?: {
      [key: string]: any;
    };
  } & (ApiKeyActor | UserActor);
  actor_id: string;
  actor_type: "user" | "api_key" | "system" | "integration";
  occurred_at: string;
  request_id: string;
  segmentation_key: string;
  source: string;
  source_data: {
    node?: string;
    service: string;
    version: string;
  };
};
export type ApiKey = {
  created_by_user?: User | null;
  created_by_user_id?: string | null;
  description?: string | null;
  expires_at: string | null;
  id: string;
  inserted_at?: string;
  last_used_at?: string | null;
  name: string;
  permissions?: Permissions;
  secret_key_last_4: string;
  system?: boolean;
  updated_at?: string;
};
export type ApiKeyWithSecret = {
  created_by_user?: User | null;
  created_by_user_id?: string | null;
  description?: string | null;
  expires_at: string | null;
  id: string;
  inserted_at?: string;
  last_used_at?: string | null;
  name: string;
  permissions?: Permissions;
  secret_key: string;
  secret_key_last_4: string;
  system?: boolean;
  updated_at?: string;
};
export type Create = {
  description?: string | null;
  name: string;
  permissions?: Permissions;
};
export type RotateSecret = {
  description?: string | null;
  expire_previous_secret_key_at?: string | null;
  name?: string;
  permissions?: Permissions;
};
export type Update = {
  description?: string | null;
  expires_at?: string | null;
  name?: string;
  permissions?: Permissions;
};
export type ResourceDefinition = {
  description?: string | null;
  name?: string;
  orion_context_type?: string | null;
  orion_mapping?: string | null;
  permissions?: string[];
  realm?: boolean | null;
  resources?: {
    [key: string]: ResourceDefinition;
  } | null;
  roles?: {
    [key: string]: {
      description?: string | null;
      name?: string | null;
      permissions: PermissionsList | ResourcePermissions;
    };
  } | null;
  singleton?: boolean | null;
};
export type App = {
  alias: string;
  base_url?: string | null;
  description?: string | null;
  id: string;
  inserted_at?: string;
  name: string;
  required?: boolean;
  resources?: {
    [key: string]: {
      description?: string | null;
      name?: string;
      orion_context_type?: string | null;
      orion_mapping?: string | null;
      permissions?: string[];
      realm?: boolean | null;
      resources?: {
        [key: string]: ResourceDefinition;
      } | null;
      roles?: {
        [key: string]: {
          description?: string | null;
          name?: string | null;
          permissions: PermissionsList | ResourcePermissions;
        };
      } | null;
      singleton?: boolean | null;
    };
  };
  roles?: {
    [key: string]: {
      description?: string | null;
      name?: string | null;
      permissions: PermissionsList | ResourcePermissions;
    };
  } | null;
  status: string;
  updated_at?: string;
  version?: number;
};
export type Realm = {
  app_id: string;
  description?: string | null;
  full_resource_name?: string;
  id: string;
  inserted_at?: string;
  name: string;
  resource_id: string;
  resource_path: string;
  updated_at?: string;
};
export type CreateRealm = {
  description?: string | null;
  name: string;
};
export type Domo = {
  url: string;
};
export type CreateInsightsRequest = {
  resource_path?: string;
  user?: {
    email: string;
    name: string;
    sub: string;
  };
};
export type Member = {
  email: string;
  external_id: string;
  first_name?: string | null;
  id: string;
  inserted_at?: string;
  last_name?: string | null;
  membership_status?: "invited" | "active" | "inactive";
  permissions?: Permissions;
  phone?: string | null;
  role?: string | null;
  role_count?: number | null;
  roles?: {
    [key: string]: {
      name?: string | null;
      role?: string;
    } | null;
  } | null;
  status?: "invited" | "active" | "inactive";
  updated_at?: string;
  user_status?: "setup" | "active" | "inactive";
};
export type UpdateRole = {
  [key: string]: string | null;
} | null;
export type CreateMember = {
  email: string;
  roles?: UpdateRole;
  status?: "invited" | "active" | "inactive";
};
export type UpdateMember = {
  permissions?: Permissions;
  roles?: UpdateRole;
  status?: "invited" | "active" | "inactive";
};
export type ValidationErrors = {
  errors?: {
    [key: string]: string[];
  };
};
