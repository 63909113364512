import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { get } from "lodash-es";
import { HStack, IconButton } from "@chakra-ui/react";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

import { capitalize, FaIcon, RHFSelect } from "@stordco/fe-components";

import { useApi, useOrganization } from "../hooks";

export function MemberRoleSelection({
  onAdd,
}: {
  onAdd: (values: {
    resourcePath: string;
    roleDisplayName: string;
    role: string;
  }) => void;
}) {
  const api = useApi();
  const { organizationId } = useOrganization();
  const { control, watch, handleSubmit, reset, formState, resetField } =
    useForm({
      mode: "all",
      defaultValues: {
        selectedAppAlias: "",
        selectedRealmId: "",
        role: "",
        roleDisplayName: "",
      },
    });

  const selectedRealmId = watch("selectedRealmId");
  const selectedAppAlias = watch("selectedAppAlias");

  useEffect(() => {
    if (selectedAppAlias) {
      resetField("selectedRealmId");
    }
  }, [selectedAppAlias, resetField]);

  const {
    data: organizationAppsData,
    isLoading: organizationAppsDataIsLoading,
  } = api.useOrganizationAppsIndexQuery({
    organizationId,
  });

  const {
    data: organizationRealmsData,
    isLoading: organizationRealmsDataIsLoading,
  } = api.useRealmsIndexQuery(
    {
      organizationId,
      appId: selectedAppAlias,
    },
    {
      skip: !selectedAppAlias,
    },
  );

  const appOptions = useMemo(() => {
    if (!organizationAppsData) return [];

    return organizationAppsData?.data.map((app) => ({
      label: app.name,
      value: app.alias,
    }));
  }, [organizationAppsData]);

  const realmOptions = useMemo(() => {
    if (!organizationRealmsData) return [];

    return (
      organizationRealmsData.data.map((realm) => ({
        label: realm.name,
        value: realm.id,
      })) ?? []
    );
  }, [organizationRealmsData]);

  const roleOptions = useMemo(() => {
    if (selectedRealmId && organizationRealmsData?.data.length) {
      const realm = organizationRealmsData.data.find(
        (realm) => realm.id === selectedRealmId,
      );

      const realmApp = organizationAppsData?.data.find(
        (app) => app.id === realm?.app_id,
      );

      return Object.keys(
        get(
          // TODO: fix this type
          (realmApp?.resources as any) ?? {},
          [realm?.full_resource_name ?? "networks", "roles"],
          {},
        ),
      )
        .map((role) => ({
          label: capitalize(role),
          value: role,
        }))
        .filter((role) => !role.value.startsWith("_"));
    } else {
      const app = organizationAppsData?.data.find(
        ({ alias }) => alias === selectedAppAlias,
      );

      const roles = app?.roles ?? {};

      return Object.entries(roles ?? {})
        .map(([role, roleData]) => ({
          label: roleData?.name ?? capitalize(role),
          value: role,
        }))
        .filter((role) => !role.value.startsWith("_"));
    }
  }, [
    organizationAppsData,
    selectedRealmId,
    organizationRealmsData,
    selectedAppAlias,
  ]);

  return (
    <HStack alignItems="flex-start" spacing={4}>
      <RHFSelect
        control={control}
        name="selectedAppAlias"
        label="Application"
        options={appOptions}
        isLoading={organizationAppsDataIsLoading}
        rules={{ required: true }}
      />

      <RHFSelect
        control={control}
        name="selectedRealmId"
        label="Realm"
        options={realmOptions}
        isDisabled={!realmOptions.length}
        isLoading={organizationRealmsDataIsLoading}
        rules={{
          validate: (value) => {
            if (realmOptions.length > 0 && !value) {
              return "Realm is required";
            }
          },
        }}
      />

      <RHFSelect
        control={control}
        name="role"
        label="Role"
        isDisabled={!roleOptions.length}
        options={roleOptions}
        rules={{ required: true }}
      />

      <IconButton
        flex={1}
        colorScheme="gray"
        variant="outline"
        aria-label="Add role"
        icon={<FaIcon icon={faPlus} />}
        isDisabled={!formState.isValid}
        mt={6}
        onClick={handleSubmit((values) => {
          if (values.selectedAppAlias && !values.selectedRealmId) {
            onAdd({
              resourcePath: values.selectedAppAlias,
              role: values.role,
              roleDisplayName: values.roleDisplayName,
            });
          } else {
            const realm = organizationRealmsData?.data.find(
              (realm) => realm.id === values.selectedRealmId,
            );
            const app = organizationAppsData?.data.find(
              (app) => app.id === realm?.app_id,
            );

            onAdd({
              resourcePath: `${app?.alias}${realm?.resource_path}`,
              role: values.role,
              roleDisplayName: values.roleDisplayName,
            });
          }
          reset();
        })}
      />
    </HStack>
  );
}
