import { type ReactNode, useEffect, useRef, useState } from "react";
import { Flex, Box, type BoxProps } from "@chakra-ui/react";
import { type Table } from "@tanstack/react-table";

import { Card } from "../Card";

export const FixedHeightTableLayout = Object.assign(
  function FixedHeightTableLayout({ children }: { children: ReactNode }) {
    return (
      <Flex direction="column" h="full">
        {children}
      </Flex>
    );
  },
  {
    TableWrapper({ children }: { children: ReactNode }) {
      return (
        <Card direction="column" flex="1" minHeight={400}>
          {children}
        </Card>
      );
    },
    TableWrapperHeader({
      search,
      filters,
      actions,
      children,
      ...props
    }: BoxProps & {
      search?: ReactNode;
      filters?: ReactNode;
      actions?: ReactNode;
    }) {
      return (
        <Box
          borderBottomWidth={1}
          borderColor="gray.200"
          px={6}
          py={4}
          _dark={{
            borderColor: "gray.800",
          }}
          {...props}
        >
          <Flex gap={2}>
            <Flex alignItems="center" gap={2} flex={1}>
              {search ? (
                <Box maxW="sm" flexGrow={1}>
                  {search}
                </Box>
              ) : null}

              {filters}
            </Flex>

            <Flex gap={1}>{actions}</Flex>
          </Flex>

          {children}
        </Box>
      );
    },
    TableContainer({
      children,
      table,
      ...props
    }: {
      children: ReactNode;
      table: Table<any>;
    } & Omit<BoxProps, "children">) {
      const pageIndex = table.getState().pagination.pageIndex;
      const [previousPageIndex, setPreviousPageIndex] = useState(pageIndex);

      const ref = useRef<HTMLDivElement>();

      useEffect(() => {
        if (pageIndex !== previousPageIndex) {
          setPreviousPageIndex(pageIndex);
          ref.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
      }, [pageIndex, previousPageIndex]);

      return (
        <Box
          ref={ref}
          flex={1}
          overflow="auto"
          __css={{
            _dark: {
              th: {
                backgroundColor: "#2a2a2a",
              },
            },
            th: {
              backgroundColor: "white",
            },
          }}
          {...props}
        >
          {children}
        </Box>
      );
    },
    TableWrapperFooter({ children }: { children: ReactNode }) {
      return (
        <Flex
          borderBottomRadius="sm"
          borderTopWidth={1}
          borderColor="gray.200"
          alignItems="center"
          gap={4}
          px={6}
          py={4}
          _dark={{
            borderColor: "gray.800",
          }}
        >
          {children}
        </Flex>
      );
    },
  },
);
