import { Box, Collapse, Flex, Stack } from "@chakra-ui/react";
import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import {
  useCallback,
  useEffect,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useLocation } from "react-router-dom";

import { FaIcon, useIsDesktop } from "@stordco/fe-components";
import { getIsModalOpen } from "@stordco/fe-components/src/utils/useIsModalOpen";

import { NavButton, ParentNavButton } from "./NavButton";
import { useNavigation, type NavigationState } from "./provider";
import {
  isNavigationParent,
  type NavigationItem,
  type NavigationParent,
} from "./types";

const GroupNavButton = ({
  action,
  onToggle,
  openAccordion,
  isDesktop,
  closeNavbarDrawerAction,
  navigationState,
  setOpenAccordion,
}: {
  action: NavigationParent;
  onToggle: (actionLabel: string) => void;
  openAccordion: string | null;
  isDesktop: boolean;
  closeNavbarDrawerAction?: () => void;
  navigationState: NavigationState;
  setOpenAccordion: Dispatch<SetStateAction<string | null>>;
}) => {
  const location = useLocation();

  const isParentButtonActive = action.children
    .map((child) => child.to)
    .some((to) =>
      location.pathname.startsWith(
        new URL(to ?? "", window.location.origin).pathname,
      ),
    );
  const isOpen = openAccordion === action.label;
  const isCollapsed = navigationState === "collapsed";

  useEffect(() => {
    if (
      (isDesktop &&
        (navigationState === "hoverExpanded" ||
          navigationState === "expanded") &&
        isParentButtonActive) ||
      (!isDesktop && isParentButtonActive)
    ) {
      setOpenAccordion(action.label);
    }
  }, [
    action.label,
    isDesktop,
    isParentButtonActive,
    navigationState,
    setOpenAccordion,
  ]);

  return (
    <Box
      backgroundColor={isOpen || isParentButtonActive ? "gray.50" : undefined}
      _dark={{
        backgroundColor:
          isOpen || isParentButtonActive ? "whiteAlpha.200" : undefined,
      }}
      borderRadius="base"
    >
      <ParentNavButton
        key={`${action}`}
        onClick={() => onToggle(action.label)}
        rightIcon={
          <FaIcon
            icon={faAngleDown}
            rotation={isOpen ? 180 : undefined}
            transition="transform 0.1s ease-in, opacity 0.2s ease-out"
            opacity={isCollapsed && isDesktop ? 0 : undefined}
          />
        }
        isDesktop={isDesktop}
        justifyContent="space-between"
        isActiveLink={isParentButtonActive}
        isOpen={isOpen}
        icon={action.icon}
        label={action.label}
        isCollapsed={isCollapsed}
      />
      <Collapse in={isOpen} animateOpacity>
        <Stack spacing={1} shouldWrapChildren paddingY={1}>
          {action.children.map((child) => (
            <NavButton
              key={`${child.label}`}
              marginX={isCollapsed ? 0 : 1}
              {...child}
              isDesktop={isDesktop}
              displayIcon={false}
              isCollapsed={isCollapsed}
              onClick={() => {
                if (!isDesktop) {
                  closeNavbarDrawerAction?.();
                }
              }}
            />
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

// This could be a group or individual nav button, but we're not sure yet.
const GenericNavButton = ({
  action,
  onParentActionClick,
  openAccordion,
  closeNavbarDrawerAction,
  isDesktop = true,
  setOpenAccordion,
  navigationState,
}: {
  action: NavigationItem;
  onParentActionClick: (parentActionLabel: string) => void;
  openAccordion: string | null;
  closeNavbarDrawerAction?: () => void;
  isDesktop?: boolean;
  setOpenAccordion: Dispatch<SetStateAction<string | null>>;
  navigationState: NavigationState;
}) => {
  const key = action.label;
  const isCollapsed = navigationState === "collapsed";

  if (isNavigationParent(action)) {
    if (action.children.length > 1) {
      return (
        <GroupNavButton
          key={key}
          action={action}
          onToggle={onParentActionClick}
          openAccordion={openAccordion}
          isDesktop={isDesktop}
          closeNavbarDrawerAction={closeNavbarDrawerAction}
          navigationState={navigationState}
          setOpenAccordion={setOpenAccordion}
        />
      );
    }

    // If there's only one child in the parent, don't display the parent and only display the one child
    if (action.children.length === 1) {
      return (
        <NavButton
          key={key}
          onClick={() => {
            setOpenAccordion(null);
            if (!isDesktop) {
              closeNavbarDrawerAction?.();
            }
          }}
          isDesktop={isDesktop}
          isCollapsed={isCollapsed}
          {...action.children[0]}
        />
      );
    }

    return null;
  }

  return (
    <NavButton
      key={key}
      onClick={() => {
        setOpenAccordion(null);
        if (!isDesktop) {
          closeNavbarDrawerAction?.();
        }
      }}
      isCollapsed={isCollapsed}
      isDesktop={isDesktop}
      {...action}
    />
  );
};

type SidebarProps = {
  // This is used to close the mobile navbar, when applicable, when a user clicks on a navigation item
  closeNavbarDrawerAction?: () => void;
};

export const Sidebar = ({ closeNavbarDrawerAction }: SidebarProps) => {
  const { navigationState, setNavigationState, links } = useNavigation();
  const isDesktop = useIsDesktop();
  const [openAccordionLabel, setOpenAccordionLabel] = useState<string | null>(
    null,
  );

  const isCollapsed = navigationState === "collapsed";
  const isHoverExpanded = navigationState === "hoverExpanded";

  const onParentActionClick = useCallback(
    (parentActionLabel: string) => {
      if (parentActionLabel === openAccordionLabel) {
        setOpenAccordionLabel(null);
      } else {
        setOpenAccordionLabel(parentActionLabel);
      }
    },
    [openAccordionLabel, setOpenAccordionLabel],
  );

  useHotkeys(
    "[",
    () => {
      const isModalOpen = getIsModalOpen();

      if (!isModalOpen) {
        if (isCollapsed || isHoverExpanded) {
          setNavigationState("expanded");
        } else {
          setNavigationState("collapsed");
        }
      }
    },
    [isCollapsed, isHoverExpanded, setNavigationState],
  );

  // When the user navigates away from the page, don't keep the local storage saved as "hoverExpanded";
  // Otherwise, when a user re-opens S1C later, the nav will be in a weird hoverExpanded state without the user hovering over it
  useEffect(() => {
    const collapseNavigation = () => {
      if (document.hidden && navigationState === "hoverExpanded") {
        setNavigationState("collapsed");
      }
    };

    document.addEventListener("visibilitychange", collapseNavigation);

    return () => {
      document.removeEventListener("visibilitychange", collapseNavigation);
    };
  }, [navigationState, setNavigationState]);

  return (
    <Flex
      direction="column"
      width={{
        base: "full",
        lg: isCollapsed ? "3.5rem" : "15rem",
      }}
      position="absolute"
      height="inherit"
      paddingTop={3}
      paddingInline={2}
      backgroundColor="bg-surface"
      boxShadow={{
        base: "none",
        lg: "base",
      }}
      // The background color is whiteAlpha.200 over a black background, without the transparency that comes with whiteAlpha.200;
      // making it whiteAlpha.200 made elements show through the nav when hover expanded
      _dark={{ bg: "#242424", boxShadow: isHoverExpanded ? "dark-lg" : "base" }}
      transition="width 0.2s ease-out"
      overflowY={isCollapsed ? "hidden" : "auto"}
      overflowX="clip"
      onMouseEnter={() => {
        if (isCollapsed) {
          setNavigationState("hoverExpanded");
        }
      }}
      onMouseLeave={() => {
        if (isCollapsed || isHoverExpanded) {
          setNavigationState("collapsed");
        }
      }}
      zIndex="sidebar"
    >
      <Stack spacing={4} shouldWrapChildren>
        <Stack spacing={1} shouldWrapChildren>
          {links.primary.map((action) => (
            <GenericNavButton
              key={action.label}
              action={action}
              onParentActionClick={onParentActionClick}
              openAccordion={
                !isDesktop || isHoverExpanded || navigationState === "expanded"
                  ? openAccordionLabel
                  : null
              }
              isDesktop={isDesktop}
              closeNavbarDrawerAction={closeNavbarDrawerAction}
              setOpenAccordion={setOpenAccordionLabel}
              navigationState={navigationState}
            />
          ))}
        </Stack>
      </Stack>
    </Flex>
  );
};
