import { HStack, Link, Text, Button, type LinkProps } from "@chakra-ui/react";
import type { ButtonProps } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { transparentize } from "@chakra-ui/theme-tools";

import { FaIcon } from "@stordco/fe-components";

import type { NavigationChild, NavigationLink } from "./types";

type NavButtonProps = (NavigationLink | NavigationChild) &
  LinkProps & {
    displayIcon?: boolean;
    isDesktop: boolean;
    isCollapsed: boolean;
    rightIcon?: IconDefinition;
  };

export const NavButton = ({
  icon,
  label,
  isExternal,
  onClick,
  isDesktop,
  isCollapsed,
  displayIcon = true,
  rightIcon,
  ...buttonProps
}: NavButtonProps) => {
  return (
    <Link
      as={!isExternal && buttonProps.to ? NavLink : "a"}
      color="gray.800"
      paddingX={2}
      paddingY={2.5}
      paddingInlineStart={3}
      marginTop={0}
      borderRadius="base"
      isExternal={isExternal}
      _hover={{ backgroundColor: "gray.100", color: "gray.900" }}
      {...(!isExternal && buttonProps.to
        ? { to: buttonProps.to }
        : { href: buttonProps.to })}
      className="group"
      display="block"
      _activeLink={{
        backgroundColor: transparentize("blue.50", 0.5),
        color: "blue.500",
      }}
      _dark={{
        color: "whiteAlpha.700",
        _hover: {
          backgroundColor: "whiteAlpha.200",
          color: "white",
        },
        _activeLink: {
          backgroundColor: "whiteAlpha.200",
          color: "white",
          ".active-icon": {
            color: "volt",
          },
        },
      }}
      onClick={onClick}
      {...buttonProps}
    >
      <HStack justify="space-between">
        <HStack spacing={4}>
          {/* Don't display icons when the actions are in a parent */}
          {displayIcon ? <FaIcon className="active-icon" icon={icon} /> : null}
          <Text
            marginLeft={displayIcon ? 0 : 6}
            as="span"
            fontSize="sm"
            lineHeight="1.25rem"
            fontWeight="medium"
            width="max-content"
            opacity={isDesktop && isCollapsed ? 0 : undefined}
            transition="opacity 0.2s ease-out"
            display="inline-flex"
            alignItems="center"
          >
            {label}
            {rightIcon && (
              <FaIcon width={3} height={3} marginLeft={2} icon={rightIcon} />
            )}
          </Text>
          )
        </HStack>
        {isExternal && (
          <FaIcon
            icon={faArrowUpRightFromSquare}
            display="none"
            _groupHover={{
              display: "inline-block",
            }}
          />
        )}
      </HStack>
    </Link>
  );
};

type ParentNavButtonProps = ButtonProps &
  Pick<NavigationLink, "icon" | "label"> & {
    isOpen: boolean;
    isActiveLink: boolean;
    isCollapsed: boolean;
    isDesktop: boolean;
  };

export const ParentNavButton = ({
  icon,
  label,
  isActiveLink,
  isOpen,
  isCollapsed,
  isDesktop,
  ...buttonProps
}: ParentNavButtonProps) => {
  return (
    <Button
      variant="ghost"
      color={isActiveLink && !isOpen ? "blue.500" : "gray.800"}
      _dark={{
        color: isOpen || isActiveLink ? "white" : "whiteAlpha.700",
      }}
      paddingX={2}
      paddingY={2.5}
      paddingInlineStart={3}
      marginTop={0}
      borderRadius="base"
      fontWeight="medium"
      justifyContent="start"
      _hover={
        isOpen || isActiveLink
          ? {}
          : {
              background: "gray.100",
              color: "gray.900",
              _dark: { backgroundColor: "whiteAlpha.200", color: "white" },
            }
      }
      _active={{}}
      className="group"
      size="md"
      w="100%"
      {...buttonProps}
    >
      <HStack justify="space-between" spacing="3">
        <FaIcon
          icon={icon}
          {...(isActiveLink
            ? { color: "blue.500", _dark: { color: "volt" } }
            : {})}
        />
        <Text
          as="span"
          fontSize="sm"
          lineHeight="1.25rem"
          fontWeight="medium"
          width="max-content"
          opacity={isDesktop && isCollapsed ? 0 : 1}
          transition="opacity 0.2s ease-out"
        >
          {label}
        </Text>
      </HStack>
    </Button>
  );
};
