import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useRef } from "react";

import { useApi, useOrganization } from "../../../hooks";
import type { Member } from "../../../store/services/api.generated";

export default function RemoveMemberModal({
  member,
  onClose,
}: {
  member: Member;
  onClose: () => void;
}) {
  const api = useApi();
  const { organizationId } = useOrganization();
  const cancelRef = useRef(null);
  const [removeMember, { isLoading }] =
    api.useOrganizationsMembersDeleteMutation();

  const toast = useToast();

  return (
    <AlertDialog isOpen leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>Delete member</AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can&apos;t undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter gap={2}>
            <Button
              ref={cancelRef}
              variant="ghost"
              colorScheme="gray"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={async () => {
                try {
                  await removeMember({
                    organizationId,
                    id: member.id,
                  }).unwrap();
                  toast({
                    status: "success",
                    title: "Member deleted",
                  });
                  onClose();
                } catch (error) {
                  console.error(error);
                  toast({
                    status: "error",
                    title: "Error deleting member. Please try again.",
                  });
                }
              }}
              isLoading={isLoading}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
