import type { ConfigureStoreOptions } from "@reduxjs/toolkit";
import {
  combineSlices,
  configureStore,
  createDynamicMiddleware,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import authReducer from "../features/auth/authSlice";
import configReducer from "../features/config/configSlice";
import organizationSwitcherReducer from "../features/organizationSwitcher/organizationSwitcherSlice";

import { api } from "./api";

export const dynamicMiddleware = createDynamicMiddleware();

export const rootReducer = combineSlices({
  [api.reducerPath]: api.reducer,
  config: configReducer,
  auth: authReducer,
  organizationSwitcher: organizationSwitcherReducer,
});

export const setupStore = (
  preloadedState?: ConfigureStoreOptions["preloadedState"],
) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // Adding the api middleware enables caching, invalidation, polling and other features of RTK Query
      getDefaultMiddleware({ serializableCheck: false })
        .prepend(dynamicMiddleware.middleware)
        .concat(api.middleware),
    preloadedState,
  });
};

export const store = setupStore();
// Call setupListeners to enable online & focused detection
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
