import "@total-typescript/ts-reset";
import { Suspense, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { Global } from "@emotion/react";

import {
  AppearanceModeController,
  DatadogProvider,
  ErrorBoundaryReloadOnDynamicImport,
  PageLoader,
  SEO,
  StordChakraProvider,
} from "@stordco/fe-components";

import App from "./App";
import { ConfigGate } from "./components/ConfigGate";
import { store } from "./store";
import "./styles/globals.css";

const Fonts = () => (
  <Global
    styles={`
    @font-face {
      font-family: 'Inter';
      font-weight: 700;
      font-style: normal;
      font-display: swap;
    }
    
    @font-face {
      font-family: 'Inter';
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }
      `}
  />
);

export default Fonts;

createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <StordChakraProvider>
      <Fonts />
      <AppearanceModeController />
      <ErrorBoundaryReloadOnDynamicImport>
        <Suspense fallback={<PageLoader />}>
          <HelmetProvider>
            <Provider store={store}>
              <ConfigGate>
                {(config) => (
                  <DatadogProvider
                    version={__APP_VERSION__}
                    applicationId={config.DATADOG_APPLICATION_ID}
                    clientToken={config.DATADOG_CLIENT_TOKEN}
                    service={config.DATADOG_SERVICE_NAME}
                    env={config.DATADOG_ENV}
                  >
                    <SEO
                      titleTemplate="%s | Stord Cloud"
                      defaultTitle="Stord Cloud"
                    />
                    <App />
                  </DatadogProvider>
                )}
              </ConfigGate>
            </Provider>
          </HelmetProvider>
        </Suspense>
      </ErrorBoundaryReloadOnDynamicImport>
    </StordChakraProvider>
  </StrictMode>,
);
