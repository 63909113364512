import {
  Box,
  ButtonGroup,
  DarkMode,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Kbd,
  Tooltip,
  usePrevious,
} from "@chakra-ui/react";
import {
  faBars,
  faDisplay,
  faMoon,
  faMagnifyingGlass,
  faSunBright,
  faXmarkLarge,
} from "@fortawesome/pro-regular-svg-icons";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import {
  FaIcon,
  useAppearanceMode,
  useIsDesktop,
} from "@stordco/fe-components";

import { AppSelector } from "./AppSelector";
import { UserAccountMenu } from "./UserAccountMenu";

import { useNavigation } from ".";
import { CloudAdminButton } from "./CloudAdminButton";

const MotionIcon = motion(FaIcon);

export const Navbar = ({
  onMobileToggle,
  mobileIsOpen,
  onMobileClose,
  includeSearch = false,
}: {
  onMobileToggle: () => void;
  mobileIsOpen: boolean;
  onMobileClose: () => void;
  includeSearch?: boolean;
}) => {
  const isDesktop = useIsDesktop();
  const { setNavigationState, navigationState } = useNavigation();
  const location = useLocation();

  const prevPathname = usePrevious(location.pathname);
  const [appearanceMode, setAppearanceMode] = useAppearanceMode();

  const hasChanged =
    typeof prevPathname !== "undefined" && prevPathname !== location.pathname;

  useEffect(() => {
    if (mobileIsOpen && hasChanged) {
      onMobileClose();
    }
  }, [hasChanged, mobileIsOpen, onMobileClose]);

  return (
    <Box
      backgroundColor="black"
      _dark={{
        backgroundColor: "whiteAlpha.200",
        boxShadow: "0 0 0 1px var(--chakra-colors-black)",
      }}
      paddingX={3}
      paddingRight={7}
      paddingY={2}
      position="relative"
      boxShadow="md"
    >
      <HStack justify="space-between">
        <HStack spacing={0.5} minHeight={10}>
          {isDesktop ? (
            <Tooltip
              label={
                <Flex gap={2}>
                  {navigationState === "collapsed" ? "Expand" : "Collapse"}
                  <DarkMode>
                    <Kbd>[</Kbd>
                  </DarkMode>
                </Flex>
              }
              placement="bottom"
            >
              <IconButton
                variant="ghost"
                aria-label="Open menu"
                icon={<FaIcon icon={faBars} />}
                colorScheme="whiteAlpha"
                onClick={() =>
                  setNavigationState((val) => {
                    return val === "collapsed" ? "expanded" : "collapsed";
                  })
                }
              />
            </Tooltip>
          ) : (
            <IconButton
              variant="ghost"
              aria-label="Open menu"
              icon={
                mobileIsOpen ? (
                  <FaIcon icon={faXmarkLarge} />
                ) : (
                  <FaIcon icon={faBars} />
                )
              }
              colorScheme="whiteAlpha"
              onClick={onMobileToggle}
            />
          )}
          <AppSelector />
        </HStack>

        {includeSearch ? (
          <InputGroup
            maxW={{ md: "sm", lg: "2xl" }}
            display={{ base: "none", md: "inline-flex" }}
          >
            <InputLeftElement>
              <FaIcon icon={faMagnifyingGlass} size="sm" color="white" />
            </InputLeftElement>
            <Input
              placeholder="Search"
              backgroundColor="gray.900"
              border="none"
              color="white"
            />
          </InputGroup>
        ) : null}

        <HStack spacing={{ base: 2, md: 3 }}>
          <ButtonGroup variant="ghost" colorScheme="whiteAlpha" spacing={2}>
            <CloudAdminButton />
            <Tooltip
              label={
                appearanceMode === "system"
                  ? "Set to light mode"
                  : appearanceMode === "light"
                  ? "Set to dark mode"
                  : "Set to system mode"
              }
              placement="bottom"
            >
              <IconButton
                aria-label={
                  appearanceMode === "system"
                    ? "Set to light mode"
                    : appearanceMode === "light"
                    ? "Set to dark mode"
                    : "Set to system mode"
                }
                onClick={() =>
                  setAppearanceMode(
                    appearanceMode === "system"
                      ? "light"
                      : appearanceMode === "light"
                      ? "dark"
                      : "system",
                  )
                }
                icon={
                  <AnimatePresence mode="wait" initial={false}>
                    <MotionIcon
                      key={appearanceMode}
                      icon={
                        appearanceMode === "system"
                          ? faSunBright
                          : appearanceMode === "light"
                          ? faMoon
                          : faDisplay
                      }
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                      }}
                      exit={{
                        opacity: 0,
                      }}
                      transition={{ duration: "0.2" }}
                    />
                  </AnimatePresence>
                }
              />
            </Tooltip>
          </ButtonGroup>
          <UserAccountMenu />
        </HStack>
      </HStack>
    </Box>
  );
};
