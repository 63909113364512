import { Tr, Td, IconButton, Table, Tbody, Th, Thead } from "@chakra-ui/react";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import type { UseFieldArrayRemove } from "react-hook-form";

import { FaIcon, StackedCell, capitalize } from "@stordco/fe-components";

import { useApi, useOrganization } from "../hooks";

export function MemberRoleTable({
  roles,
  onRemove,
}: {
  onRemove: UseFieldArrayRemove;
  roles: Array<
    { resourcePath: string; role: string; roleDisplayName: string } & {
      id: string;
    }
  >;
}) {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Role</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {roles.map((row, index) => (
          <RoleRow key={row.id} {...row} onRemove={() => onRemove(index)} />
        ))}
      </Tbody>
    </Table>
  );
}

function RoleRow({
  resourcePath,
  role,
  roleDisplayName,
  onRemove,
}: {
  resourcePath: string;
  role: string;
  roleDisplayName: string;
  onRemove: () => void;
}) {
  const resourceIsRealm = resourcePath.includes("/");

  return (
    <Tr>
      <Td>
        {resourceIsRealm ? (
          <RealmCell resourcePath={resourcePath} />
        ) : (
          <AppCell id={resourcePath} />
        )}
      </Td>
      <Td>{roleDisplayName ? roleDisplayName : capitalize(role)}</Td>
      <Td textAlign="right">
        <IconButton
          aria-label="Delete row"
          variant="ghost"
          colorScheme="gray"
          icon={<FaIcon icon={faXmark} onClick={onRemove} />}
        />
      </Td>
    </Tr>
  );
}

function AppCell({ id }: { id: string }) {
  const api = useApi();
  const { organizationId } = useOrganization();

  const { data, isLoading } = api.useOrganizationAppsShowQuery({
    organizationId,
    id,
  });

  return <StackedCell lines={[data?.data.name]} isLoading={isLoading} />;
}

function RealmCell({
  resourcePath: fullResourcePath,
}: {
  resourcePath: string;
}) {
  const api = useApi();
  const { organizationId } = useOrganization();
  const [appAlias, ...resourcePathParts] = fullResourcePath.split("/");
  const resourcePath = resourcePathParts.join("/");

  const { data: realmData, isLoading: realmDataIsLoading } =
    api.useRealmsShowQuery({
      organizationId,
      appId: appAlias,
      resourcePath,
    });

  const realm = realmData?.data;

  const { data: appData, isLoading: appDataIsLoading } =
    api.useOrganizationAppsShowQuery({
      organizationId,
      id: appAlias,
    });

  return (
    <StackedCell
      isLoading={realmDataIsLoading || appDataIsLoading}
      lines={[
        realm?.name,
        `${capitalize(appData?.data.alias ?? "")} - ${appData?.data.name}`,
      ]}
    />
  );
}
