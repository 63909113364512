import { createApi } from "@reduxjs/toolkit/query/react";

import type {
  App,
  ApiKey,
  CreateOrUpdateApiKeyPayload,
  CreateOrUpdateOrganizationPayload,
  Organization,
  OrganizationMember,
  RenderedResourceInstance,
  User,
} from "../models";

import baseQuery from "./baseQuery";
import { fetchStaticConfig } from "./common";

export { userApi as api } from "./services/api";

type ApiResponse<T> = {
  status: boolean;
  data: T;
  metadata: { total_count: number };
};

export const nonGeneratedApi = createApi({
  baseQuery,
  tagTypes: [
    "Organization",
    "OrganizationMember",
    "App",
    "User",
    "ApiKey",
    "ApiKeyRoles",
    "AvailablePermission",
  ],
  endpoints: (build) => ({
    syncSeeds: build.mutation<{ ok: boolean }, void>({
      query: () => ({
        url: "/seeds/sync",
        method: "POST",
        body: {},
      }),
    }),
    listOrganizations: build.query<ApiResponse<Organization[]>, void>({
      query: () => "/admin/organizations",
      providesTags: ["Organization"],
    }),
    createOrganization: build.mutation<
      { data: { organization: Organization; member: OrganizationMember } },
      CreateOrUpdateOrganizationPayload
    >({
      query: (data) => ({
        url: `/admin/organizations`,
        method: "POST",
        body: { data },
      }),
      invalidatesTags: ["Organization"],
    }),

    listOrganizationMembers: build.query<
      ApiResponse<(OrganizationMember & User)[]>,
      string
    >({
      query: (id) => `/organizations/${id}/members`,
      providesTags: ["OrganizationMember"],
    }),
    showOrganizationMember: build.query<
      {
        ok: boolean;
        data: OrganizationMember &
          User & { permissions: { name: string; slug: string }[] };
      },
      { organization_id: string; organization_member_id: string }
    >({
      query: ({ organization_id, organization_member_id }) =>
        `/organizations/${organization_id}/members/${organization_member_id}`,
      providesTags: (_res, _err, { organization_member_id }) => [
        { type: "OrganizationMember", id: organization_member_id },
      ],
    }),
    inviteOrganizationMembers: build.mutation<
      Organization,
      { organization_id: string; emails: string[] }
    >({
      query: ({ organization_id, ...body }) => ({
        url: `/organizations/${organization_id}/members`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["OrganizationMember"],
    }),

    getOrganization: build.query<ApiResponse<Organization>, string>({
      query: (id) => `/admin/organizations/${id}`,
      providesTags: (_res, _err, id) => [{ type: "Organization", id }],
    }),
    updateOrganization: build.mutation<
      Organization,
      CreateOrUpdateOrganizationPayload & { organization_id: string }
    >({
      query: ({ organization_id, ...body }) => ({
        url: `/admin/organizations/${organization_id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Organization"],
    }),
    deleteOrganization: build.mutation<void, string>({
      query: (organization_id) => ({
        url: `/admin/organizations/${organization_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Organization"],
    }),

    listApps: build.query<ApiResponse<App[]>, void>({
      query: () => "/apps",
    }),
    createApp: build.mutation<any, void>({
      query: () => ({
        url: ``,
        method: "POST",
      }),
    }),

    getApp: build.query<any, string>({
      query: (id) => `/organizations/${id}`,
    }),
    updateApp: build.mutation<any, Partial<App>>({
      query: ({ app_id, ...body }) => ({
        url: `/apps/${app_id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteApp: build.mutation<any, string>({
      query: (id) => ({
        url: `/apps/${id}`,
        method: "DELETE",
      }),
    }),

    listOrganizationAvailablePermissions: build.query<
      ApiResponse<RenderedResourceInstance>,
      string
    >({
      query: (organization_id) =>
        `/organizations/${organization_id}/available_permissions`,
      providesTags: ["AvailablePermission"],
    }),
    listOrganizationApiKeys: build.query<ApiResponse<ApiKey[]>, string>({
      query: (organization_id) =>
        `/admin/organizations/${organization_id}/api_keys`,
      providesTags: ["ApiKey"],
    }),
    createOrganizationApiKey: build.mutation<
      ApiResponse<ApiKey>,
      CreateOrUpdateApiKeyPayload & { organization_id: string }
    >({
      query: ({ organization_id, ...data }) => ({
        url: `/admin/organizations/${organization_id}/api_keys`,
        method: "POST",
        body: { data },
      }),
      invalidatesTags: ["ApiKey"],
    }),
    showOrganizationApiKey: build.query<
      ApiResponse<ApiKey & { permissions: { name: string; slug: string }[] }>,
      { organization_id: string; api_key_id: string }
    >({
      query: ({ organization_id, api_key_id }) =>
        `/admin/organizations/${organization_id}/api_keys/${api_key_id}`,
      providesTags: (_res, _err, { api_key_id }) => [
        { type: "ApiKey", id: api_key_id },
      ],
    }),
    // updateApiKeyById: build.mutation<
    //   ApiResponse<ApiKey>,
    //   CreateOrUpdateApiKeyPayload & { id: string }
    // >({
    //   query: ({ id, ...body }) => ({
    //     url: `/api_keys/${id}`,
    //     method: "PUT",
    //     body,
    //   }),
    //   invalidatesTags: ["ApiKey"],
    // }),
    // deleteApiKeyById: build.mutation<void, string>({
    //   query: (api_key_id) => ({
    //     url: `/api_keys/${api_key_id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["ApiKey"],
    // }),

    getTokenRoles: build.query<ApiResponse<ApiKey[]>, string>({
      query: (api_key_id) => `/api_keys/${api_key_id}`,
      providesTags: (_res, _err, id) => [{ type: "ApiKeyRoles", id }],
    }),
    createTokenRole: build.mutation<
      ApiResponse<ApiKey>,
      CreateOrUpdateApiKeyPayload & { api_key_id: string }
    >({
      query: ({ api_key_id, ...body }) => ({
        url: `/api_keys/${api_key_id}/roles`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ApiKeyRoles"],
    }),
    getTokenRoleById: build.query<
      ApiResponse<ApiKey>,
      { api_key_id: string; api_key_role_id: string }
    >({
      query: ({ api_key_id, api_key_role_id }) =>
        `/api_keys/${api_key_id}/roles/${api_key_role_id}`,
      providesTags: (_res, _err, { api_key_id, api_key_role_id }) => [
        {
          type: "ApiKeyRoles",
          id: `${api_key_id}-${api_key_role_id}`,
        },
      ],
    }),
    deleteTokenRoleById: build.mutation<
      void,
      { api_key_id: string; api_key_role_id: string }
    >({
      query: ({ api_key_id, api_key_role_id }) => ({
        url: `/api_keys/${api_key_id}/roles/${api_key_role_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ApiKeyRoles"],
    }),

    getConfig: build.query<EnvConfig, void>({
      queryFn: async () => {
        try {
          const data = await fetchStaticConfig();
          return { data };
        } catch (err) {
          return {
            error: { status: 500, data: "Error fetching config" },
          };
        }
      },
    }),
  }),
});

export type EnvConfigKeys =
  | "API_URL"
  | "OMS_API_URL"
  | "PARCEL_API_URL"
  | "AUTH0_DOMAIN"
  | "AUTH0_CLIENT_ID"
  | "DATADOG_APPLICATION_ID"
  | "DATADOG_CLIENT_TOKEN"
  | "DATADOG_SERVICE_NAME"
  | "DATADOG_ENV"
  | "FLATFILE_PUBLISHABLE_KEY"
  | "FLATFILE_ENVIRONMENT_ID";
export type EnvConfig = Record<EnvConfigKeys, string>;
