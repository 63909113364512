import { useAuth0 } from "@auth0/auth0-react";
// import type { LogoutOptions } from "auth0-js";
import { useCallback, useMemo } from "react";
import { bindActionCreators } from "redux";

import { logout as logoutAction, setAuthData } from "../features/auth/actions";
import { useAppDispatch, useAppSelector } from "../store/hooks";

import { useConfig } from "./";

export const getBestUserName = (user?: any): string => {
  return user?.name || user?.email || user?.id || "--";
};

export function useAuth() {
  const {
    user,
    loginWithRedirect,
    getIdTokenClaims,
    isLoading,
    logout: auth0Logout,
    isAuthenticated: isAuthenticatedWithAuth0,
  } = useAuth0();
  const { AUTH0_CLIENT_ID } = useConfig();
  const dispatch = useAppDispatch();

  const { token, role } = useAppSelector((state) => state.auth);

  const bestAvailableDisplayName = getBestUserName(user);

  const combinedLogout = useCallback(
    (options?: any) => {
      dispatch(logoutAction());
      auth0Logout({
        client_id: AUTH0_CLIENT_ID,
        returnTo: `${window.location.origin}`,
        ...options,
      });
    },
    [dispatch, auth0Logout, AUTH0_CLIENT_ID],
  );

  const isAuthenticated = isAuthenticatedWithAuth0;

  /**
   * NOTE: this is temporary until we have assigned "admin" roles for users
   */
  const isStordEmployee =
    role === "stord_employee" || user?.email?.endsWith("@stord.com");

  return useMemo(
    () => ({
      isAuthenticated,
      isLoading,
      loginWithRedirect,
      getIdTokenClaims,
      logout: combinedLogout,
      user,
      bestAvailableDisplayName,
      token,
      role,
      // stord_employees should be considered as 'super-admins'
      isAdmin: role === "admin" || isStordEmployee,
      isSupervisor: role === "supervisor",
      isAssociate: role === "associate",
      isStordEmployee,
      ...bindActionCreators({ setAuthData }, dispatch),
    }),
    [
      isAuthenticated,
      isLoading,
      isStordEmployee,
      loginWithRedirect,
      getIdTokenClaims,
      combinedLogout,
      user,
      bestAvailableDisplayName,
      token,
      role,
      dispatch,
    ],
  );
}
