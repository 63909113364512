import {
  faEnvelopeOpenText,
  faHouse,
} from "@fortawesome/pro-regular-svg-icons";
import type { ReactNode } from "react";
import { useEffect } from "react";

import { clearOrganization } from "../features/auth/actions";
import { selectCurrentOrganization } from "../features/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";

import { LayoutWrapper } from "./shell/LayoutWrapper";
import { NavigationProvider } from "./shell/Navigation";

export default function UserNavigation({ children }: { children: ReactNode }) {
  const currentOrg = useAppSelector(selectCurrentOrganization);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // clear current org
    if (currentOrg?.id) {
      dispatch(clearOrganization());
    }
  }, [dispatch, currentOrg]);

  const primaryLinks = [
    {
      label: "Home",
      icon: faHouse,
      to: "/",
    },
    {
      label: "Invitations",
      icon: faEnvelopeOpenText,
      to: "/invitations",
    },
  ];

  return (
    <NavigationProvider
      links={{
        primary: primaryLinks,
      }}
    >
      <LayoutWrapper>{children}</LayoutWrapper>
    </NavigationProvider>
  );
}
