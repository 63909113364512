import type { ReactNode } from "react";
import { useEffect, useState } from "react";

// import { useSearchParams } from "react-router-dom";
import { PageLoader } from "@stordco/fe-components";

import { setAuthData } from "../features/auth/actions";
import { useAuth } from "../hooks";
import { useAppDispatch } from "../store/hooks";

/**
 * @remarks This component will prevent the app from rendering while we set the auth data in the store. This will prevent
 * the user from seeing pages that are not scoped for their auth role. This also fetches the initial building data necessary
 * for building-related configurations (such as timezone). Once we have a buildingId set in the store, we can render everything
 * else!
 *
 * getAuth0Claims and getBuildingsByBuildingId set state in the authSlice based on endpoint matchers so we don't
 * need to explicitly call some action to populate the store
 *
 * Also, if getAuth0Claims fails for some reason and the claims are empty, it will still call getBuildingsByBuildingId,
 * which will fail with a 401 -- This will force us to attempt to get claims again or automatically log the user out
 * (All of this happens in baseQuery.tsx -> baseQueryWithReauth)
 */
export const AuthHydrator = ({ children }: { children: ReactNode }) => {
  const [hasIdToken, setHasIdToken] = useState(false);
  const { getIdTokenClaims, isAuthenticated, isLoading } = useAuth();
  const dispatch = useAppDispatch();
  //   const buildingId = useAppSelector(selectCurrentBuildingId);
  // const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const getToken = async () => {
      if (isAuthenticated && !hasIdToken) {
        try {
          const idToken = await getIdTokenClaims();

          if (!idToken) {
            console.error("Could not fetch id token or building");
            return;
          }

          dispatch(setAuthData(idToken));
          //   const claims = idToken["https://wms.stord.com"];
          //   if (claims?.building_id) {
          //     dispatch(setBuildingId({ id: claims?.building_id }));
          //   }
          setHasIdToken(true);
        } catch (err) {
          console.error("Could not fetch id token or building", err);
        }
      }
    };

    getToken();
  }, [
    dispatch,
    getIdTokenClaims,
    isAuthenticated,
    // getBuilding,
    setHasIdToken,
    hasIdToken,
  ]);

  //   const queryParamBuildingId = searchParams.get("building_id");
  //   const shouldSwitchBuildingForAdmin =
  //     queryParamBuildingId !== null &&
  //     queryParamBuildingId !== buildingId &&
  //     isStordEmployee;

  //   const {
  //     isLoading: isLoadingBuilding,
  //     error: buildingError,
  //     isError: isBuildingError,
  //   } = useGetBuildingsByBuildingIdQuery(
  //     { buildingId },
  //     { skip: !buildingId || shouldSwitchBuildingForAdmin }
  //   );

  //   useEffect(() => {
  //     // this clears the building id from local storage to force building select screen
  //     // to display for a stord_employee on next page load. We shouldn't do this for non-stord_employees
  //     // or we will end up infinitely calling getBuildingsById until it stops failing.
  //     // We also clear the buildingId from the query string to prevent error looping
  //     if (isBuildingError && isStordEmployee) {
  //       dispatch(setBuildingId({ id: "" }));
  //       searchParams.delete("building_id");
  //       setSearchParams(searchParams, { replace: true });
  //       // If the url contains a building Id and the user is a stord employee, we should switch their building context to the query param
  //     } else if (shouldSwitchBuildingForAdmin) {
  //       dispatch(setBuildingId({ id: queryParamBuildingId }));
  //     }
  //   }, [
  //     dispatch,
  //     isBuildingError,
  //     isStordEmployee,
  //     queryParamBuildingId,
  //     shouldSwitchBuildingForAdmin,
  //     setSearchParams,
  //     searchParams,
  //   ]);

  if (isLoading || (isAuthenticated && !hasIdToken)) {
    return <PageLoader />;
  }

  return <div data-testid="auth-hydrator">{children}</div>;
};
