import { Drawer, DrawerContent, DrawerOverlay } from "@chakra-ui/react";

import { Sidebar } from ".";

export const MobileSidebar = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="left"
      size="2xs"
      preserveScrollBarGap
    >
      <DrawerOverlay />
      <DrawerContent>
        <Sidebar />
      </DrawerContent>
    </Drawer>
  );
};
