import type { TextProps } from "@chakra-ui/react";
import {
  Button,
  Flex,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  chakra,
  useColorModeValue,
} from "@chakra-ui/react";
import { faAngleDown, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { Fragment, useMemo } from "react";
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom";

import {
  FaIcon,
  StordEmblem,
  StordLogoIconBadge,
} from "@stordco/fe-components";

import { selectCurrentOrganization } from "../../../features/auth/authSlice";
import { useIsAdminContext } from "../../../hooks";
import { useAppSelector } from "../../../store/hooks";
import { userApi } from "../../../store/services/api";
import { StordCloudLogo } from "../StordCloudLogo";

const GROUPABLE_APPS = ["oms", "wms"];

const StordText = ({
  children,
  color = "white",
  fontSize = "md",
  ...rest
}: TextProps) => {
  return (
    <Text
      as="span"
      fontFamily="Inter"
      lineHeight="18px"
      color={color}
      fontSize={fontSize}
      {...rest}
    >
      <chakra.span fontWeight="bold">Stord</chakra.span>
      <chakra.span fontWeight="light">{children}</chakra.span>
    </Text>
  );
};

export function AppSelector() {
  const isAdminContext = useIsAdminContext();
  const location = useLocation();
  const currentOrg = useAppSelector(selectCurrentOrganization);

  const { standaloneApps, appsWithRealms } = userApi.useMyOrganizationQuery(
    currentOrg.alias,
    {
      skip: isAdminContext || !currentOrg.alias,
      selectFromResult: ({ data }) => {
        return {
          standaloneApps:
            data?.data.apps
              .filter((app) => !GROUPABLE_APPS.includes(app.alias))
              .sort((a, b) => a.name.localeCompare(b.name)) ?? [],
          appsWithRealms:
            data?.data.apps
              .filter((app) => app.realms.length)
              .sort((a, b) => a.name.localeCompare(b.name)) ?? [],
        };
      },
    },
  );

  const selectedApp = useMemo(() => {
    const app = standaloneApps.findLast((app) => {
      // NOTE: ignore apps on other domains for now
      if (!app.base_url || app.base_url?.startsWith("http")) return false;

      return matchPath(
        {
          path: app.base_url,
          end: false,
        },
        location.pathname,
      );
    });

    if (!app) return undefined;

    return app;
  }, [location.pathname, standaloneApps]);

  if (isAdminContext) {
    return (
      <HStack alignItems="center" pl={2}>
        <StordEmblem height="24px" fill="white" />
        <chakra.span
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={0.5}
        >
          <StordText>Cloud</StordText>
          <chakra.span fontWeight="semibold" fontSize="10px">
            Admin
          </chakra.span>
        </chakra.span>
      </HStack>
    );
  }

  if (!currentOrg?.id || (!standaloneApps.length && !appsWithRealms.length)) {
    return <StordCloudLogo />;
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="ghost"
        colorScheme="whiteAlpha"
        leftIcon={<StordEmblem height="24px" fill="white" />}
        alignItems="center"
        paddingX={2}
        paddingY={1}
        height={10}
      >
        <chakra.span display="inline-flex" flexDirection="row" gap={4}>
          <chakra.span
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={0.5}
          >
            <StordText>Cloud</StordText>
            {selectedApp ? (
              <chakra.span fontWeight="semibold" fontSize="10px">
                {selectedApp.name}
              </chakra.span>
            ) : null}
          </chakra.span>
          <FaIcon icon={faAngleDown} alignSelf="center" />
        </chakra.span>
      </MenuButton>
      <MenuList zIndex="modalPopovers" minWidth="2xs">
        <MenuOptionGroup value={selectedApp?.alias}>
          <MenuGroupTitle title="Cloud" />
          {standaloneApps?.map((app) => {
            if (!app.base_url) return null;

            const baseProps = {
              icon: <FaIcon icon={faCheck} />,
              value: app.alias,
              sx: {
                "&:hover": {
                  textDecoration: "none",
                },
              },
              _focusVisible: {
                boxShadow: "none",
              },
            };

            if (app.base_url.startsWith("http")) {
              return (
                <MenuItemOption
                  key={app.id}
                  {...baseProps}
                  as={Link}
                  href={app.base_url!}
                >
                  {app.name}
                </MenuItemOption>
              );
            } else {
              return (
                <MenuItemOption
                  key={app.id}
                  {...baseProps}
                  as={RouterLink}
                  to={app.base_url!}
                  isDisabled={selectedApp?.alias === app.alias}
                  _disabled={{
                    opacity: 1,
                    cursor: "unset",
                  }}
                >
                  {app.name}
                </MenuItemOption>
              );
            }
          })}

          {appsWithRealms?.map((app) => {
            return (
              <Fragment key={app.alias}>
                <MenuDivider />
                <MenuGroup>
                  <MenuGroupTitle title={app.alias.toLocaleUpperCase()} />
                  {[...app.realms]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((realm) => (
                      <MenuItemOption
                        key={realm.resource_id}
                        icon={<FaIcon icon={faCheck} />}
                        value={app.alias}
                        as={Link}
                        sx={{
                          "&:hover": {
                            textDecoration: "none",
                          },
                        }}
                        _focusVisible={{
                          boxShadow: "none",
                        }}
                        href={app.base_url?.replace(
                          ":resourceId",
                          realm.resource_id,
                        )}
                      >
                        {realm.name}
                      </MenuItemOption>
                    ))}
                </MenuGroup>
              </Fragment>
            );
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}

function MenuGroupTitle({ title }: { title: string }) {
  return (
    <MenuItem
      as={Flex}
      isDisabled
      _disabled={{
        cursor: "unset",
        pointerEvents: "none",
      }}
    >
      <StordLogoIconBadge
        boxSize={5}
        bgColor="none"
        color={useColorModeValue("black", "white")}
      />
      <StordText color="black" _dark={{ color: "white" }} fontSize="sm">
        {title}
      </StordText>
    </MenuItem>
  );
}
