import { hasPermission } from "@stordco/cloud-utils";

import { api } from "../store/api";

import { useIsAdminContext } from "./useIsAdminContext";
import { useOrganization } from "./useOrganization";

export const useCloudPermissions = (
  resourcePath: string | string[],
  permission: string,
) => {
  const isAdminContext = useIsAdminContext();
  const { data: orgData } = useOrganization();

  const { data, ...meta } = api.useAuthPermissionsQuery(
    isAdminContext
      ? {
          app: "cloud_admin",
          organization: "stord",
        }
      : {
          app: "cloud",
          organization: orgData?.data.alias ?? "",
        },
  );

  if (typeof resourcePath === "string") {
    resourcePath = resourcePath.split("/");
  }

  const adminPrefixedResourcePath = isAdminContext
    ? ["organizations", orgData?.data.alias ?? "*", ...resourcePath]
    : resourcePath;

  return [
    hasPermission(
      data?.data?.permissions || {},
      adminPrefixedResourcePath,
      permission,
    ),
    {
      data,
      ...meta,
    },
  ] as const;
};
