import {
  Badge,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { faEllipsisVertical, faUser } from "@fortawesome/pro-regular-svg-icons";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { debounce } from "lodash-es";
import { useCallback, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  createSearchComponent,
  ErrorFetchingData,
  FaIcon,
  Filter,
  FixedHeightTableLayout,
  getDetailedLocationIndicatorText,
  getOnSortingChangeColonFormat,
  NoFilteredResultsFound,
  NoResultsFound,
  StackedCell,
  TanstackTable,
  TanstackTablePagination,
  useBaseFilter,
  useSearchParams,
  useSortingColonFormat,
} from "@stordco/fe-components";

import {
  useTanstackPagination,
  useTanstackPaginationChange,
} from "../../../helpers/table";
import {
  useApi,
  useAuth,
  useIsAdminContext,
  useOrganization,
} from "../../../hooks";
import { useCloudPermissions } from "../../../hooks/useCloudPermissions";
import type { Member } from "../../../store/services/api.generated";

import EditMemberRoles from "./EditMemberRoles";
import { getUsersFullName } from "./helpers";
import RemoveMemberModal from "./RemoveMemberModal";

type ModalControl =
  | { action: "edit"; member: Member }
  | { action: "delete"; member: Member }
  | null;

const { Component: SearchComponent, ref: searchRef } = createSearchComponent({
  displayName: "search",
});
export default function MembersTable() {
  const isAdminContext = useIsAdminContext();

  const [hasWritePermission] = useCloudPermissions(
    "organization_members",
    "write",
  );

  const api = useApi();

  const { user } = useAuth();
  const { organizationId } = useOrganization();
  const [params, { append, clear }] = useSearchParams();
  const status = params.status as string;

  const sorting = useSortingColonFormat({
    sort: typeof params.sort === "string" ? params.sort : "inserted_at",
  });

  const onSearch = useCallback(
    (search: string) => {
      append({
        page: undefined,
        search: search || undefined,
      });
    },
    [append],
  );

  const debouncedOnSearch = useMemo(() => debounce(onSearch, 200), [onSearch]);

  const [modalControl, setModalControl] = useState<ModalControl>(null);

  const filterParams = ["status"];

  const { data, isLoading, refetch, isError } =
    api.useOrganizationsMembersIndexQuery({
      organizationId,
      page: Number(params.page) || 1,
      filter: {
        membership_status: status,
      },
      search: params.search ? `%${params.search}%` : undefined,
      sort: params.sort as string,
    });

  const [activateMember] = api.useOrganizationsMembersActivateMutation();
  const [deactivateMember] = api.useOrganizationsMembersDeactivateMutation();
  const [resendWelcomeEmail] =
    api.useOrganizationsMembersResendInvitationMutation();

  const { pageCount, pagination } = useTanstackPagination(data?.meta);
  const { onPaginationChange } = useTanstackPaginationChange(data?.meta);

  const statusFilter = useBaseFilter({
    scope: "Status",
    options: [
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" },
      { value: "invited", label: "Invited" },
    ],
    queryParam: "status",
  });

  const toast = useToast();

  const columns = useMemo(() => {
    // NOTE: Including permissions in the type gets an infinite type error
    const columnHelper = createColumnHelper<Omit<Member, "permissions">>();

    return [
      columnHelper.accessor("first_name", {
        header: "Name and email",
        cell: ({ row: { original } }) => (
          <StackedCell
            lines={[getUsersFullName(original), original.email]}
            to={original.id}
          />
        ),
      }),
      columnHelper.accessor("membership_status", {
        header: "Status",
        cell: ({ getValue }) => (
          <Badge
            colorScheme={getValue() === "active" ? "green" : "gray"}
            textTransform="uppercase"
          >
            {getValue()}
          </Badge>
        ),
      }),
      columnHelper.accessor("role_count", {
        header: "Permissions",
        enableSorting: false,
        cell: ({ getValue, row: { original } }) => {
          if (!getValue()) return <>-</>;

          return (
            <Link as={RouterLink} to={`${original.id}/permissions`}>
              {getValue()} {Number(getValue()) > 1 ? "roles" : "role"}
            </Link>
          );
        },
      }),
      columnHelper.accessor("inserted_at", {
        header: "Inserted",
        cell: ({ getValue }) => {
          const value = getValue();
          return <>{value ? new Date(value).toLocaleString() : "-"}</>;
        },
      }),
      columnHelper.accessor("updated_at", {
        header: "Updated",
        cell: ({ getValue }) => {
          const value = getValue();
          return <>{value ? new Date(value).toLocaleString() : "-"}</>;
        },
      }),
      columnHelper.display({
        id: "actions",
        cell: ({ row: { original } }) =>
          hasWritePermission ? (
            <Menu>
              <MenuButton
                as={IconButton}
                variant="outline"
                colorScheme="gray"
                size="sm"
                icon={<FaIcon icon={faEllipsisVertical} />}
              />
              <MenuList>
                {original.membership_status !== "active" ? (
                  <MenuItem
                    isDisabled={
                      original.membership_status === "invited" &&
                      !isAdminContext
                    }
                    onClick={() =>
                      activateMember({
                        userId: original.id,
                        organizationId,
                      })
                        .unwrap()
                        .then(() => {
                          toast({
                            status: "success",
                            description: "User activated successfully.",
                          });
                        })
                        .catch((err) => {
                          console.error(err);
                          toast({
                            status: "error",
                            description:
                              "There was an error activating this user. Please try again.",
                          });
                        })
                    }
                  >
                    Activate
                  </MenuItem>
                ) : original.membership_status === "active" ? (
                  <MenuItem
                    onClick={() =>
                      deactivateMember({
                        userId: original.id,
                        organizationId,
                      })
                        .unwrap()
                        .then(() => {
                          toast({
                            status: "success",
                            description: "User deactivated successfully.",
                          });
                        })
                        .catch((err) => {
                          console.error(err);
                          toast({
                            status: "error",
                            description:
                              "There was an error deactivating this user. Please try again.",
                          });
                        })
                    }
                  >
                    Deactivate
                  </MenuItem>
                ) : null}

                <MenuItem
                  onClick={() => {
                    resendWelcomeEmail({
                      userId: original.id,
                      organizationId,
                    })
                      .unwrap()
                      .then(() => {
                        toast({
                          status: "success",
                          description: "Welcome email resent successfully.",
                        });
                      })
                      .catch((err) => {
                        console.error(err);
                        toast({
                          status: "error",
                          description:
                            "There was an error sending the email. Please try again.",
                        });
                      });
                  }}
                >
                  Resend invitation email
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    setModalControl({ action: "edit", member: original })
                  }
                >
                  Edit
                </MenuItem>
                {user?.email !== original.email ? (
                  <MenuItem
                    onClick={() =>
                      setModalControl({ action: "delete", member: original })
                    }
                  >
                    Delete
                  </MenuItem>
                ) : null}
              </MenuList>
            </Menu>
          ) : null,
        meta: {
          isRightAligned: true,
        },
      }),
    ];
  }, [
    hasWritePermission,
    isAdminContext,
    user?.email,
    activateMember,
    organizationId,
    deactivateMember,
    resendWelcomeEmail,
    toast,
  ]);

  const tableData = useMemo(() => {
    return data?.data ?? [];
  }, [data]);

  const table = useReactTable({
    data: tableData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    enableMultiSort: false,
    enableSortingRemoval: false,
    onSortingChange: getOnSortingChangeColonFormat({
      sorting,
      onChange(sort) {
        append({
          sort,
          page: 1,
        });
      },
    }),
    onPaginationChange,
    pageCount,
    state: {
      pagination,
      sorting,
    },
  });

  return (
    <>
      <FixedHeightTableLayout.TableWrapper>
        <FixedHeightTableLayout.TableWrapperHeader
          search={
            <SearchComponent
              ref={searchRef}
              onSearch={debouncedOnSearch}
              placeholder="Search by name, email, external ID or membership status"
              initialValue={params.search as string}
            />
          }
          filters={<Filter {...statusFilter} />}
        />
        <FixedHeightTableLayout.TableContainer table={table}>
          <TanstackTable
            table={table}
            stickyHeaders
            isLoading={isLoading}
            renderEmptyData={() => {
              if (isError) {
                return <ErrorFetchingData onRetryClick={() => refetch()} />;
              }

              if (
                params.search ||
                filterParams.some((param) => params[param])
              ) {
                return (
                  <NoFilteredResultsFound
                    onClearFiltersClick={() => {
                      searchRef.current?.clear();
                      clear();
                    }}
                  />
                );
              }

              return (
                <NoResultsFound
                  icon={faUser}
                  objectName={{ plural: "users", singular: "user" }}
                />
              );
            }}
          />
        </FixedHeightTableLayout.TableContainer>
        <FixedHeightTableLayout.TableWrapperFooter>
          <TanstackTablePagination
            table={table}
            isLoading={isLoading}
            getLocationIndicatorText={getDetailedLocationIndicatorText({
              totalCount: data?.meta.total_entries,
              singularName: "member",
              pluralName: "members",
            })}
            ml="auto"
          />
        </FixedHeightTableLayout.TableWrapperFooter>
      </FixedHeightTableLayout.TableWrapper>

      {modalControl?.action === "delete" && (
        <RemoveMemberModal
          onClose={() => setModalControl(null)}
          member={modalControl.member}
        />
      )}

      {modalControl?.action === "edit" && (
        <EditMemberRoles
          onClose={() => setModalControl(null)}
          member={modalControl.member}
        />
      )}
    </>
  );
}
