import type { EnvConfig } from "../store/api";
import { api } from "../store/api";

export interface ConfigGateProps {
  children: React.ReactNode | ((props: EnvConfig) => React.ReactNode);
}

export const ConfigGate = ({ children }: ConfigGateProps) => {
  const { data, isError } = api.useGetConfigQuery();

  if (!data) {
    return null;
  } else if (isError) {
    return <>Error loading config</>;
  }

  return (
    <div data-testid="config-gate">
      {typeof children === "function" ? children(data!) : children}
    </div>
  );
};
