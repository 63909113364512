import { useNavigate, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

import { useApi } from "./useApi";

export const useOrganization = () => {
  const api = useApi();
  // This is a bit of a hack to get the orgId from the URL if it's not in the store and then just refresh the query
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  if (!organizationId) {
    throw new Error(
      "Cannot use useOrganization outside an organization-scoped route",
    );
  }

  const query = api.useOrganizationsShowQuery(organizationId);

  // Note: we wouldn't really want to do this. This is intentionally harsh.
  if (
    typeof query.error !== "undefined" &&
    "status" in query.error &&
    query.error.status! === 403
  ) {
    const toastId = "no-access";
    if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        status: "error",
        title: "You don't have access to this organization",
      });
    }
    setTimeout(() => {
      navigate("/");
    }, 50);
  }

  return { ...query, organizationId };
};
