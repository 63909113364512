import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { get } from "lodash-es";
import { Fragment, useEffect, useMemo } from "react";
import type { Control } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";

import { RadioButton, RadioButtonGroup } from "../ButtonGroup";

export type ResourceMeta =
  | {
      description?: string | null;
      name: string;
    }
  | {
      unknown: true;
    };
export type PermissionsList = string[];
export type ResourcePermissions = {
  [key: string]: {
    __meta?: ResourceMeta;
  } & (
    | PermissionsList
    | {
        [key: string]: PermissionsList | ResourcePermissions;
      }
  );
};
export type Permissions = {
  [key: string]: ResourcePermissions;
};

interface PermissionSelectorProps {
  resourceType?: string;
  availablePermissions: ResourcePermissions;
  onChange: (values: any) => void;
  defaultValues?: Record<string, string[]>;
  isDisabled?: boolean;
  hideTitle?: boolean;
}

const onChangeSetter = (selection: string, permissions: string[]) => {
  if (selection === "none") return [];
  if (selection === "read") return ["read"];
  if (selection === "write") return permissions;
};

const selectValueGetter = (value: string[]) => {
  if (value.length === 0) return "none";
  if (value.length === 1 && value[0] === "read") return "read";
  return "write";
};

const WRITE_PERMISSIONS = ["write", "create", "update", "delete", "cancel"];

type ResourcePermissionsSelectorProps = {
  control: Control<{ permissions: Record<string, string[]> }>;
  permissions: PermissionsList | ResourcePermissions;
  resourcePath?: string[];
  resourceType?: string;
};

function ResourcePermissionsSelector({
  control,
  permissions,
  resourcePath = [],
  resourceType,
}: ResourcePermissionsSelectorProps) {
  return (
    <>
      {Object.entries(permissions).map(
        ([resource, permissionsListOrResourceIds]) => {
          const innerResourcePath = [...resourcePath, resource, "*"];

          let permissionsList: PermissionsList | undefined;
          let subresources: ResourcePermissions | undefined;
          if (Array.isArray(permissionsListOrResourceIds)) {
            permissionsList = permissionsListOrResourceIds;
          } else if (
            typeof permissionsListOrResourceIds === "object" &&
            "*" in permissionsListOrResourceIds
          ) {
            const starResource = permissionsListOrResourceIds["*"];
            if (Array.isArray(starResource)) {
              permissionsList = starResource;
              innerResourcePath.push("*");
            } else if (typeof starResource === "object") {
              const { $self, ...others } = starResource;
              permissionsList = $self as PermissionsList;
              subresources = others;
            }
          }

          if (!permissionsList && !subresources) {
            return;
          }

          const isDisabled = false;

          const flattenedResourcePath = flattenResourcePath(innerResourcePath);

          return (
            <Fragment key={flattenedResourcePath}>
              {permissionsList ? (
                <Controller
                  name={`permissions.${flattenedResourcePath}`}
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => {
                    const { value, onChange } = field;

                    return (
                      <Tr>
                        <Td
                          paddingLeft={`${resourcePath.length / 2}rem`}
                          textTransform="capitalize"
                        >
                          {resource === "$self"
                            ? resourceType === "networks"
                              ? "network"
                              : "" // impossible - this is just dropping off the plural.
                            : resource.replaceAll("_", " ")}
                        </Td>
                        <Td>
                          <RadioButtonGroup
                            defaultValue="none"
                            value={selectValueGetter(value)}
                            size="sm"
                            onChange={(value) => {
                              onChange(onChangeSetter(value, permissionsList!));
                            }}
                            isDisabled={isDisabled}
                          >
                            <RadioButton value="none">None</RadioButton>
                            {permissionsList!.includes("read") && (
                              <RadioButton value="read">Read</RadioButton>
                            )}
                            {permissionsList!.some((permission) =>
                              WRITE_PERMISSIONS.includes(permission),
                            ) && <RadioButton value="write">Write</RadioButton>}
                          </RadioButtonGroup>
                        </Td>
                      </Tr>
                    );
                  }}
                />
              ) : null}

              {subresources ? (
                <ResourcePermissionsSelector
                  key={innerResourcePath.join("/")}
                  control={control}
                  permissions={subresources}
                  resourcePath={innerResourcePath}
                />
              ) : null}
            </Fragment>
          );
        },
      )}
    </>
  );
}

function flattenResourcePath(resourcePath: string[]) {
  if (resourcePath[resourcePath.length - 1] === "*") {
    resourcePath = resourcePath.slice(0, -1);
  }
  return resourcePath.join("/");
}

function flattenPermissions(
  permissions: ResourcePermissions,
): Record<string, string[]> {
  const result: Record<string, string[]> = {};

  const traversePermissions = (
    permissions: ResourcePermissions,
    path: string[] = [],
  ) => {
    Object.entries(permissions).forEach(([resource, value]) => {
      const currentPath = [...path, resource];

      if (Array.isArray(value)) {
        result[flattenResourcePath(currentPath)] = value;
      } else if (typeof value === "object" && value !== null) {
        traversePermissions(value, currentPath);
      }
    });
  };

  traversePermissions(permissions);

  return result;
}

export const PermissionSelector = ({
  resourceType,
  availablePermissions,
  onChange,
  defaultValues = {},
  isDisabled = false,
  hideTitle = false,
}: PermissionSelectorProps) => {
  const { control, watch, reset } = useForm<{
    permissions: Record<string, string[]>;
  }>({
    defaultValues: {
      permissions: defaultValues,
    },
  });

  useEffect(() => {
    const subscription = watch((value) => onChange(value.permissions));
    return () => subscription.unsubscribe();
  }, [watch, onChange]);

  const grantAllPermissions = () => {
    const flattened = flattenPermissions(availablePermissions);
    reset({ permissions: flattened });
  };

  const watched = watch();

  const hasAllPermissions = useMemo(() => {
    const flattened = Object.entries(flattenPermissions(availablePermissions));
    const missing = flattened.filter(([resource, permissions]) => {
      const currentPermissions = watched.permissions[resource] || [];
      return currentPermissions.length !== permissions.length;
    });
    return missing.length === 0;
  }, [availablePermissions, watched]);

  const hasSelectedSomePermissions = useMemo(() => {
    const flattened = Object.entries(flattenPermissions(availablePermissions));
    const missing = flattened.filter(([resource]) => {
      const currentPermissions = watched.permissions[resource] || [];
      return currentPermissions.length > 0;
    });
    return missing.length > 0;
  }, [availablePermissions, watched]);

  return (
    <Box py={5}>
      {!hideTitle ? (
        <Text fontWeight="bold" mb={4}>
          Permissions:{" "}
          {get(availablePermissions, ["__meta", "name"], "Unknown")}
        </Text>
      ) : null}

      <Table size="sm">
        <Thead>
          <Tr>
            <Th width="50%">Resource</Th>
            <Th>
              <HStack justifyContent="space-between">
                <Text>Permissions</Text>

                {!isDisabled ? (
                  <ButtonGroup size="sm" variant="ghost">
                    {hasSelectedSomePermissions && (
                      <Button
                        onClick={() =>
                          reset({
                            permissions: defaultValues,
                          })
                        }
                      >
                        Reset
                      </Button>
                    )}
                    {!hasAllPermissions && (
                      <Button onClick={grantAllPermissions}>Grant all</Button>
                    )}
                  </ButtonGroup>
                ) : null}
              </HStack>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          <ResourcePermissionsSelector
            resourceType={resourceType}
            control={control}
            permissions={availablePermissions}
          />
        </Tbody>
      </Table>
    </Box>
  );
};
