import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { MemberRoleSelection } from "../../../components/MemberRoleSelection";
import { MemberRoleTable } from "../../../components/MemberRoleTable";
import { useApi, useOrganization } from "../../../hooks";
import type { Member } from "../../../store/services/api.generated";

export default function EditMemberRoles({
  onClose,
  member,
}: {
  onClose: () => void;
  member: Member;
}) {
  const api = useApi();
  const { organizationId } = useOrganization();

  const { data: memberData, isLoading } = api.useOrganizationsMembersShowQuery({
    organizationId,
    id: member.id,
  });

  const roles = useMemo(() => {
    return Object.entries(memberData?.data.roles ?? {}).map(
      ([resourcePath, role]) => {
        return {
          resourcePath,
          roleDisplayName: role?.name || "",
          role: role?.role || "",
        };
      },
    );
  }, [memberData]);

  return (
    <Modal isOpen onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        {memberData && !isLoading ? (
          <MemberRoleForm
            member={memberData.data}
            organizationId={organizationId}
            defaultValues={{ roles }}
            onClose={onClose}
          />
        ) : null}
      </ModalContent>
    </Modal>
  );
}

type MemerRoleFormValues = {
  roles: Array<{ resourcePath: string; roleDisplayName: string; role: string }>;
};
function MemberRoleForm({
  defaultValues,
  onClose,
  member,
  organizationId,
}: {
  defaultValues: MemerRoleFormValues;
  onClose: () => void;
  member: Member;
  organizationId: string;
}) {
  const api = useApi();
  const [updateMember] = api.useOrganizationsMembersUpdateMutation();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<MemerRoleFormValues>({
    defaultValues,
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "roles",
  });

  const toast = useToast();

  return (
    <form
      onSubmit={handleSubmit(async (values) => {
        const resetPreviousRoles = Object.entries(member.roles ?? {}).reduce(
          (acc, [key]) => {
            return {
              ...acc,
              [key]: null,
            };
          },
          {},
        );

        const memberRoles = values.roles.reduce((acc, cur) => {
          return {
            [cur.resourcePath]: cur.role,
            ...acc,
          };
        }, {});

        try {
          await updateMember({
            organizationId,
            id: member.id,
            body: {
              data: {
                roles: {
                  ...resetPreviousRoles,
                  ...memberRoles,
                },
              },
            },
          }).unwrap();

          toast({
            status: "success",
            title: "Member roles updated",
          });
          onClose();
        } catch (err) {
          console.log(err);
          toast({
            status: "error",
            description: "Failed to update member roles",
          });
        }
      })}
    >
      <ModalHeader>Edit member roles</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={4}>
          <MemberRoleSelection onAdd={append} />
          <MemberRoleTable roles={fields} onRemove={remove} />
        </Stack>
      </ModalBody>
      <ModalFooter gap={2}>
        <Button variant="ghost" colorScheme="gray" onClick={onClose}>
          Cancel
        </Button>
        <Button colorScheme="blue" type="submit" isLoading={isSubmitting}>
          Save
        </Button>
      </ModalFooter>
    </form>
  );
}
