import { Navigate } from "react-router-dom";
import type { PropsWithChildren } from "react";
import { useEffect } from "react";

import { PageLoader } from "@stordco/fe-components";

import { useOrganization } from "../hooks";
import { useAppDispatch } from "../store/hooks";
import { setOrganization } from "../features/auth/actions";

export interface OrganizationHydratorProps extends PropsWithChildren {
  errorRedirectTo: string;
}

export function OrganizationHydrator({
  errorRedirectTo,
  children,
}: OrganizationHydratorProps) {
  const dispatch = useAppDispatch();
  const { data, isLoading, isSuccess, isError } = useOrganization();

  useEffect(() => {
    if (data) {
      dispatch(setOrganization(data?.data));
    }
  }, [data, dispatch]);

  if (isLoading) return <PageLoader />;

  if (!data?.data && (isSuccess || isError)) {
    return <Navigate to={errorRedirectTo} />;
  }

  return <>{children}</>;
}
