import type { User } from "@auth0/auth0-spa-js";

import type { Member } from "../../../store/services/api.generated";
import type { Member as AdminMember } from "../../../store/services/admin/api.generated";

const isAuth0User = (user: Member | AdminMember | User): user is User => {
  return "given_name" in user;
};

export function getUsersFullName(user?: Member | AdminMember | User) {
  if (!user) return "";

  if (isAuth0User(user)) {
    return `${user.given_name || ""}${
      user.family_name ? " " + user.family_name : ""
    }`;
  }

  return `${user.first_name ?? ""}${
    user.last_name ? " " + user.last_name : ""
  }`;
}
