import {
  faFileWaveform,
  faHouse,
  faKey,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import type { ReactNode } from "react";
import { Suspense } from "react";

import { selectCurrentOrganization } from "../features/auth/authSlice";
import { useAppSelector } from "../store/hooks";

import { LayoutWrapper } from "./shell/LayoutWrapper";
import { NavigationProvider } from "./shell/Navigation";

export default function OrganizationNavigation({
  children,
}: {
  children: ReactNode;
}) {
  const currentOrg = useAppSelector(selectCurrentOrganization);
  const pathPrefix = `/organizations/${currentOrg.alias}`;

  const primaryLinks = currentOrg.alias
    ? [
        {
          label: "Home",
          icon: faHouse,
          to: `${pathPrefix}/dashboard`,
        },
        {
          label: "API Keys",
          icon: faKey,
          to: `${pathPrefix}/api-keys`,
        },
        {
          label: "Members",
          icon: faUser,
          to: `${pathPrefix}/members`,
        },
        {
          label: "Activities",
          icon: faFileWaveform,
          to: `${pathPrefix}/activities`,
        },
      ]
    : [];

  return (
    <NavigationProvider
      links={{
        primary: primaryLinks,
      }}
    >
      <LayoutWrapper>
        <Suspense fallback={null}>{children}</Suspense>
      </LayoutWrapper>
    </NavigationProvider>
  );
}
