import { createSlice } from "@reduxjs/toolkit";

import type { RootState } from "../../store";

export const organizationSwitcherSlice = createSlice({
  name: "organizationSwitcher",
  initialState: {
    isOpen: false,
  },
  reducers: {
    toggle: (state) => {
      state.isOpen = !state.isOpen;
    },
    onClose: (state) => {
      state.isOpen = false;
    },
    onOpen: (state) => {
      state.isOpen = true;
    },
  },
});

export default organizationSwitcherSlice.reducer;
export const {
  toggle: toggleOrganizationSwitcher,
  onClose: closeOrganizationSwitcher,
  onOpen: openOrganizationSwitcher,
} = organizationSwitcherSlice.actions;

export const selectOrganizationSwitcherIsOpen = (state: RootState) =>
  state.organizationSwitcher.isOpen;
