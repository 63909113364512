import { useSelector } from "react-redux";

import { selectConfig } from "../features/config/configSlice";

/**
 * @returns the environment variables as a lookup table
 */
export const useConfig = () => {
  const config = useSelector(selectConfig);

  return config;
};
