import { Button } from "@chakra-ui/react";
import { api } from "../../../store/api";
import { faShuffle } from "@fortawesome/pro-regular-svg-icons";
import { FaIcon } from "@stordco/fe-components";
import { useIsAdminContext } from "../../../hooks";
import { useNavigate } from "react-router-dom";

/**
 * A convenience button to allow a cloud admin to switch between organization and admin contexts
 */
export function CloudAdminButton() {
  const { data } = api.useMeQuery({});
  const isAdminContext = useIsAdminContext();
  const navigate = useNavigate();

  let isCloudAdmin = false;
  for (const org of data?.data.organizations ?? []) {
    for (const app of org.apps ?? []) {
      if (app.alias === "cloud_admin") {
        isCloudAdmin = true;
      }
    }
  }

  if (!isCloudAdmin) return null;

  if (isAdminContext) {
    return (
      <Button
        leftIcon={<FaIcon icon={faShuffle} />}
        onClick={() => navigate("/organizations")}
      >
        Organization View
      </Button>
    );
  }

  return (
    <Button
      leftIcon={<FaIcon icon={faShuffle} />}
      onClick={() => navigate("/admin/organizations")}
    >
      Cloud Admin
    </Button>
  );
}
