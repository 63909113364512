/**
 *
 * @param str A string you want to convert from something to Something
 * @returns a Capitalized string if given a valid string input, otherwise returns the input.
 */
export function capitalize(str: string) {
  return typeof str === "string" && str.length
    ? str.replace(str[0], str[0].toUpperCase())
    : str;
}
