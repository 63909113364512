import type { ReactNode } from "react";

import { StackedCell } from "@stordco/fe-components";

import { formatDate, formatTime } from "../helpers/datetime";

export function DateTimeCell({
  value,
  fallback = "-",
  includeTime = true,
}: {
  value?: string | Date | null;
  fallback?: ReactNode;
  includeTime?: boolean;
}) {
  if (!value) return <>{fallback}</>;
  const date = formatDate(value);
  const time = formatTime(value);

  return <StackedCell lines={[date, includeTime ? time : null]} />;
}
