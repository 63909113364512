import { format } from "date-fns";

// NOTE: from `shipper`
export const DATETIME_FORMAT_STRINGS = {
  time: "h:mm a zzz",
  dateWithYear: "MMM d, yyyy",
  dateWithoutYear: "MMM d",
  dateWithDayAndYear: "E, MMM d, yyyy",
  dateWithDayWithoutYear: "E, MMM d",
};

export const formatDate = (date: string | Date) => {
  const datetime = new Date(date);
  const isThisYear = datetime.getFullYear() === new Date().getFullYear();

  return format(
    new Date(date),
    isThisYear
      ? DATETIME_FORMAT_STRINGS.dateWithoutYear
      : DATETIME_FORMAT_STRINGS.dateWithYear,
  );
};

export const formatTime = (date: string | Date) => {
  return format(new Date(date), DATETIME_FORMAT_STRINGS.time);
};
