import { createRef, forwardRef, useImperativeHandle, useState } from "react";
import { ChakraSearch } from "./ChakraSearch";
import type { ExpandableSearch } from "./ExpandableSearch/ExpandableSearch";

export * from "./ChakraSearch";
export * from "./ExpandableSearch/ExpandableSearch";

export type CreateSearchComponentProps = {
  displayName?: string;
  Component?: typeof ChakraSearch | typeof ExpandableSearch;
};
export type SearchRef = {
  clear: () => void;
};
export type SearchComponentProps = {
  onSearch: (search: string) => void;
  placeholder?: string;
  initialValue?: string;
};
/**
 * Creates a render-optimized search component that returns a `ref` for clearing the search term.
 *
 * In the case that you want to reuse the created component multiple times in the same page,
 * you can pass through a unique `ref` from `useRef` to each.
 *
 * @example
 * ```ts
 * const { Component: SupplierSearch, ref: searchRef } = createSearchComponent({
 *  displayName: "SupplierSearch",
 * });
 *
 * <SupplierSearch
 *   ref={searchRef}
 *   placeholder="Search by name"
 *   initialValue={params.search as string}
 *   onSearch={debouncedOnSearch}
 * />
 * ```
 */
export function createSearchComponent({
  displayName = "SearchComponent",
  Component = ChakraSearch,
}: CreateSearchComponentProps = {}) {
  const searchRef = createRef<SearchRef>();

  const SearchComponent = forwardRef<SearchRef, SearchComponentProps>(
    function SearchComponent(
      { onSearch, placeholder = "Search", initialValue = "" },
      ref,
    ) {
      const [searchTerm, setSearchTerm] = useState(initialValue);

      useImperativeHandle(ref, () => ({
        clear() {
          setSearchTerm("");
        },
      }));

      return (
        <Component
          onChange={(search) => {
            setSearchTerm(search);
            onSearch(search);
          }}
          placeholder={placeholder}
          value={searchTerm}
        />
      );
    },
  );
  SearchComponent.displayName = displayName;
  return { Component: SearchComponent, ref: searchRef };
}
