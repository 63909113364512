import type { RedirectLoginOptions, User } from "@auth0/auth0-spa-js";
import { useEffect, type PropsWithChildren, type ReactElement } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { PageLoader } from "@stordco/fe-components";

// NOTE: This is almost copied exactly from `withAuthenticationRequired` from auth0-react

const defaultOnRedirecting = (): ReactElement => <PageLoader />;

/**
 * @ignore
 */
const defaultReturnTo = () =>
  `${window.location.pathname}${window.location.search}`;

export interface RequireAuthOptions {
  /**
   * Add a path for the `onRedirectCallback` handler to return the user to after login.
   */
  returnTo?: string | (() => string);
  /**
   *
   * Render a message to show that the user is being redirected to the login.
   */
  onRedirecting?: () => ReactElement;
  /**
   * Pass additional login options, like extra `appState` to the login page.
   * This will be merged with the `returnTo` option used by the `onRedirectCallback` handler.
   */
  loginOptions?: RedirectLoginOptions;
  /**
   * Check the user object for JWT claims and return a boolean indicating
   * whether or not they are authorized to view the component.
   */
  claimCheck?: (claims?: User) => boolean;
}

export const RequireAuth = ({
  children,
  options = {},
}: PropsWithChildren<{
  options?: RequireAuthOptions;
}>) => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  const {
    returnTo = defaultReturnTo,
    onRedirecting = defaultOnRedirecting,
    loginOptions = {},
    claimCheck = (): boolean => true,
  } = options;

  /**
   * The route is authenticated if the user has valid auth and there are no
   * JWT claim mismatches.
   */
  const routeIsAuthenticated = isAuthenticated && claimCheck(user);

  useEffect(() => {
    if (isLoading || routeIsAuthenticated) {
      return;
    }
    const opts = {
      ...loginOptions,
      appState: {
        ...loginOptions.appState,
        returnTo: typeof returnTo === "function" ? returnTo() : returnTo,
      },
    };
    (async (): Promise<void> => {
      await loginWithRedirect(opts);
    })();
  }, [
    isLoading,
    routeIsAuthenticated,
    loginWithRedirect,
    loginOptions,
    returnTo,
  ]);

  return routeIsAuthenticated ? <>{children}</> : onRedirecting();
};
