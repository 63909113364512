import {
  Avatar,
  AvatarBadge,
  Box,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import {
  faEnvelopes,
  faShuffle,
  faSignOut,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { transparentize } from "@chakra-ui/theme-tools";

import { FaIcon, IconBadge, StordEmblem } from "@stordco/fe-components";

import { useAuth } from "../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { openOrganizationSwitcher } from "../../../features/organizationSwitcher/organizationSwitcherSlice";
import { api } from "../../../store/api";

const getOs = () => {
  const os = ["Windows", "Linux", "Mac"]; // add your OS values
  return os.find((v) => navigator.appVersion.indexOf(v) >= 0);
};

export function UserAccountMenu() {
  const navigate = useNavigate();
  const os = getOs();
  const { user, logout } = useAuth();
  const dispatch = useAppDispatch();
  const selectedOrg = useAppSelector((state) => state.auth.organization);
  const { data } = api.useMeQuery({});

  useHotkeys("meta+shift+o", () => dispatch(openOrganizationSwitcher()));
  useHotkeys(
    "ctrl+shift+a",
    () => user?.email?.endsWith("@stord.com") && navigate("/admin"),
  );

  return (
    <Menu>
      <Tooltip label="Account" placement="bottom">
        <MenuButton
          as={Avatar}
          boxSize={8}
          src={user?.picture}
          referrerPolicy="no-referrer" // https://stackoverflow.com/a/61042200
          icon={<FaIcon icon={faUser} width="32px" color="gray.200" />}
          bg="gray.800"
          _hover={{
            cursor: "pointer",
            shadow: "0px 0px 0px 4px #2e2e2e",
            _dark: {
              shadow: "0px 0px 0px 4px rgba(255, 255, 255, 0.08)",
            },
          }}
          _active={{
            shadow: "0px 0px 0px 4px #2e2e2e",
            _dark: {
              shadow: "0px 0px 0px 4px rgba(255, 255, 255, 0.08)",
            },
          }}
        >
          <AvatarBadge
            boxSize="16px"
            rounded="base"
            borderColor="black"
            borderWidth="2px"
            transform="translate(50%, 10%)"
          >
            {Object.keys(selectedOrg ?? {}).length ? (
              <Avatar
                rounded="sm"
                boxSize="16px"
                outline="2px solid"
                outlineColor="black"
                _dark={{
                  outlineColor: "#242424",
                }}
                bgColor="gray.800"
                color="white"
                sx={{
                  ".chakra-avatar__initials": {
                    fontSize: "10px",
                    fontWeight: "semibold",
                  },
                }}
                name={selectedOrg.name}
              />
            ) : (
              <Box padding="2px" backgroundColor="black">
                <StordEmblem width="11px" fill="white" />
              </Box>
            )}
          </AvatarBadge>
        </MenuButton>
      </Tooltip>
      <MenuList zIndex="dropdown">
        <MenuGroup title="Organizations">
          {data?.data?.organizations?.map((org, index) => {
            if (index >= 5) {
              return null;
            } else {
              return (
                <MenuItem
                  key={org.id}
                  as={Link}
                  to={`/organizations/${org.alias}/dashboard`}
                  borderLeftColor="blue.500"
                  _dark={{
                    borderLeftColor: "blue.200",
                  }}
                  paddingLeft={selectedOrg.id === org.id ? "14px" : undefined}
                  borderLeftWidth={selectedOrg.id === org.id ? "2px" : 0}
                  icon={
                    <Avatar
                      name={org.name}
                      boxSize={8}
                      rounded="base"
                      backgroundColor="blackAlpha.100"
                      _dark={{
                        backgroundColor: transparentize("gray.200", 0.16),
                      }}
                      sx={{
                        ".chakra-avatar__initials": {
                          fontSize: "16px",
                        },
                      }}
                    />
                  }
                >
                  {org.name}
                </MenuItem>
              );
            }
          })}
          <MenuItem
            gap={0}
            command={`${os === "Mac" ? "⌘" : "Ctrl"}⇧O`}
            icon={
              <IconBadge variant="square" colorScheme="gray" icon={faShuffle} />
            }
            onClick={() => dispatch(openOrganizationSwitcher())}
          >
            View all organizations
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title="Account">
          <MenuItem
            gap={0}
            icon={
              <IconBadge
                variant="square"
                colorScheme="gray"
                icon={faEnvelopes}
              />
            }
            as={Link}
            to="/invitations"
          >
            Invitations
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuItem
          gap={0}
          onClick={logout}
          icon={
            <IconBadge icon={faSignOut} variant="square" colorScheme="gray" />
          }
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
