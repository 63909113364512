import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

import type { UserApi } from "../store/services/api";
import type { AdminApi } from "../store/services/admin/api";

export type AnyApi = UserApi | AdminApi;

export type ApiTypes = {
  user: UserApi;
  admin: AdminApi;
};

export type ApiType = keyof ApiTypes;

const ApiContext = createContext<AnyApi>(null as any);

interface ApiProviderProps extends PropsWithChildren {
  api: AnyApi;
}

export function ApiProvider({ api, children }: ApiProviderProps) {
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}

export function useApi(): AnyApi {
  return useContext(ApiContext);
}
