import { lazy, Suspense } from "react";
import type { ReactNode } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  useRouteError,
} from "react-router-dom";

import { AuthenticationErrorPage, PageLoader } from "@stordco/fe-components";

import { Auth0Provider, OrganizationHydrator, RequireAuth } from "./components";
import OrganizationNavigation from "./components/OrganizationNavigation";
import { RequireAdmin } from "./components/RequireAdmin";
import { ApiKeyDataTable } from "./features/auth/apiKeys/components/ApiKeyDataTable";
import Login from "./features/auth/Login";
import { ApiProvider, useApi } from "./hooks";
import { AuthHydrator } from "./components/AuthHydrator";
import MembersTable from "./features/auth/members/MembersTable";
import UserNavigation from "./components/UserNavigation";
import { CloudUserStatusGate } from "./components/CloudUserStatusGate";
import { ApiKeyDetailsDrawer } from "./features/auth/apiKeys/components/ApiKeyDetailsDrawer";
import { userApi } from "./store/services/api";
import { ParcelMiddlewareInjector } from "./store/services/middleware-injectors/ParcelMiddlewareInjector";

// "userApi" is the default cloud experience. we do not need to lazy load this.
// "adminApi" powers the admin app, but has the same api footprint as "userApi" just with
// slightly different routes. we lazy load this and dynamically inject the reducer and middleware for the api slice.
// this same thing would happen with any other "sub apps".
// we'd most likely want to package this up for the platform. need to noodle on it.

const UserOnboarding = lazy(() => import("./features/user/UserOnboarding"));
const Home = lazy(() => import("./features/user/Home"));
const Invitations = lazy(
  () => import("./features/auth/invitations/Invitations"),
);
const CreateApiKey = lazy(() => import("./features/auth/apiKeys/CreateApiKey"));
const AdminActivities = lazy(
  () => import("./features/admin/activities/Activities"),
);
const AdminUsers = lazy(() => import("./features/admin/users/Users"));
const AdminApps = lazy(() => import("./features/admin/apps/Apps"));
const AdminApp = lazy(() => import("./features/admin/apps/App"));
const AdminAppProfile = lazy(
  () => import("./features/admin/apps/panels/Profile"),
);
const AdminAppVersionHistory = lazy(
  () => import("./features/admin/apps/panels/Versions"),
);
const AdminCaches = lazy(() => import("./features/admin/cache/Caches"));

const AdminUser = lazy(() => import("./features/admin/users/User"));
const Member = lazy(() => import("./features/auth/members/Member"));
const MemberPermissions = lazy(
  () => import("./features/auth/members/MemberPermissions"),
);
const MemberProfile = lazy(
  () => import("./features/auth/members/MemberProfile"),
);
const Members = lazy(() => import("./features/auth/members/Members"));
const Dashboard = lazy(() => import("./features/dashboard/Dashboard"));
const AdminOrganizations = lazy(
  () => import("./features/admin/organizations/Organizations"),
);
const AdminOrganization = lazy(
  () => import("./features/admin/organizations/Organization"),
);
const AdminOrganizationProfile = lazy(
  () => import("./features/admin/organizations/panels/Profile"),
);
const AdminOrganizationApps = lazy(
  () => import("./features/admin/organizations/panels/Apps"),
);
const AdminNavigation = lazy(() => import("./components/AdminNavigation"));
const ApiKeys = lazy(() => import("./features/auth/apiKeys/ApiKeys"));
const AppInstances = lazy(
  () => import("./features/admin/organizations/panels/AppInstances"),
);
const AppInstanceDataTable = lazy(
  () => import("./features/app-instances/AppInstancesDataTable"),
);
const AppInstance = lazy(() => import("./features/app-instances/AppInstance"));
const AdminApiProvider = lazy(
  () => import("./store/services/admin/AdminApiProvider"),
);
const Activities = lazy(() => import("./features/activities/Activities"));
const ActivitiesTable = lazy(
  () => import("./features/activities/ActivitiesTable"),
);

function OrganizationApiProvider({ children }: { children: ReactNode }) {
  return <ApiProvider api={userApi}>{children}</ApiProvider>;
}

function RedirectToOrganization() {
  const api = useApi();
  const { data, isLoading } = api.useOrganizationsIndexQuery({ pageSize: 1 });

  if (isLoading) return null;

  const organizations = data?.data;
  if (!organizations || organizations.length === 0) {
    return <Navigate to={`/`} />;
  }

  return <Navigate to={`/organizations/${organizations[0].alias}/dashboard`} />;
}

const ErrorElement = function ErrorElement() {
  throw useRouteError();
};

export const routes = createBrowserRouter([
  { path: "/login", errorElement: <ErrorElement />, element: <Login /> },
  {
    path: "/auth_error",
    errorElement: <ErrorElement />,
    element: <AuthenticationErrorPage />,
  },
  {
    path: "/",
    errorElement: <ErrorElement />,
    element: (
      <Auth0Provider>
        <AuthHydrator>
          <RequireAuth>
            <CloudUserStatusGate>
              <Suspense fallback={<PageLoader />}>
                <Outlet />
              </Suspense>
            </CloudUserStatusGate>
          </RequireAuth>
        </AuthHydrator>
      </Auth0Provider>
    ),
    children: [
      { path: "onboarding", element: <UserOnboarding /> },
      {
        path: "/",
        element: (
          <OrganizationApiProvider>
            <UserNavigation>
              <Suspense fallback={<PageLoader />}>
                <Outlet />
              </Suspense>
            </UserNavigation>
          </OrganizationApiProvider>
        ),
        children: [
          { index: true, element: <Home /> },
          {
            path: "invitations",
            element: <Invitations />,
          },
        ],
      },
      {
        path: "app",
        element: (
          <ParcelMiddlewareInjector>
            <Suspense fallback={<PageLoader />}>
              <Outlet />
            </Suspense>
          </ParcelMiddlewareInjector>
        ),
        children: [
          {
            path: "parcel/*",
            lazy: () => import("./apps/parcel"),
          },
        ],
      },
      // 1. let the user log in
      // 2. check for organizations
      // 3. if there is 1 org, show the 'dashboard' for that org
      // 4. if there are > 1 orgs, show a list of orgs or jump back into the last one (unless the orgId is scoped in the URL)
      // 5. if the orgId specified doesn't exist in the list of orgs, show a toast and redirect?
      {
        path: "organizations",
        element: (
          <OrganizationApiProvider>
            <OrganizationNavigation>
              <Suspense fallback={<PageLoader />}>
                <Outlet />
              </Suspense>
            </OrganizationNavigation>
          </OrganizationApiProvider>
        ),
        children: [
          { index: true, element: <RedirectToOrganization /> },
          {
            path: ":organizationId",
            element: (
              <OrganizationHydrator errorRedirectTo="/organizations">
                <Suspense fallback={<PageLoader />}>
                  <Outlet />
                </Suspense>
              </OrganizationHydrator>
            ),
            children: [
              { path: "dashboard", element: <Dashboard /> },
              { path: "members", element: <Members /> },
              {
                path: "members/:memberId",
                element: <Member />,
                children: [
                  { index: true, element: <MemberProfile /> },
                  { path: "permissions", element: <MemberPermissions /> },
                ],
              },
              {
                path: "api-keys",
                element: <ApiKeys />,
                children: [
                  { path: ":apiKeyId", element: <ApiKeyDetailsDrawer /> },
                ],
              },
              {
                path: "app-instances",
                element: <AppInstances />,
              },
              { path: "api-keys/create", element: <CreateApiKey /> },
              { path: "activities", element: <Activities /> },
            ],
          },
        ],
      },
      {
        path: "admin",
        element: (
          <AdminApiProvider>
            <RequireAdmin>
              <Suspense fallback={<PageLoader />}>
                <AdminNavigation />
              </Suspense>
            </RequireAdmin>
          </AdminApiProvider>
        ),
        children: [
          { index: true, element: <Navigate to="organizations" /> },
          {
            path: "activities",
            children: [{ index: true, element: <AdminActivities /> }],
          },
          {
            path: "apps",
            children: [
              { index: true, element: <AdminApps /> },
              {
                path: ":appId",
                element: <AdminApp />,
                children: [
                  { index: true, element: <AdminAppProfile /> },
                  {
                    path: "versions",
                    element: <AdminAppVersionHistory />,
                  },
                ],
              },
            ],
          },
          {
            path: "cache",
            children: [
              { index: true, element: <AdminCaches /> },
              // { path: ":cacheId", element: <AdminCache /> },
            ],
          },
          {
            path: "users/*",
            children: [
              { index: true, element: <AdminUsers /> },
              { path: ":userId", element: <AdminUser /> },
            ],
          },
          {
            path: "organizations",
            children: [
              { index: true, element: <AdminOrganizations /> },
              {
                path: ":organizationId",
                element: (
                  <OrganizationHydrator errorRedirectTo="/admin/organizations">
                    <AdminOrganization />
                  </OrganizationHydrator>
                ),
                children: [
                  { index: true, element: <AdminOrganizationProfile /> },
                  { path: "apps", element: <AdminOrganizationApps /> },
                  { path: "members", element: <MembersTable /> },
                  {
                    path: "api-keys",
                    element: <ApiKeyDataTable />,
                    children: [
                      { path: ":apiKeyId", element: <ApiKeyDetailsDrawer /> },
                    ],
                  },
                  {
                    path: "app-instances",
                    element: <AppInstanceDataTable />,
                  },
                  { path: "activities", element: <ActivitiesTable /> },
                ],
              },
              {
                path: ":organizationId/api-keys/create",
                element: <CreateApiKey />,
              },
              {
                path: ":organizationId/members/:memberId",
                element: <Member isAdminContext />,
                children: [
                  { index: true, element: <MemberProfile /> },
                  { path: "permissions", element: <MemberPermissions /> },
                ],
              },
              {
                path: ":organizationId/app-instances/:appInstanceId",
                element: <AppInstance />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
