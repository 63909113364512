import { createApi } from "@reduxjs/toolkit/query/react";

import { default as baseQuery } from "../baseQuery";

export const reducerPath = "api";

export const baseApi = createApi({
  reducerPath,
  baseQuery,
  endpoints: () => ({}),
});
