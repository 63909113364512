import { generatedApi } from "./api.generated";

export const TAG_TYPES = [
  "ZipZoneMappings",
  "Shippers",
  "Parcels",
  "BillingAccounts",
];

export const parcelApi = generatedApi.enhanceEndpoints({
  addTagTypes: TAG_TYPES,
  endpoints: {
    getV1ShippersByShipperIdBillingAccounts: {
      providesTags: ["BillingAccounts"],
    },
    postV1ShippersByShipperIdBillingAccounts: {
      invalidatesTags: (_result, error) => (!error ? ["BillingAccounts"] : []),
    },
    zipZoneMappingsIndex: {
      providesTags: ["ZipZoneMappings"],
    },
    zipZoneMappingsUpsert: {
      invalidatesTags: (_result, error) => (!error ? ["ZipZoneMappings"] : []),
    },
  },
});

export type ParcelApi = typeof parcelApi;
