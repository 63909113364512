import { Navigate, useMatch } from "react-router-dom";

import { PageLoader } from "@stordco/fe-components";

import { api } from "../store/api";

export function CloudUserStatusGate({
  children,
}: {
  children: React.ReactNode;
}) {
  const match = useMatch("/onboarding");

  // we're going to check for the user status from cloud
  // if it's :setup, we need to show the setup form
  // then call update_me with first_name, last_name
  const { data, isLoading } = api.useMeQuery({});

  if (isLoading) return <PageLoader />;

  if (
    ((data?.data.type === "user" && data?.data.user.status === "setup") ||
      data?.data.type === "auth0") &&
    !match
  ) {
    return <Navigate to="/onboarding" />;
  }

  // After user completes onboarding, redirect to root
  if (
    data?.data.type === "user" &&
    data?.data.user.status === "active" &&
    match
  ) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
}
