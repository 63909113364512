import type { ReactNode } from "react";
import { useLayoutEffect } from "react";
import { createContext, useContext } from "react";

import { useLocalStorage } from "@stordco/fe-components";

import type { NavigationContextProps } from "./types";

const NavigationContext = createContext({} as NavigationContextProps);

export interface NavigationProviderProps extends NavigationContextProps {
  children: ReactNode;
}

export type NavigationState = "expanded" | "collapsed" | "hoverExpanded";

export const NavigationProvider = ({
  children,
  links,
}: Omit<NavigationProviderProps, "navigationState" | "setNavigationState">) => {
  const [navigationState, setNavigationState] =
    useLocalStorage<NavigationState>("navigation-state", "expanded");

  useLayoutEffect(() => {
    document.body.dataset.navigationState = navigationState;
  }, [navigationState]);

  return (
    <NavigationContext.Provider
      value={{
        links,
        navigationState,
        setNavigationState,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  return useContext(NavigationContext);
};
